/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 删除系统用户【系统用户管理】
 */
export interface SysDeleteCtrlUserEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 删除系统用户【系统用户管理】
 */
/**
 * 返回值 code=0 执行成功 ， code = 1 执行失败 ，&gt;=10000 其他系统错误
 */
export interface SysDeleteCtrlUserParams {
  /**
   * 系统用户ID
   */
  id: number
}

/**
 * 删除系统用户【系统用户管理】
 */
export const sysDeleteCtrlUser = (params: SysDeleteCtrlUserParams) => {
  return client.post<IClientResp<SysDeleteCtrlUserEntity>>(
    '/Sys/DeleteCtrlUser',
    params
  )
}

/**
 * - **通过** `Inject('sysDeleteCtrlUserStore')` 依赖注入到组件
 * - **删除系统用户【系统用户管理】** SysDeleteCtrlUserStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysDeleteCtrlUserStore: SysDeleteCtrlUserStore
		}
		@Inject('sysDeleteCtrlUserStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysDeleteCtrlUserStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysDeleteCtrlUserStore extends AsyncStore {
  @observable deleteCtrlUserEntity?: SysDeleteCtrlUserEntity = undefined
  @action
  async deleteCtrlUser(params: SysDeleteCtrlUserParams) {
    return this.asyncAction<SysDeleteCtrlUserEntity>(
      'deleteCtrlUserEntity',
      sysDeleteCtrlUser(params)
    )
  }
}

export const sysDeleteCtrlUserStore = new SysDeleteCtrlUserStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 新增权限组【权限组管理】
 */
export interface MenuAddActionGroupsEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 新增权限组【权限组管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface MenuAddActionGroupsParams {
  /**
   * ID
   */
  id: number

  /**
   * 菜单项ID
   */
  menuID: number

  /**
   * 所属分类(控制器名称)
   */
  controllerName: string

  /**
   * 是否自动选中（1--自动选中 0--不选中）
   */
  autoSelected: boolean

  /**
   * 排序码
   */
  orderNo: number

  /**
   * 备注
   */
  remark: string

  /**
   * 权限分组名称(简体)
   */
  actionGroupName_zh_cn: string

  /**
   * 权限分组名称(繁体)
   */
  actionGroupName_zh_tw: string

  /**
   * 权限分组名称(英文)
   */
  actionGroupName_en: string

  /**
   * 权限分组名称(越南文)
   */
  actionGroupName_vn: string

  /**
   * 权限分组名称(泰文)
   */
  actionGroupName_th: string

  /**
   * 彩种名称
   */
  actionGroupName: string
}

/**
 * 新增权限组【权限组管理】
 */
export const menuAddActionGroups = (params: MenuAddActionGroupsParams) => {
  return client.post<IClientResp<MenuAddActionGroupsEntity>>(
    '/Menu/AddActionGroups',
    params
  )
}

/**
 * - **通过** `Inject('menuAddActionGroupsStore')` 依赖注入到组件
 * - **新增权限组【权限组管理】** MenuAddActionGroupsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuAddActionGroupsStore: MenuAddActionGroupsStore
		}
		@Inject('menuAddActionGroupsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuAddActionGroupsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuAddActionGroupsStore extends AsyncStore {
  @observable addActionGroupsEntity?: MenuAddActionGroupsEntity = undefined
  @action
  async addActionGroups(params: MenuAddActionGroupsParams) {
    return this.asyncAction<MenuAddActionGroupsEntity>(
      'addActionGroupsEntity',
      menuAddActionGroups(params)
    )
  }
}

export const menuAddActionGroupsStore = new MenuAddActionGroupsStore()

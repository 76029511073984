/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取分站列表
 */
export interface NoticeSitesEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 获取分站列表
 */
export interface NoticeSitesParams {
  venderName?: string
}

/**
 * 获取分站列表
 */
export const noticeSites = (params: NoticeSitesParams) => {
  return client.get<IClientResp<NoticeSitesEntity>>('/notice/Sites', { params })
}

/**
 * - **通过** `Inject('noticeSitesStore')` 依赖注入到组件
 * - **获取分站列表** NoticeSitesStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			noticeSitesStore: NoticeSitesStore
		}
		@Inject('noticeSitesStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			noticeSitesStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class NoticeSitesStore extends AsyncStore {
  @observable sitesEntity?: NoticeSitesEntity = undefined
  @action
  async sites(params: NoticeSitesParams) {
    return this.asyncAction<NoticeSitesEntity>(
      'sitesEntity',
      noticeSites(params)
    )
  }
}

export const noticeSitesStore = new NoticeSitesStore()

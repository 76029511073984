/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 清除号码
 */
export interface OpenManageRemoveResultEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 清除号码
 */
export interface OpenManageRemoveResultParams {
  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 期号查询
   */
  lottoNo: string
}

/**
 * 清除号码
 */
export const openManageRemoveResult = (
  params: OpenManageRemoveResultParams
) => {
  return client.post<IClientResp<OpenManageRemoveResultEntity>>(
    '/OpenManage/RemoveResult',
    params
  )
}

/**
 * - **通过** `Inject('openManageRemoveResultStore')` 依赖注入到组件
 * - **清除号码** OpenManageRemoveResultStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			openManageRemoveResultStore: OpenManageRemoveResultStore
		}
		@Inject('openManageRemoveResultStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			openManageRemoveResultStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class OpenManageRemoveResultStore extends AsyncStore {
  @observable removeResultEntity?: OpenManageRemoveResultEntity = undefined
  @action
  async removeResult(params: OpenManageRemoveResultParams) {
    return this.asyncAction<OpenManageRemoveResultEntity>(
      'removeResultEntity',
      openManageRemoveResult(params)
    )
  }
}

export const openManageRemoveResultStore = new OpenManageRemoveResultStore()

import antEN from 'antd/lib/locale-provider/en_US'
import antTH from 'antd/lib/locale-provider/th_TH'
import antVN from 'antd/lib/locale-provider/vi_VN'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import zhTW from 'antd/lib/locale-provider/zh_TW'
import en from './en'
import enInfo from './en-messages'
import th from './th'
import thInfo from './th-messages'
import tw from './tw'
import twInfo from './tw-messages'
import vn from './vn'
import vnInfo from './vn-messages'
import zh from './zh'
import zhInfo from './zh-messages'

export const lang = {
  messages: zhInfo
}

interface Options {
  [key: string]: {
    name: string
    messages: any
    infos: any
    ant: any
    value: string
  }
}

const options: Options = {
  'zh-tw': {
    name: 'zh_tw',
    messages: tw,
    infos: twInfo,
    ant: zhTW,
    value: '繁體'
  },
  'zh-cn': {
    name: 'zh_cn',
    messages: zh,
    infos: zhInfo,
    ant: zhCN,
    value: '简体'
  },
  en: {
    name: 'en',
    messages: en,
    infos: enInfo,
    ant: antEN,
    value: 'English'
  },
  th: {
    name: 'th',
    messages: th,
    infos: thInfo,
    ant: antTH,
    value: 'ไทย'
  },
  vi: {
    name: 'vn',
    messages: vn,
    infos: vnInfo,
    ant: antVN,
    value: 'Vietnam'
  }
}

export const getKeyByName = (name: string) => {
  switch (name) {
    case 'zh_tw':
      return 'zh-tw'
    case 'zh_cn':
      return 'zh-cn'
    case 'vn':
      return 'vi'
    default:
      return name
  }
}

export default options

import { Drawer } from 'antd'
import React, { Component } from 'react'
import { HomeGetMenuEntity } from 'services/home/get_menu'
import SiderMenu from './SiderMenu'

interface SiderMenuWrapperProps {
  collapsed: boolean
  onCollapse: any
  isMobile: boolean
  data: HomeGetMenuEntity | undefined
}

class SiderMenuWrapper extends Component<SiderMenuWrapperProps, any> {
  render() {
    const { isMobile, collapsed, onCollapse } = this.props
    return isMobile ? (
      <Drawer
        visible={!collapsed}
        placement='left'
        onClose={() => onCollapse()}
        style={{
          padding: 0,
          height: '100vh',
        }}
      >
        <SiderMenu {...this.props} collapsed={false} />
      </Drawer>
      ) : (
        <SiderMenu {...this.props} collapsed={collapsed} />
        )
  
    }
  }
  
  export default SiderMenuWrapper

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 会员动态日志分页数据【会员动态日志】
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 操作者账号
   */
  operateUserName: string

  /**
   * 操作命令ID
   */
  operateCommandID: number

  /**
   * 操作时间
   */
  operateDateTime: string

  /**
   * 操作内容
   */
  operateContent: string

  /**
   * 被操作者账号
   */
  beOperateUserName: string

  /**
   * 被操作者昵称
   */
  beOperateNickName: string

  /**
   * 操作命令名称
   */
  operateCommandName: string
}
export interface LogGetDynamicLogListEntity {
  recondCount: number
  data: data[]
}

/**
 * 会员动态日志分页数据【会员动态日志】
 */
export interface LogGetDynamicLogListParams {
  /**
   * 开始时间(eg:[2017-06-12 12:00:00])
   */
  BeginTime?: string

  /**
   * 结束时间(eg:[2018-06-12 12:00:00])
   */
  EndTime?: string

  /**
   * 操作命令ID
   */
  OperateCommandID?: number

  /**
   * 会员账号/昵称
   */
  KeyName?: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 会员动态日志分页数据【会员动态日志】
 */
export const logGetDynamicLogList = (params: LogGetDynamicLogListParams) => {
  return client.get<IClientResp<LogGetDynamicLogListEntity>>(
    '/Log/GetDynamicLogList',
    { params }
  )
}

/**
 * - **通过** `Inject('logGetDynamicLogListStore')` 依赖注入到组件
 * - **会员动态日志分页数据【会员动态日志】** LogGetDynamicLogListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetDynamicLogListStore: LogGetDynamicLogListStore
		}
		@Inject('logGetDynamicLogListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetDynamicLogListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetDynamicLogListStore extends AsyncStore {
  @observable getDynamicLogListEntity?: LogGetDynamicLogListEntity = undefined
  @action
  async getDynamicLogList(params: LogGetDynamicLogListParams) {
    return this.asyncAction<LogGetDynamicLogListEntity>(
      'getDynamicLogListEntity',
      logGetDynamicLogList(params)
    )
  }
}

export const logGetDynamicLogListStore = new LogGetDynamicLogListStore()

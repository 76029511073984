import {
  AppstoreOutlined,
  AreaChartOutlined,
  CarOutlined,
  DollarOutlined,
  FileTextOutlined,
  HomeOutlined,
  NotificationOutlined,
  ProfileOutlined,
  SettingOutlined,
  TrophyOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Menu } from 'antd'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { routeEvent } from 'routes/App/route-event'
import { HomeGetMenuEntity } from 'services/home/get_menu'
import { getUrlWithPrefix } from 'utils'
const SubMenu = Menu.SubMenu
export interface BaseMenuProps {
  data: HomeGetMenuEntity | undefined
  collapsed: boolean
  onCollapse: any
  isMobile: boolean
}

const icons = {
  home: <HomeOutlined />,
  user: <UserOutlined />,
  dollar: <DollarOutlined />,
  areachart: <AreaChartOutlined />,
  filetext: <FileTextOutlined />,
  setting: <SettingOutlined />,
  profile: <ProfileOutlined />,
  trophy: <TrophyOutlined />,
  appstore: <AppstoreOutlined />,
  car: <CarOutlined />,
  notification: <NotificationOutlined />
}

@observer
class BaseMenu extends Component<BaseMenuProps, any> {
  @observable
  openKey: any[] = []
  @observable
  selectKey: string[] = []
  openChange = openKeys => {
    this.openKey = openKeys.slice(-1)
  }
  onSelect = sel => {
    this.selectKey = sel ? sel.selectedKeys : sel
  }
  componentDidMount() {
    routeEvent.on(this.onKeyChanged)
  }
  private onKeyChanged = (key: string) => {
    if (!key) {
      return
    }
    const [openKey, selectedKey] = key.split(':')
    this.selectKey = [selectedKey]
    this.openKey = [openKey]
  }
  componentWillUnmount() {
    routeEvent.off(this.onKeyChanged)
  }
  isSelected = (key: string) => {
    return this.selectKey.indexOf(key) !== -1
  }
  render() {
    const { data, onCollapse, isMobile } = this.props
    if (!data) {
      return null
    }
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={this.selectKey}
        onSelect={this.onSelect}
        style={{ margin: '16px 0', width: '100%' }}
        onClick={() => (isMobile ? onCollapse() : null)}
      >
        {data
          .filter(c => c.pid === 0)
          .map(m => {
            const childMenus = data.filter(o => o.pid === m.id)
            return m.pid === 0 && childMenus.length > 0 ? (
              <SubMenu
                key={`${getUrlWithPrefix(`/${m.tabName}`)}`}
                title={
                  <span>
                    {icons[m.icon]}
                    <span className="nav-text" title={m.menusName}>
                      {m.menusName}
                    </span>
                  </span>
                }
              >
                {childMenus.map(mc => {
                  return (
                    <Menu.Item
                      key={`${mc.menuUrl}`}
                      className={
                        this.isSelected(getUrlWithPrefix(mc.menuUrl))
                          ? 'ant-menu-item-selected'
                          : ''
                      }
                    >
                      <Link to={getUrlWithPrefix(mc.menuUrl)}>
                        {mc.menusName}
                      </Link>
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            ) : (
              <Menu.Item
                key={`${m.menuUrl}`}
                className={
                  this.isSelected(m.menuUrl) ? 'ant-menu-item-selected' : ''
                }
              >
                <Link to={getUrlWithPrefix(getUrlWithPrefix(m.menuUrl))}>
                  {icons[m.icon]}
                  <span>{m.menusName}</span>
                </Link>
              </Menu.Item>
            )
          })}
      </Menu>
    )
  }
}

export default BaseMenu

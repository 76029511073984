/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 配置权限组【菜单管理】
 */
export interface MenuMenuBindActionGroupsEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 配置权限组【菜单管理】
 */
/**
* ResponseData :<br />
code = 0 执行成功 <br />
code=1 执行失败
*/
export interface MenuMenuBindActionGroupsParams {
  /**
   * 权限组IDs，多个以[,]隔开  string
   */
  actionGroupIDs: string

  /**
   * 菜单ID
   */
  menuID: number
}

/**
 * 配置权限组【菜单管理】
 */
export const menuMenuBindActionGroups = (
  params: MenuMenuBindActionGroupsParams
) => {
  return client.post<IClientResp<MenuMenuBindActionGroupsEntity>>(
    '/Menu/MenuBindActionGroups',
    params
  )
}

/**
 * - **通过** `Inject('menuMenuBindActionGroupsStore')` 依赖注入到组件
 * - **配置权限组【菜单管理】** MenuMenuBindActionGroupsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuMenuBindActionGroupsStore: MenuMenuBindActionGroupsStore
		}
		@Inject('menuMenuBindActionGroupsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuMenuBindActionGroupsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuMenuBindActionGroupsStore extends AsyncStore {
  @observable
  menuBindActionGroupsEntity?: MenuMenuBindActionGroupsEntity = undefined
  @action
  async menuBindActionGroups(params: MenuMenuBindActionGroupsParams) {
    return this.asyncAction<MenuMenuBindActionGroupsEntity>(
      'menuBindActionGroupsEntity',
      menuMenuBindActionGroups(params)
    )
  }
}

export const menuMenuBindActionGroupsStore = new MenuMenuBindActionGroupsStore()

import { Spin } from 'antd'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import IntlContext from 'routes/context'
import { HomeSsoLoginStore } from 'services/home/sso_login'
import { Inject } from 'store'
import { saveAuth } from 'utils/authentication'
import { getKeyByName, lang } from '../../lang'

export interface SsoLoginProps extends RouteComponentProps {
  homeSsoLoginStore: HomeSsoLoginStore
}

@Inject(s => ({
  homeSsoLoginStore: s.homeSsoLoginStore
}))
@observer
class SsoLogin extends Component<SsoLoginProps, any> {
  static contextType = IntlContext
  async componentDidMount() {
    const { match, homeSsoLoginStore } = this.props
    const { from, path, languageNo } = match.params as any
    const { switchLocale } = this.context
    switchLocale(getKeyByName(languageNo))
    const res = await homeSsoLoginStore.ssoLogin(match.params as any)
    if (res && res.code === 0) {
      saveAuth({
        ...res.body,
        from
      })
      this.props.history.push(from ? `/${from}/${path}` : '')
    }
  }
  render() {
    const { match } = this.props
    const { from } = match.params as any
    return (
      <div style={{ textAlign: 'center', marginTop: 50 }}>
        {from ? <Spin /> : <Spin tip={lang.messages.logining} />}
      </div>
    )
  }
}

export default SsoLogin

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 编辑菜单权限【RY编辑菜单权限】
 */
export interface MenuModifyAuthorityEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 编辑菜单权限【RY编辑菜单权限】
 */
export interface MenuModifyAuthorityParams {
  venderName: string
  siteNo: string

  /**
   * 菜单ID，多个菜单以[,]隔开
   */
  menuIDs: string

  /**
   * 菜单权限 ID
   */
  menuActionConfigID: number
}

/**
 * 编辑菜单权限【RY编辑菜单权限】
 */
export const menuModifyAuthority = (params: MenuModifyAuthorityParams) => {
  return client.post<IClientResp<MenuModifyAuthorityEntity>>(
    '/Menu/ModifyAuthority',
    params
  )
}

/**
 * - **通过** `Inject('menuModifyAuthorityStore')` 依赖注入到组件
 * - **编辑菜单权限【RY编辑菜单权限】** MenuModifyAuthorityStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuModifyAuthorityStore: MenuModifyAuthorityStore
		}
		@Inject('menuModifyAuthorityStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuModifyAuthorityStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuModifyAuthorityStore extends AsyncStore {
  @observable modifyAuthorityEntity?: MenuModifyAuthorityEntity = undefined
  @action
  async modifyAuthority(params: MenuModifyAuthorityParams) {
    return this.asyncAction<MenuModifyAuthorityEntity>(
      'modifyAuthorityEntity',
      menuModifyAuthority(params)
    )
  }
}

export const menuModifyAuthorityStore = new MenuModifyAuthorityStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 错误日志分页数据【错误日志】
 */
export interface data {
  /**
   * 日志编号
   */
  id: number

  /**
   * 方法名称（命名空间.类名.方法名）
   */
  methodName: string

  /**
   * 传入参数值
   */
  inMsg: string

  /**
   * 错误信息
   */
  errorMsg: string

  /**
   * 日志分类
   */
  className: string

  /**
   * 日志记录时间
   */
  logDateTime: string

  /**
   * Url
   */
  url: string

  /**
   * HttpMethod
   */
  httpMethod: string

  /**
   * HttpFormData
   */
  httpFormData: string

  /**
   * SiteNo
   */
  siteNo: string

  /**
   * UserName
   */
  userName: string

  /**
   * ErrorMsgHtml
   */
  errorMsgHtml: string

  /**
   * IsDebug
   */
  isDebug: boolean
}
export interface LogGetErrorLogListEntity {
  recondCount: number
  data: data[]
}

/**
 * 错误日志分页数据【错误日志】
 */
export interface LogGetErrorLogListParams {
  /**
   * 开始时间(eg:[2017-06-12 12:00:00])
   */
  BeginTime?: string

  /**
   * 结束时间(eg:[2018-06-12 12:00:00])
   */
  EndTime?: string

  /**
   * 日志分类
   */
  ClassName?: string

  /**
   * URL
   */
  Url?: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 错误日志分页数据【错误日志】
 */
export const logGetErrorLogList = (params: LogGetErrorLogListParams) => {
  return client.get<IClientResp<LogGetErrorLogListEntity>>(
    '/Log/GetErrorLogList',
    { params }
  )
}

/**
 * - **通过** `Inject('logGetErrorLogListStore')` 依赖注入到组件
 * - **错误日志分页数据【错误日志】** LogGetErrorLogListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetErrorLogListStore: LogGetErrorLogListStore
		}
		@Inject('logGetErrorLogListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetErrorLogListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetErrorLogListStore extends AsyncStore {
  @observable getErrorLogListEntity?: LogGetErrorLogListEntity = undefined
  @action
  async getErrorLogList(params: LogGetErrorLogListParams) {
    return this.asyncAction<LogGetErrorLogListEntity>(
      'getErrorLogListEntity',
      logGetErrorLogList(params)
    )
  }
}

export const logGetErrorLogListStore = new LogGetErrorLogListStore()

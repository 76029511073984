/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取分站列表
 */
export interface HomeGetSiteListEntityItem {
  /**
   * 厂商名称(不可以为空)
   */
  venderName: string

  /**
   * 分站编码(不可以为空)
   */
  siteNo: string

  /**
   * 分站昵称(不可以为空)
   */
  siteName: string
}
export type HomeGetSiteListEntity = HomeGetSiteListEntityItem[]

/**
 * 获取分站列表
 */
export const homeGetSiteList = () => {
  return client.get<IClientResp<HomeGetSiteListEntity>>('/Home/GetSiteList')
}

/**
 * - **通过** `Inject('homeGetSiteListStore')` 依赖注入到组件
 * - **获取分站列表** HomeGetSiteListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			homeGetSiteListStore: HomeGetSiteListStore
		}
		@Inject('homeGetSiteListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			homeGetSiteListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class HomeGetSiteListStore extends AsyncStore {
  @observable getSiteListEntity?: HomeGetSiteListEntity = undefined
  @action
  async getSiteList() {
    return this.asyncAction<HomeGetSiteListEntity>(
      'getSiteListEntity',
      homeGetSiteList()
    )
  }
}

export const homeGetSiteListStore = new HomeGetSiteListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 编辑公告
 */
export interface NoticeEditEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 编辑公告
 */
export interface NoticeEditParams {
  /**
   * ID
   */
  id: number

  /**
   * 分站编码列表：ALL表示全部分站，多个分站编码使用[,]分隔(前后也都要有一个[,])，eg:,00-08,00-29,
   */
  siteNoList: string

  /**
   * 状态(0--禁用  1--启用)
   */
  state: boolean

  /**
   * 是否置顶 （1--置顶）
   */
  isTop: boolean

  /**
   * 查看公告的用户类型列表(0--后台(分站账号查看)  1--前台(会员查看)),多个使用[,]分隔,eg: 1,2
   */
  userTypeList: string

  /**
   * 公告类型列表【1--跑马灯 2--弹出公告】,可以同时为多种类型采用[,]分隔
   */
  typeList: string

  /**
   * 公告启用时间
   */
  startDateTime: string

  /**
   * 公告失效时间
   */
  endDateTime: string

  /**
   * 公告内容(简体)
   */
  content_zh_cn: string

  /**
   * 公告内容(繁体)
   */
  content_zh_tw: string

  /**
   * 公告内容(繁体)
   */
  content_vn: string

  /**
   * 公告内容(繁体)
   */
  content_th: string

  /**
   * 公告内容(英文)
   */
  content_en: string
}

/**
 * 编辑公告
 */
export const noticeEdit = (params: NoticeEditParams) => {
  return client.post<IClientResp<NoticeEditEntity>>('/notice/edit', params)
}

/**
 * - **通过** `Inject('noticeEditStore')` 依赖注入到组件
 * - **编辑公告** NoticeEditStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			noticeEditStore: NoticeEditStore
		}
		@Inject('noticeEditStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			noticeEditStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class NoticeEditStore extends AsyncStore {
  @observable editEntity?: NoticeEditEntity = undefined
  @action
  async edit(params: NoticeEditParams) {
    return this.asyncAction<NoticeEditEntity>('editEntity', noticeEdit(params))
  }
}

export const noticeEditStore = new NoticeEditStore()

export default {
  pwd: '密码',
  setting: '设定',
  bet: '投注',
  profitLoss: '盈亏',
  numberOfPeople: '人数',
  todaySumarry: '今日统计',
  loginAccount: '登录账号',
  playTypeReport: '玩法报表',
  memberReport: '会员报表',
  dateReport: '日期报表',
  betDetail: '下注明细',
  editSuccess: '修改成功',
  deleteSuccess: '删除成功',
  saveSuccess: '保存成功',
  title: '泰国彩后台',
  systemAdmin: '系统管理员',
  vernderAdmin: '厂商管理员',
  siteAccount: '分站账号',
  operatorAccount: '操作者账号',
  operateConDetail: '操作内容详情',
  logining: '登录跳转中...',
  inputMemberName: '输入会员帐号',
  plsInputUserName: '请输入用户名',
  inputLangConQuery: '输入语言内容查询',
  configPermissionEntry: '配置权限项',
  operatorType: '操作类型',
  memberAccount: '会员账号',
  allInstructions: '全部指令',
  totalClassify: '全部分类',
  memberNickname: '会员昵称',
  currencyNo: '币别报表',
  memberIDLottery: '会员ID(泰国彩)',
  memberIDCS: '会员ID(厂商)',
  inputContent: '输入内容',
  totalTimeMS: '总耗时(毫秒)',
  currentAccountSearchRange: '当前账查询时间范围：',
  permission: '权限',
  allType: '所有类型',
  allClassify: '所有分类',
  editPermission: '编辑权限',
  permissionConfig: '权限配置',
  inputInstructContent: '输入指令内容查询',
  plsInputMaintainExplain: '请输入维护说明',
  allControl: '--所有控制器--',
  allClass: '--所有分类--',
  inputAuthName: '输入权限组名',
  plSelectType: '请选择分类',
  configMenuAuth: '配置菜单权限',
  open: '开',
  close: '关',
  lotNo: '期数',
  alllotterytype: '全部彩种',
  allstatus: '全部状态',
  InputNoteNumberOrMemberAccount: '输入注单号或会员账号',
  plsInputMemberAccount: '请输入会员账号',
  plsInputAmount: '输入金额',
  manualRefresh: '手动刷新',
  total: '合计',
  maxLength: '最大长度为{0}',
  // Tab菜单
  '/app': '首页',
  '/app/statisticsreport': '注单统计',
  '/app/financereport': '财务报表',
  '/app/openmanage': '开奖管理',
  '/app/gamesettings': '游戏设定',
  '/app/syssetting': '系统设定',
  '/app/logs': '日志',
  '/app/systemlogs': '系统日志',
  '/app/menu': '菜单管理',
  '/app/noticemanage': '公告配置',
  '/app/syssetting/user': '系统用户管理',
  '/app/syssetting/usergroup': '系统用户组管理',
  '/app/syssetting/sitemanagement': '分站管理',
  '/app/syssetting/sysmaintain': '系统维护',
  '/app/syssetting/vendermanagement': '厂商管理',
  '/app/syssetting/languagemanagement': '多语言管理',
  '/app/syssetting/operatecommand': '操作指令管理',
  '/app/syssetting/operatecommandclass': '操作指令分类管理',
  '/app/menu/config': '菜单配置',
  '/app/menu/authoritygroup': '权限组管理',
  '/app/menu/authorityitem': '权限项管理',
  '/app/menu/controller': '控制器管理',
  '/app/menu/usertemplate': '权限模板管理',
  '/app/systemlogs/ctrlloginlogs': '管理员登录日志',
  '/app/systemlogs/errorlogs': '错误日志',
  '/app/systemlogs/apilogs': 'API日志',
  '/app/logs/memberdynamiclogs': '会员动态日志',
  '/app/logs/operatelogs': '操作日志',
  '/app/logs/memberloginlogs': '会员登录日志',
  '/app/logs/siteloginlogs': '分站登录日志',
  '/app/financereport/currentaccount': '当前账',
  '/app/financereport/countdetail': '注单查询',
  '/app/statisticsreport/today': '今日注单',
  '/app/statisticsreport/realtime': '实时注单',
  '/app/userinfo/userlist': '用户列表',
  '/app/openmanage/openmanagedetail': '开奖管理',
  '/app/openmanage/openresult': '开奖历史',
  '/app/gamesettings/playconfig': '玩法配置',
  '/app/gamesettings/lotteryconfig': '彩种启停',
  '/app/gamesettings/lotteryonekey': '一键启停管理',
  '/app/noticemanage/noticelist': '公告管理'
}

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 添加操作指令分类【操作指令分类管理】
 */
export interface SysAddOperateCommandClassEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 添加操作指令分类【操作指令分类管理】
 */
export interface SysAddOperateCommandClassParams {
  /**
   * 分类名称
   */
  className: string

  /**
   * 操作命令分类名称(简体)
   */
  classNickName_zh_cn: string

  /**
   * 操作命令分类名称(繁体)
   */
  classNickName_zh_tw: string

  /**
   * 操作命令分类名称(英文)
   */
  classNickName_en: string

  /**
   * 操作命令分类名称(越南文)
   */
  classNickName_vn: string

  /**
   * 操作命令分类名称(泰文)
   */
  classNickName_th: string
  oldClassName: string
}

/**
 * 添加操作指令分类【操作指令分类管理】
 */
export const sysAddOperateCommandClass = (
  params: SysAddOperateCommandClassParams
) => {
  return client.post<IClientResp<SysAddOperateCommandClassEntity>>(
    '/Sys/AddOperateCommandClass',
    params
  )
}

/**
 * - **通过** `Inject('sysAddOperateCommandClassStore')` 依赖注入到组件
 * - **添加操作指令分类【操作指令分类管理】** SysAddOperateCommandClassStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysAddOperateCommandClassStore: SysAddOperateCommandClassStore
		}
		@Inject('sysAddOperateCommandClassStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysAddOperateCommandClassStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysAddOperateCommandClassStore extends AsyncStore {
  @observable
  addOperateCommandClassEntity?: SysAddOperateCommandClassEntity = undefined
  @action
  async addOperateCommandClass(params: SysAddOperateCommandClassParams) {
    return this.asyncAction<SysAddOperateCommandClassEntity>(
      'addOperateCommandClassEntity',
      sysAddOperateCommandClass(params)
    )
  }
}

export const sysAddOperateCommandClassStore = new SysAddOperateCommandClassStore()

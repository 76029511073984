// tslint:disable-next-line: no-var-requires
const EventEmitter = require('eventemitter3')
const EE = new EventEmitter()

const key = 'tab-route-has-changed'

type Emitted = (key: string) => void

class RouteEvent<T> {
  on(emitted: Emitted) {
    EE.on(key, emitted)
  }
  off(emitted: Emitted) {
    EE.removeListener(key, emitted)
  }
  emit(data: T) {
    EE.emit(key, data)
  }
}
export const routeEvent = new RouteEvent<string>()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取系统维护数据列表【系统维护】
 */
export interface data {
  /**
   * 站点类型（1--前台下注端 2--后台）
   */
  siteType: number

  /**
   * 状态(1--开放 0--维护)
   */
  state: boolean

  /**
   * 维护开始时间
   */
  startDateTime: string

  /**
   * 维护开始时间
   */
  endDateTime: string

  /**
   * 维护说明(简体)
   */
  msg_zh_cn: string

  /**
   * 维护说明(繁体)
   */
  msg_zh_tw: string

  /**
   * 维护说明(英文)
   */
  msg_en: string

  /**
   * 维护说明(越南文)
   */
  msg_vn: string

  /**
   * 维护说明(泰文)
   */
  msg_th: string

  /**
   * 是否T线(前端才T线)
   */
  isKickOut: boolean
}

/**
 * 系统维护信息表(用于提交数据)
 */
export interface SysGetMaintenancesListEntity {
  data: data[]
}

/**
 * 获取系统维护数据列表【系统维护】
 */
export const sysGetMaintenancesList = () => {
  return client.get<IClientResp<SysGetMaintenancesListEntity>>(
    '/Sys/GetMaintenancesList'
  )
}

/**
 * - **通过** `Inject('sysGetMaintenancesListStore')` 依赖注入到组件
 * - **获取系统维护数据列表【系统维护】** SysGetMaintenancesListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysGetMaintenancesListStore: SysGetMaintenancesListStore
		}
		@Inject('sysGetMaintenancesListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysGetMaintenancesListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysGetMaintenancesListStore extends AsyncStore {
  @observable
  getMaintenancesListEntity?: SysGetMaintenancesListEntity = undefined
  @action
  async getMaintenancesList() {
    return this.asyncAction<SysGetMaintenancesListEntity>(
      'getMaintenancesListEntity',
      sysGetMaintenancesList()
    )
  }
}

export const sysGetMaintenancesListStore = new SysGetMaintenancesListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取后台的公告
 */
export interface NoticeBackstageEntityItem {
  id: number
  content: string
  type: number
}
export type NoticeBackstageEntity = NoticeBackstageEntityItem[]

/**
 * 获取后台的公告
 */
export const noticeBackstage = () => {
  return client.get<IClientResp<NoticeBackstageEntity>>('/notice/backstage')
}

/**
 * - **通过** `Inject('noticeBackstageStore')` 依赖注入到组件
 * - **获取后台的公告** NoticeBackstageStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			noticeBackstageStore: NoticeBackstageStore
		}
		@Inject('noticeBackstageStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			noticeBackstageStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class NoticeBackstageStore extends AsyncStore {
  @observable backstageEntity?: NoticeBackstageEntity = undefined
  @action
  async backstage() {
    return this.asyncAction<NoticeBackstageEntity>(
      'backstageEntity',
      noticeBackstage()
    )
  }
}

export const noticeBackstageStore = new NoticeBackstageStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取游戏列表【游戏列表】
 */
export interface gameList {
  /**
   * 游戏ID
   */
  gameID: number

  /**
   * 游戏名称-简体
   */
  gameName_zh_cn: string

  /**
   * 游戏名称-繁体
   */
  gameName_zh_tw: string

  /**
   * 英文
   */
  gameName_en: string

  /**
   * 越南文
   */
  gameName_vn: string

  /**
   * 游戏名称-泰文
   */
  gameName_th: string

  /**
   * 映射多语言
   */
  gameName: string
}
export interface data {
  /**
   * 游戏类型ID
   */
  gameClassID: number

  /**
   * 状态 【true(1)-启用，false(0)-禁用】
   */
  status: boolean

  /**
   * 游戏类型名称-简体
   */
  gameClassName_zh_cn: string

  /**
   * 游戏类型名称-繁体
   */
  gameClassName_zh_tw: string

  /**
   * 英文
   */
  gameClassName_en: string

  /**
   * 游戏类型名称-越南文
   */
  gameClassName_vn: string

  /**
   * 游戏类型名称-泰文
   */
  gameClassName_th: string

  /**
   * 映射多语言
   */
  gameClassName: string

  /**
   * 子游戏集合
   */
  gameList: gameList[]
}
export interface VenderGetGameClassListEntity {
  data: data[]
}

/**
 * 获取游戏列表【游戏列表】
 */
export const venderGetGameClassList = () => {
  return client.get<IClientResp<VenderGetGameClassListEntity>>(
    '/Vender/GetGameClassList'
  )
}

/**
 * - **通过** `Inject('venderGetGameClassListStore')` 依赖注入到组件
 * - **获取游戏列表【游戏列表】** VenderGetGameClassListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			venderGetGameClassListStore: VenderGetGameClassListStore
		}
		@Inject('venderGetGameClassListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			venderGetGameClassListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class VenderGetGameClassListStore extends AsyncStore {
  @observable getGameClassListEntity?: VenderGetGameClassListEntity = undefined
  @action
  async getGameClassList() {
    return this.asyncAction<VenderGetGameClassListEntity>(
      'getGameClassListEntity',
      venderGetGameClassList()
    )
  }
}

export const venderGetGameClassListStore = new VenderGetGameClassListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 用户组管理列表(分页)【系统用户组管理】
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 授权菜单ID列表(eg: ,1,20,)
   */
  menuIDList: string

  /**
   * 授权ActionID列表(eg: ,1,20,)
   */
  actionIDList: string

  /**
   * 状态(0--禁用  1--启用) (默认值：1)
   */
  state: boolean

  /**
   * 备注
   */
  remark: string

  /**
   * 用户组名称
   */
  userGroupName: string

  /**
   * 菜单列表
   */
  menuList: string[]

  /**
   * 权限组列表
   */
  actionList: string[]
}
export interface SysGetUserGroupListEntity {
  recondCount: number
  data: data[]
}

/**
 * 用户组管理列表(分页)【系统用户组管理】
 */
export interface SysGetUserGroupListParams {
  /**
   * 每页记录数
   */
  PageSize: number

  /**
   * 当前页码
   */
  PageIndex: number

  /**
   * 用户组状态
   */
  state?: string

  /**
   * 用户组名称
   */
  userGroupName?: string
}

/**
 * 用户组管理列表(分页)【系统用户组管理】
 */
export const sysGetUserGroupList = (params: SysGetUserGroupListParams) => {
  return client.get<IClientResp<SysGetUserGroupListEntity>>(
    '/Sys/GetUserGroupList',
    { params }
  )
}

/**
 * - **通过** `Inject('sysGetUserGroupListStore')` 依赖注入到组件
 * - **用户组管理列表(分页)【系统用户组管理】** SysGetUserGroupListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysGetUserGroupListStore: SysGetUserGroupListStore
		}
		@Inject('sysGetUserGroupListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysGetUserGroupListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysGetUserGroupListStore extends AsyncStore {
  @observable getUserGroupListEntity?: SysGetUserGroupListEntity = undefined
  @action
  async getUserGroupList(params: SysGetUserGroupListParams) {
    return this.asyncAction<SysGetUserGroupListEntity>(
      'getUserGroupListEntity',
      sysGetUserGroupList(params)
    )
  }
}

export const sysGetUserGroupListStore = new SysGetUserGroupListStore()

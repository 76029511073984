/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取全部操作命令数据【操作指令管理】
 */
export interface SysGetOperateCommandAllEntityItem {
  /**
   * ID
   */
  id: number

  /**
   * 分类名称(分类名称长度不能超过30个字符)
   */
  className: string

  /**
   * 命令名称(格式：[分类名称_命令名称])
   */
  keyName: string

  /**
   * 登录用户类型(0--系统管理员 1--厂商管理账号 2-- 子厂商管理账号  3--分站账号)
   */
  userType: string

  /**
   * 备注
   */
  remark: string

  /**
   * 操作命令名称(简体)
   */
  commandName_zh_cn: string

  /**
   * 操作命令名称(繁体)
   */
  commandName_zh_tw: string

  /**
   * 操作命令名称(英文)
   */
  commandName_en: string

  /**
   * 操作命令名称(越南文)
   */
  commandName_vn: string

  /**
   * 操作命令名称(泰文)
   */
  commandName_th: string
}
export type SysGetOperateCommandAllEntity = SysGetOperateCommandAllEntityItem[]

/**
 * 获取全部操作命令数据【操作指令管理】
 */
export const sysGetOperateCommandAll = () => {
  return client.get<IClientResp<SysGetOperateCommandAllEntity>>(
    '/Sys/GetOperateCommandALL'
  )
}

/**
 * - **通过** `Inject('sysGetOperateCommandAllStore')` 依赖注入到组件
 * - **获取全部操作命令数据【操作指令管理】** SysGetOperateCommandAllStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysGetOperateCommandAllStore: SysGetOperateCommandAllStore
		}
		@Inject('sysGetOperateCommandAllStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysGetOperateCommandAllStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysGetOperateCommandAllStore extends AsyncStore {
  @observable
  getOperateCommandAllEntity?: SysGetOperateCommandAllEntity = undefined
  @action
  async getOperateCommandAll() {
    return this.asyncAction<SysGetOperateCommandAllEntity>(
      'getOperateCommandAllEntity',
      sysGetOperateCommandAll()
    )
  }
}

export const sysGetOperateCommandAllStore = new SysGetOperateCommandAllStore()

export default {
  "noticeText": "公告",
  "cancel": "取消",
  "define": "確定",
  "title": "泰國彩後臺",
  "totalRecord": "共{total}條記錄",
  "loginInvalid": "登錄失效，請重新登錄",
  "welcome": "歡迎您",
  "exit": "退出",
  "betNo": "注單編號",
  "betDateTime": "下注時間",
  "lottery": "彩種",
  "playType": "玩法",
  "betNumber": "投注號碼",
  "multiple": "賠率",
  "betMoney": "投注金額",
  "waterWithdrawal": "退水",
  "memberWinOrLose": "會員輸贏",
  "state": "狀態",
  "completed": "已完成",
  "changedOrder": "已改單",
  "invalidNote": "無效注單",
  "counterSettlement": "反結算",
  "reversed": "已撤單",
  "pageTotal": "小計",
  "total": "總計",
  "query": "查詢",
  "currentAccountHome": "當前賬首頁",
  "date": "日期:",
  "betNums": "筆數",
  "member": "會員",
  "betDetail": "下注明細",
  "currency": "貨幣",
  "excludeTestAccount": "排除測試賬號",
  "settlementStatement": "交收報表",
  "classifiedReport": "分類報表",
  "dealed": "已結算",
  "byDate": "按日期",
  "lotteryTypeName": "彩種名稱",
  "queryTime": "查詢時間",
  "yesterday": "昨天",
  "today": "今天",
  "lastWeek": "上週",
  "thisWeek": "本週",
  "lastMonth": "上月",
  "thisMonth": "本月",
  "reportType": "報表類型",
  "reset": "重置",
  "orderByGroupKey": "按照分組關鍵字排序",
  "inverseByGameWinLose": "遊戲輸贏倒序",
  "orderByGameWinLose": "遊戲輸贏順序",
  "inverseByBetMoney": "下注金額倒序",
  "orderByBetMoney": "下注金額順序",
  "search": "搜索",
  "openDraw": "開盤",
  "closeDraw": "封盤",
  "close": "關閉",
  "editConfig": "修改配置",
  "save": "保存",
  "currentPeriod": "當前期數",
  "switch": "開關",
  "closeAutoState": "你確定要關閉自動結算嗎?",
  "openAutoState": "你確定要開啓自動結算嗎?",
  "startDateTime": "開始時間",
  "aotuOpen": "是否自動開盤",
  "closePanDateTime": "封盤時間",
  "countDown": "倒計時",
  "openResultTime": "開獎時間",
  "venderName": "廠商名稱",
  "siteNo": "分站編號",
  "siteName": "分站名稱",
  "currentState": "當前狀態",
  "allPlatform": "全平臺",
  "openPanNumber": "開盤數量",
  "oneKeyClosePan": "確定對所有分站進行封盤嗎？",
  "oneKeyClose": "一鍵封盤",
  "closePanNumber": "封盤數量",
  "oneKeyOpenPan": "確定對所有分站進行開盤嗎？",
  "oneKeyOpen": "一鍵開盤",
  "EveryTimeBet": "額度每投注",
  "everyTimeReduce": "每次降低",
  "startUsing": "啓用",
  "configType": "配置類型",
  "playTypeConfig": "玩法配置",
  "oddsChange": "賠率變動",
  "autoOdds": "自動降賠",
  "assignedNumber": "指定號碼",
  "fillBetContent": "請輸入指定號碼",
  "fillChangeValues": "請輸入賠率",
  "expiry": "有效期",
  "operation": "操作",
  "edit": "編輯",
  "delete": "刪除",
  "allPlayType": "全部玩法",
  "deleteSelectedConfirm": "確定要刪除所選?",
  "deleteSelected": "刪除所選",
  "add": "新增",
  "editPlayType": "編輯玩法",
  "addPlayType": "新增玩法",
  "minBet": "最小投注",
  "min": "最小",
  "singleInjectionLimit": "單註上限",
  "max": "最大",
  "singleLimit": "單期限額",
  "onlineMember": "在線會員",
  "sevenDataGameData": "近七天投注數據",
  "todayGameData": "今日遊戲數據",
  "plsInputUserName": "請填寫用戶名",
  "plsInputPwd": "請填寫密碼",
  "atLeastEight": "最少八位數",
  "login": "登錄",
  "normal": "正常",
  "warning": "警告",
  "artificialBan": "人工禁言",
  "systemBan": "系統禁言",
  "memberUser": "會員暱稱/賬號",
  "remarksContent": "備註內容",
  "operateTime": "操作時間",
  "loginDateTime": "登錄時間",
  "loginIP": "登錄IP",
  "operateUserName": "操作者姓名",
  "operatorUserType": "操作者用戶類型",
  "csManageAccount": "廠商管理賬號",
  "zcsManageAccount": "子廠商管理賬號",
  "siteAccount": "分站帳號",
  "operateCommand": "操作命令",
  "operatorIP": "操作者IP",
  "operateContent": "操作內容",
  "see": "查看",
  "userType": "用戶類型",
  "authName": "權限組名稱",
  "plsInputName": "請輸入名稱",
  "authNameTw": "權限組名稱(繁體)",
  "authNameEn": "權限組名稱(英文)",
  "authNameVn": "權限組名稱(越南文)",
  "authNameTh": "權限組名稱(泰文)",
  "autoSelected": "自動選中",
  "orderNumber": "排序碼",
  "remark": "備註",
  "addNewAuthGroup": "添加權限組",
  "updateNewAuthGroup": "修改控權限組",
  "typeName": "分類名稱",
  "true": "是",
  "false": "否",
  "order": "排序",
  "modify": "修改",
  "sureDelete": "你確定要刪除嗎？",
  "configAuth": "配置權限組",
  "addNewAuth": "新增權限組",
  "bindAuth": "綁定權限組",
  "actionName": "權限項名稱",
  "actionNameZhCn": "權限項別稱",
  "plsInputNameZh": "請輸入名稱",
  "actionName_zh_tw": "權限項別稱(繁體)",
  "plsInputNameTw": "請輸入名稱(繁體)",
  "actionName_en": "權限項別稱(英文)",
  "plsInputNameEn": "請輸入名稱(英文)",
  "actionName_vn": "權限項別稱(越南文)",
  "plsInputNameVn": "請輸入名稱(越南文)",
  "actionName_th": "權限項別稱(泰文)",
  "plsInputNameTh": "請輸入名稱(泰文)",
  "controllerName": "控制器名稱",
  "isShow": "是否顯示",
  "addNewAuthItem": "新增權限項",
  "belongMenu": "所屬菜單",
  "mainMenu": "主菜單",
  "titleZh": "主菜單(簡體)",
  "outputLimit": "超出限制",
  "titleTw": "菜單名稱(繁體)",
  "titleEn": "菜單名稱(英文)",
  "titleVn": "菜單名稱(越南文)",
  "titleTh": "菜單名稱(泰文)",
  "website": "對應網址",
  "tabName": "標籤頁名稱",
  "plsInputOrderNumber": "請輸入排序碼",
  "plsInputNumber": "請輸入數字",
  "icon": "圖標",
  "addMenu": "新增菜單",
  "addSubMenu": "新增子菜單",
  "updateMenu": "修改菜單",
  "menuName": "菜單名稱",
  "subTitle": "子菜單",
  "anotherNameZh": "控制器別稱(簡體)",
  "plsInputNickName": "請輸入別稱",
  "controllerNickNameTw": "控制器別稱(繁體)",
  "controllerNickNameEn": "控制器別稱(英文)",
  "controllerNickNameVn": "控制器別稱(越南文)",
  "controllerNickNameTh": "控制器別稱(泰文)",
  "addNewControl": "新增控制器",
  "modifyController": "修改控制器",
  "controllerNickName": "控制器別稱",
  "return": "返回",
  "templateType": "模板類型",
  "templateNameZh": "模板名稱(簡體)",
  "templateNameTw": "模板名稱(繁體)",
  "templateNameEn": "模板名稱(英文)",
  "templateNameVn": "模板名稱(越南文)",
  "templateNameTh": "模板名稱(泰文)",
  "addTemplate": "新增模板",
  "modifyTemplate": "修改模板",
  "templateName": "模板名稱",
  "notFound": "抱歉，你訪問的頁面不存在。",
  "returnHome": "返回首頁",
  "addNoticeText": "發佈公告",
  "editNoticeText": "編輯公告",
  "sendWebSite": "發送分站",
  "noticeContentText": "公告內容",
  "textZh": "簡體",
  "textTw": "繁體",
  "textEn": "英文",
  "textTh": "泰文",
  "textVn": "越南文",
  "noticeTime": "公告時間",
  "isState": "是否啓用",
  "isTop": "是否置頂",
  "object": "對象",
  "backstage": "後臺",
  "reception": "前臺",
  "noticeType": "公告類型",
  "horseRaceLamp": "跑馬燈",
  "popUpNotice": "彈出公告",
  "endDateTime": "結束時間",
  "no": "序號",
  "openPanDateTime": "開盤時間",
  "addNewNo": "添加期數",
  "openResult": "開獎號碼",
  "autoDeal": "自動結算",
  "deal": "未結算",
  "dealing": "結算中",
  "backDealing": "反結算中",
  "againDealing": "重新結算中",
  "noSignDealState": "無效結算狀態",
  "unknowState": "未知狀態",
  "firstPrize": "一等獎",
  "thirdPrizeFront": "前3位數字",
  "thirdPrizeEnd": "後3位數字",
  "secondPrizeEnd": "後2位數字",
  "thirdPrizeUp": "三定位上",
  "secondPrizeUp": "二定位上",
  "secondPrizeDown": "二定位下",
  "failBill": "無效結算",
  "settle": "結算",
  "againSettle": "重新結算",
  "defineDo": "你確定要這樣做嗎？",
  "notToDrawTime": "未到開獎時間",
  "resultInput": "結果錄入",
  "clearNumber": "清除號碼",
  "exportReport": "導出注單",
  "greaterThan": "大於",
  "amountHighlight": "金額高亮",
  "lessThan": "小於",
  "amountNotShow": "金額不顯示",
  "excludeTrialAccount": "排除試玩賬號",
  "second": "秒",
  "reload": "刷新",
  "plsInputKey": "請輸入KEY",
  "formatError": "格式錯誤",
  "explain": "說明",
  "plsInputExplain": "請輸入說明",
  "contentZh": "簡體內容",
  "plsInputContentZh": "請輸入簡體內容",
  "contentTw": "繁體內容",
  "plsInputContentTw": "請輸入繁體內容",
  "contentEn": "英文內容",
  "plsInputContentEn": "請輸入英文內容",
  "editLanguage": "編輯多語言",
  "addLanguage": "添加多語言",
  "addMultilingual": "新增多語言",
  "time": "時間",
  "plsInputID": "請輸入ID",
  "plsSelectUserType": "請選擇用戶類型",
  "plsInputKeyName": "請輸入KeyName",
  "addInstruct": "新增指令",
  "plsInputClassName": "請輸入ClassName",
  "addInstructClassify": "新增指令分類",
  "siteType": "類型",
  "maintainTimeSlot": "維護時間段",
  "maintainExplain": "維護說明",
  "maintainState": "維護狀態",
  "openUp": "開放",
  "maintain": "維護",
  "maintainExplainZh": "維護說明(簡體)：",
  "maintainTime": "維護時間：",
  "plsInputTime": "請填寫時間！",
  "memberOperation": "會員操作：",
  "kickOutAllMember": "踢出所有會員",
  "maintainSettings": "維護設置",
  "account": "賬號",
  "nickname": "暱稱",
  "userGroupList": "用戶組",
  "stopUsing": "停用",
  "ipManagement": "IP管理",
  "addUser": "新增用戶",
  "blackIPList": "IP黑名單",
  "multipleIPRule": "多個IP用,隔開",
  "bindIPList": "IP白名單",
  "twoToEightEnglishletterChinese": "2-8位英文、中文",
  "twoToEightEnglishletter": "2-8位英文",
  "twoToEightEnglishletterThai": "2-8位英文、泰文",
  "twoToEightEnglishletterVietnam": "2-8位英文、越南傣文",
  "plsInputAccount": "請輸入賬號",
  "numbersFromSixToEighteenInEnglish": "6~18的數字英文",
  "accountNickname": "賬號暱稱",
  "plsInputNickname": "請輸入暱稱",
  "loginPwd": "登錄密碼",
  "eightToEighteenLettersNumbersSymbols": "8-18位字母、數字、符號",
  "permissionStatus": "權限狀態",
  "modifyUser": "修改用戶",
  "userGroupName": "用戶組名稱",
  "userGroupRemark": "用戶組備註",
  "permissionGroupStatus": "權限組狀態",
  "addUserGroup": "新增用戶組",
  "plsInputRemark": "請輸入備註",
  "modifyUserGroup": "修改用戶組",
  "venderNo": "廠商編碼",
  "venderNickname": "廠商暱稱",
  "callLog": "調用日誌",
  "calledLog": "被調用日誌",
  "classify": "分類",
  "httpFormData": "傳入參數",
  "outParam": "傳出參數",
  "totalTimes": "總耗時",
  "recordingTime": "記錄時間",
  "detailedInformation": "詳細信息",
  "errorMessage": "錯誤信息",
  "venderMemberID": "會員ID（廠商）",
  "lottoMemberID": "泰國彩",
  "balance": "餘額",
  "currencyNo": "貨幣編號",
  "lastLogin": "最後登錄",
  "loginLogs": "登錄日誌",
  "lookatOnline": "只看在線"
}
/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 删除权限组【权限组管理】
 */
export interface MenuDeleteActionGroupsEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 删除权限组【权限组管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface MenuDeleteActionGroupsParams {
  /**
   * ID
   */
  id: number
}

/**
 * 删除权限组【权限组管理】
 */
export const menuDeleteActionGroups = (
  params: MenuDeleteActionGroupsParams
) => {
  return client.post<IClientResp<MenuDeleteActionGroupsEntity>>(
    '/Menu/DeleteActionGroups',
    params
  )
}

/**
 * - **通过** `Inject('menuDeleteActionGroupsStore')` 依赖注入到组件
 * - **删除权限组【权限组管理】** MenuDeleteActionGroupsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuDeleteActionGroupsStore: MenuDeleteActionGroupsStore
		}
		@Inject('menuDeleteActionGroupsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuDeleteActionGroupsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuDeleteActionGroupsStore extends AsyncStore {
  @observable
  deleteActionGroupsEntity?: MenuDeleteActionGroupsEntity = undefined
  @action
  async deleteActionGroups(params: MenuDeleteActionGroupsParams) {
    return this.asyncAction<MenuDeleteActionGroupsEntity>(
      'deleteActionGroupsEntity',
      menuDeleteActionGroups(params)
    )
  }
}

export const menuDeleteActionGroupsStore = new MenuDeleteActionGroupsStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改用户组【系统用户组管理】
 */
export interface SysEditUserGroupEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 修改用户组【系统用户组管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface SysEditUserGroupParams {
  /**
   * 用户组ID
   */
  id: number

  /**
   * 用户组名
   */
  userGroupName: string

  /**
   * 状态
   */
  state: boolean

  /**
   * 备注
   */
  remark?: string
}

/**
 * 修改用户组【系统用户组管理】
 */
export const sysEditUserGroup = (params: SysEditUserGroupParams) => {
  return client.post<IClientResp<SysEditUserGroupEntity>>(
    '/Sys/EditUserGroup',
    params
  )
}

/**
 * - **通过** `Inject('sysEditUserGroupStore')` 依赖注入到组件
 * - **修改用户组【系统用户组管理】** SysEditUserGroupStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysEditUserGroupStore: SysEditUserGroupStore
		}
		@Inject('sysEditUserGroupStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysEditUserGroupStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysEditUserGroupStore extends AsyncStore {
  @observable editUserGroupEntity?: SysEditUserGroupEntity = undefined
  @action
  async editUserGroup(params: SysEditUserGroupParams) {
    return this.asyncAction<SysEditUserGroupEntity>(
      'editUserGroupEntity',
      sysEditUserGroup(params)
    )
  }
}

export const sysEditUserGroupStore = new SysEditUserGroupStore()

import { lang } from 'lang'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import React from 'react'
import * as RRD from 'react-router-dom'
// tslint:disable-next-line: no-duplicate-imports
import { matchPath } from 'react-router-dom'
import { HomeGetMenuEntity } from 'services/home/get_menu'
import { getUrlWithPrefix } from 'utils'
import { breadcrumbNameMap } from './breadcrumbNameMap'
import TabRoute from './TabRoute'

interface TabSwitchProps extends Partial<RRD.RouteComponentProps> {
  menu?: HomeGetMenuEntity
}
@observer
export class TabSwitch extends React.Component<TabSwitchProps, any> {
  @observable
  o = {
    '/app': {
      title: lang.messages['/app'],
      key: '/app',
      path: '/app',
      content: null,
      location: null
    }
  }
  render() {
    // NOTE: dom 没有明确导出这个context，以后升级的时候要注意一下
    const RouterContext = (RRD as any).__RouterContext
    return (
      <RouterContext.Consumer>
        {context => {
          // console.log('You should not use <Switch> outside a <Router>')
          const location = this.props.location || context.location
          const history = this.props.history || context.history
          // tslint:disable-next-line: one-variable-per-declaration
          let element, match
          // We use React.Children.forEach instead of React.Children.toArray().find()
          // here because toArray adds keys to all child elements and we do not want
          // to trigger an unmount/remount for two <Route>s that render the same
          // component at different URLs.
          React.Children.forEach(this.props.children, child => {
            if (match == null && React.isValidElement(child)) {
              element = child
              const path = child.props.path || child.props.from
              match = path
                ? matchPath(location.pathname, { ...child.props, path })
                : context.match
            }
          })
          if (match) {
            const { url } = match
            const qs = queryString.parse(location.search)

            const bread = breadcrumbNameMap[location.pathname]
            const title = bread ? lang.messages[location.pathname] : qs.title
            // 从menu里面查找 与当前pathname 相对应的菜单配置。如果有则启用
            const current = this.props.menu
              ? this.props.menu?.find(
                  m =>
                    m.menuUrl &&
                    getUrlWithPrefix(m.menuUrl) === location.pathname
                )
              : null

            const key = current ? getUrlWithPrefix(`/${current?.tabName}`) : url
            this.o[key] = {
              title,
              path: location.pathname,
              key,
              content: React.cloneElement(element, {
                location,
                computedMatch: match
              }),
              location
            }
            return (
              <TabRoute
                cacheRoutes={this.o}
                location={location}
                history={history}
                activeKey={url}
                onRemove={k => {
                  delete this.o[k]
                }}
              />
            )
          }
          return null
        }}
      </RouterContext.Consumer>
    )
  }
}

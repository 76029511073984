import { DownOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import anime from 'animejs'
import { Button, Dropdown, Form, Input, Menu, message, Spin } from 'antd'
import md5 from 'blueimp-md5'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps } from 'react-router-dom'
import IntlContext from 'routes/context'
import { HomeLoginStore } from 'services/home/login'
import { HomeSsoLoginStore } from 'services/home/sso_login'
import { Inject } from 'store'
import { saveAuth } from 'utils/authentication'
import language, { lang } from '../../lang'
import styles from './login.module.css'
const FormItem = Form.Item

export interface LoginProps extends RouteComponentProps {
  locale: string
  homeSsoLoginStore?: HomeSsoLoginStore
  homeLoginStore?: HomeLoginStore
}

@Inject(s => ({
  homeSsoLoginStore: s.homeSsoLoginStore,
  homeLoginStore: s.homeLoginStore
}))
@observer
class Login extends Component<LoginProps, any> {
  async componentDidMount() {
    const { match, homeSsoLoginStore, history } = this.props
    const { postdata, from, path } = match.params as any
    // sso login
    if (postdata) {
      const res = await homeSsoLoginStore!.ssoLogin(match.params as any)
      if (res && res.code === 0) {
        saveAuth({
          ...res.body,
          from
        })
        history.push(from ? `/${from}/${path}` : '')
      }
    }
    this.renderCanvas()
  }

  renderCanvas = () => {
    const canvasEl: HTMLCanvasElement | null = document.querySelector(
      '.j-fireworks'
    )
    if (canvasEl) {
      const ctx = canvasEl.getContext('2d')!
      const numberOfParticules = 30
      let pointerX = 0
      let pointerY = 0
      const colors = [
        'rgba(251, 85, 169, 0.5)',
        'rgba(215, 76, 192, 0.5)',
        'rgba(172, 66, 219, 0.5)',
        'rgba(137, 58, 241, 0.5)'
      ]

      /**
       * 设置画布
       */
      const setCanvasSize = () => {
        canvasEl.width = window.innerWidth * 2
        canvasEl.height = window.innerHeight * 2
        canvasEl.style.width = window.innerWidth + 'px'
        canvasEl.style.height = window.innerHeight + 'px'
        ctx.scale(2, 2)
      }

      const updateCoords = e => {
        pointerX = e.clientX || e.touches[0].clientX
        pointerY = e.clientY || e.touches[0].clientY
      }

      const setParticuleDirection = p => {
        const angle = (anime.random(0, 360) * Math.PI) / 180
        const value = anime.random(50, 180)
        const radius = [-1, 1][anime.random(0, 1)] * value
        return {
          x: p.x + radius * Math.cos(angle),
          y: p.y + radius * Math.sin(angle)
        }
      }

      const createParticule = (x: number, y: number) => {
        const p: any = {}
        p.x = x
        p.y = y
        p.color = colors[anime.random(0, colors.length - 1)]
        p.radius = anime.random(16, 32)
        p.endPos = setParticuleDirection(p)
        p.draw = () => {
          ctx.beginPath()
          ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true)
          ctx.fillStyle = p.color
          ctx.fill()
        }
        return p
      }

      const createCircle = (x, y) => {
        const p: any = {}
        p.x = x
        p.y = y
        p.color = 'rgba(0, 0, 0, 0.3)'
        p.radius = 0.1
        p.alpha = 1
        p.lineWidth = 1
        p.draw = () => {
          ctx.globalAlpha = p.alpha
          ctx.beginPath()
          ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true)
          ctx.lineWidth = p.lineWidth
          ctx.strokeStyle = p.color
          ctx.stroke()
          ctx.globalAlpha = 1
        }
        return p
      }

      const renderParticule = (anim: any) => {
        anim.animatables.forEach((item, i) => {
          anim.animatables[i].target.draw()
        })
      }

      const render = anime({
        duration: Infinity,
        update: () => {
          ctx.clearRect(0, 0, canvasEl.width, canvasEl.height)
        }
      })

      const animateParticules = (x: number, y: number) => {
        const circle = createCircle(x, y)
        const particules: any[] = []
        for (let i = 0; i < numberOfParticules; i++) {
          particules.push(createParticule(x, y))
        }
        anime
          .timeline({
            easing: 'easeOutExpo'
          })
          .add({
            targets: particules,
            duration: anime.random(1200, 1800),
            x: p => p.endPos.x,
            y: p => p.endPos.y,
            radius: 0.1,
            update: renderParticule
          })
          .add(
            {
              targets: circle,
              radius: 300,
              duration: 30000,
              alpha: 0,
              update: renderParticule
            },
            0
          )
      }

      setCanvasSize()

      canvasEl.addEventListener(
        'mousedown',
        e => {
          render.play()
          updateCoords(e)
          animateParticules(pointerX, pointerY)
        },
        false
      )

      window.addEventListener('resize', setCanvasSize, false)
    }
  }

  @observable
  loading = false

  onFinish = async values => {
    const { homeLoginStore, history, locale } = this.props
    if (!this.loading) {
      if (values.pwd) {
        this.loading = true
        values.pwd = md5(values.pwd)
      }
      values.langNo = language[locale].name
      const res = await homeLoginStore!.login(values)
      this.loading = false
      if (res && res.code === 0) {
        message.success(res.msg)
        saveAuth(res.body)
        history.replace('/')
      }
    }
  }

  render() {
    const { match } = this.props
    const { postdata } = match.params as any
    return (
      <div className={styles.container}>
        <canvas className={`${styles.fireworks} j-fireworks`} />
        {postdata ? (
          <Spin tip={lang.messages.logining} />
        ) : (
          <>
            <div className={styles.lang}>
              <IntlContext.Consumer>
                {({ locale, switchLocale }: any) => (
                  <Dropdown
                    overlay={() => (
                      <Menu onClick={({ key }) => switchLocale(key)}>
                        {Object.keys(language).map(key =>
                          locale !== key ? (
                            <Menu.Item key={key}>
                              {language[key].value}
                            </Menu.Item>
                          ) : null
                        )}
                      </Menu>
                    )}
                  >
                    <Button type="link">
                      {language[locale].value} <DownOutlined />
                    </Button>
                  </Dropdown>
                )}
              </IntlContext.Consumer>
            </div>
            <div className={styles.content}>
              <div className={styles.header}>
                <span className={styles.title}>
                  <FormattedMessage id="title" defaultMessage="泰国彩后台" />
                </span>
              </div>
              <div className={styles.main}>
                <Form onFinish={this.onFinish}>
                  <FormItem
                    name="userName"
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormattedMessage
                            id="plsInputUserName"
                            defaultMessage="请填写用户名"
                          />
                        )
                      }
                    ]}
                    style={{ marginBottom: 8 }}
                  >
                    <Input
                      size="large"
                      placeholder={lang.messages.plsInputUserName}
                      prefix={<UserOutlined />}
                    />
                  </FormItem>
                  <FormItem
                    name="pwd"
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormattedMessage
                            id="plsInputPwd"
                            defaultMessage="请填写密码"
                          />
                        )
                      },
                      {
                        min: 8,
                        message: (
                          <FormattedMessage
                            id="atLeastEight"
                            defaultMessage="最少八位数"
                          />
                        )
                      }
                    ]}
                    style={{ marginBottom: 8 }}
                  >
                    <Input
                      type="password"
                      size="large"
                      placeholder={lang.messages.pwd}
                      prefix={<LockOutlined />}
                    />
                  </FormItem>
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className={styles.button}
                      loading={this.loading}
                    >
                      <FormattedMessage id="login" defaultMessage="登录" />
                    </Button>
                  </FormItem>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Login

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取一级菜单子菜单【权限模版管理】
 */
export interface menuPermissionExtension {
  /**
   * 一级菜单ID
   */
  id: number

  /**
   * 是否被选中
   */
  isSelected: boolean

  /**
   * 菜单名称
   */
  menuName_zh_cn: string
  menuName_zh_tw: string
  menuName_en: string
  menuName_th: string
  menuName_vn: string
  menuName: string

  /**
   * 是否需要配置权限
   */
  hasSetting: boolean
}
export interface MenuGetMenuAuthorityEntityItem {
  /**
   * 一级菜单ID
   */
  id: number

  /**
   * 是否被选中
   */
  isSelected: boolean

  /**
   * 菜单名称
   */
  menuName_zh_cn: string
  menuName_zh_tw: string
  menuName_en: string
  menuName_th: string
  menuName_vn: string
  menuName: string

  /**
   * 是否需要配置权限
   */
  hasSetting: boolean
  menuPermissionExtension: menuPermissionExtension[]
}
export type MenuGetMenuAuthorityEntity = MenuGetMenuAuthorityEntityItem[]

/**
 * 获取一级菜单子菜单【权限模版管理】
 */
export interface MenuGetMenuAuthorityParams {
  authorityTemplateID: number
}

/**
 * 获取一级菜单子菜单【权限模版管理】
 */
export const menuGetMenuAuthority = (params: MenuGetMenuAuthorityParams) => {
  return client.get<IClientResp<MenuGetMenuAuthorityEntity>>(
    '/Menu/GetMenuAuthority',
    { params }
  )
}

/**
 * - **通过** `Inject('menuGetMenuAuthorityStore')` 依赖注入到组件
 * - **获取一级菜单子菜单【权限模版管理】** MenuGetMenuAuthorityStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetMenuAuthorityStore: MenuGetMenuAuthorityStore
		}
		@Inject('menuGetMenuAuthorityStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetMenuAuthorityStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetMenuAuthorityStore extends AsyncStore {
  @observable getMenuAuthorityEntity?: MenuGetMenuAuthorityEntity = undefined
  @action
  async getMenuAuthority(params: MenuGetMenuAuthorityParams) {
    return this.asyncAction<MenuGetMenuAuthorityEntity>(
      'getMenuAuthorityEntity',
      menuGetMenuAuthority(params)
    )
  }
}

export const menuGetMenuAuthorityStore = new MenuGetMenuAuthorityStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 人工结算操作
 */
export interface OpenManageDoSettleEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 人工结算操作
 */
export interface OpenManageDoSettleParams {
  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 期号查询
   */
  lottoNo: string
}

/**
 * 人工结算操作
 */
export const openManageDoSettle = (params: OpenManageDoSettleParams) => {
  return client.post<IClientResp<OpenManageDoSettleEntity>>(
    '/OpenManage/DoSettle',
    params
  )
}

/**
 * - **通过** `Inject('openManageDoSettleStore')` 依赖注入到组件
 * - **人工结算操作** OpenManageDoSettleStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			openManageDoSettleStore: OpenManageDoSettleStore
		}
		@Inject('openManageDoSettleStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			openManageDoSettleStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class OpenManageDoSettleStore extends AsyncStore {
  @observable doSettleEntity?: OpenManageDoSettleEntity = undefined
  @action
  async doSettle(params: OpenManageDoSettleParams) {
    return this.asyncAction<OpenManageDoSettleEntity>(
      'doSettleEntity',
      openManageDoSettle(params)
    )
  }
}

export const openManageDoSettleStore = new OpenManageDoSettleStore()

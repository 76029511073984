/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取该菜单已经绑定的权限组以及未被绑定的权限组【菜单管理】
 */
export interface actionGroups {
  /**
   * 权限组ID
   */
  id: number

  /**
   * 权限组名
   */
  actionGroupName_zh_cn: string
  actionGroupName_zh_tw: string
  actionGroupName_en: string
  actionGroupName_th: string
  actionGroupName_vn: string

  /**
   * 是否选中
   */
  isSelected: boolean

  /**
   * 彩种名称
   */
  actionGroupName: string
}
export interface MenuGetBindActionGroupsEntityItem {
  actionGroups: actionGroups[]

  /**
   * 是否选中
   */
  isSelected: boolean

  /**
   * 控制器名称（用于权限组和权限项的分类）
   */
  controllerName: string

  /**
   * 控制器的别称（用中文描述）
   */
  anotherName_zh_cn: string
  anotherName_zh_tw: string
  anotherName_en: string
  anotherName_th: string
  anotherName_vn: string

  /**
   * 彩种名称
   */
  anotherName: string
}
export type MenuGetBindActionGroupsEntity = MenuGetBindActionGroupsEntityItem[]

/**
 * 获取该菜单已经绑定的权限组以及未被绑定的权限组【菜单管理】
 */
export interface MenuGetBindActionGroupsParams {
  /**
   * 菜单ID
   */
  menuId: number
}

/**
 * 获取该菜单已经绑定的权限组以及未被绑定的权限组【菜单管理】
 */
export const menuGetBindActionGroups = (
  params: MenuGetBindActionGroupsParams
) => {
  return client.get<IClientResp<MenuGetBindActionGroupsEntity>>(
    '/Menu/GetBindActionGroups',
    { params }
  )
}

/**
 * - **通过** `Inject('menuGetBindActionGroupsStore')` 依赖注入到组件
 * - **获取该菜单已经绑定的权限组以及未被绑定的权限组【菜单管理】** MenuGetBindActionGroupsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetBindActionGroupsStore: MenuGetBindActionGroupsStore
		}
		@Inject('menuGetBindActionGroupsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetBindActionGroupsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetBindActionGroupsStore extends AsyncStore {
  @observable
  getBindActionGroupsEntity?: MenuGetBindActionGroupsEntity = undefined
  @action
  async getBindActionGroups(params: MenuGetBindActionGroupsParams) {
    return this.asyncAction<MenuGetBindActionGroupsEntity>(
      'getBindActionGroupsEntity',
      menuGetBindActionGroups(params)
    )
  }
}

export const menuGetBindActionGroupsStore = new MenuGetBindActionGroupsStore()

import NoticeModal from 'components/NoticeModal'
import { observer } from 'mobx-react'
import React from 'react'
import Marquee from 'react-simple-marquee'
import { NoticeBackstageStore } from 'services/notice/backstage'
import { Inject } from 'store'

export interface TopNoticeProps {
  noticeStore?: NoticeBackstageStore
}

@Inject(s => ({
  noticeStore: s.noticeBackstageStore
}))
@observer
export default class TopNotice extends React.Component<any> {
  ref = React.createRef<NoticeModal>()

  componentDidMount() {
    this.props.noticeStore!.backstage()
  }

  renderNotice() {
    return (
      <>
        {this.props.noticeStore!.backstageEntity?.map((item, i) => {
          // 1: 跑马灯，0:跑马灯和弹出
          if (item.type === 1 || item.type === 0) {
            return (
              <span key={i} style={{ marginRight: 10 }}>
                {item.content}
              </span>
            )
          }
        })}
      </>
    )
  }

  render() {
    return (
      <>
        <Marquee>{this.renderNotice()}</Marquee>
        {/* <NoticeModal ref={this.ref} /> */}
      </>
    )
  }
}

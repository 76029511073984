/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取公告列表
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 厂商名称(ALL表示所有厂商所有分站都可查看这个公告)
   */
  venderName: string

  /**
   * 分站编码（NULL表示系统管理员发送的公告）
   */
  siteNo: string

  /**
   * 分站编码列表：ALL表示全部分站，多个分站编码使用[,]分隔(前后也都要有一个[,])，eg:,00-08,00-29,
   */
  siteNoList: string

  /**
   * 查看公告的用户类型列表(0--后台(分站账号查看)  1--前台(会员查看)),多个使用[,]分隔,eg: 1,2
   */
  userTypeList: string

  /**
   * 公告类型列表【1--跑马灯 2--弹出公告】,可以同时为多种类型采用[,]分隔
   */
  typeList: string

  /**
   * 状态(0--禁用  1--启用)
   */
  state: boolean

  /**
   * 是否置顶 （1--置顶）
   */
  isTop: boolean

  /**
   * 公告添加时间
   */
  createDateTime: string

  /**
   * 公告启用时间
   */
  startDateTime: string

  /**
   * 公告失效时间
   */
  endDateTime: string

  /**
   * 创建者类型(0--系统管理员 1--厂商管理账号 2-- 子厂商管理账号  3--分站账号 )
   */
  creatorType: string

  /**
   * 创建者账号
   */
  creatorUserName: string

  /**
   * 创建者昵称
   */
  creatorNickName: string

  /**
   * 修改者类型(0--系统管理员 1--厂商管理账号 2-- 子厂商管理账号  3--分站账号 )
   */
  editorType: string

  /**
   * 修改者账号
   */
  editorUserName: string

  /**
   * 修改者昵称
   */
  editorNickName: string

  /**
   * 修改时间
   */
  editDateTime: string

  /**
   * 公告内容(简体)
   */
  content_zh_cn: string

  /**
   * 公告内容(繁体)
   */
  content_zh_tw: string

  /**
   * 公告内容(英文)
   */
  content_en: string
  content_vn: string
  content_th: string

  /**
   * 公告
   */
  content: string

  /**
   * 公告
   */
  operation: boolean
}
export interface NoticeListEntity {
  recondCount: number
  data: data[]
}

/**
 * 获取公告列表
 */
export interface NoticeListParams {
  /**
   * 状态(0--禁用  1--启用)
   */
  State?: boolean

  /**
   * 是否置顶 （1--置顶）
   */
  IsTop?: boolean

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 获取公告列表
 */
export const noticeList = (params: NoticeListParams) => {
  return client.get<IClientResp<NoticeListEntity>>('/notice/list', { params })
}

/**
 * - **通过** `Inject('noticeListStore')` 依赖注入到组件
 * - **获取公告列表** NoticeListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			noticeListStore: NoticeListStore
		}
		@Inject('noticeListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			noticeListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class NoticeListStore extends AsyncStore {
  @observable listEntity?: NoticeListEntity = undefined
  @action
  async list(params: NoticeListParams) {
    return this.asyncAction<NoticeListEntity>('listEntity', noticeList(params))
  }
}

export const noticeListStore = new NoticeListStore()

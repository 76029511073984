/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 更新赔率变动记录 by felix
 */
export interface LottosettingUpdateChangeOddsEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 更新赔率变动记录 by felix
 */
export interface LottosettingUpdateChangeOddsParams {
  /**
   * 传入调整赔率的ID
   */
  id: number

  /**
   * 新调整的赔率值
   */
  changeValues: number
}

/**
 * 更新赔率变动记录 by felix
 */
export const lottosettingUpdateChangeOdds = (
  params: LottosettingUpdateChangeOddsParams
) => {
  return client.post<IClientResp<LottosettingUpdateChangeOddsEntity>>(
    '/lottosetting/updateChangeOdds',
    params
  )
}

/**
 * - **通过** `Inject('lottosettingUpdateChangeOddsStore')` 依赖注入到组件
 * - **更新赔率变动记录 by felix** LottosettingUpdateChangeOddsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingUpdateChangeOddsStore: LottosettingUpdateChangeOddsStore
		}
		@Inject('lottosettingUpdateChangeOddsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingUpdateChangeOddsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingUpdateChangeOddsStore extends AsyncStore {
  @observable
  updateChangeOddsEntity?: LottosettingUpdateChangeOddsEntity = undefined
  @action
  async updateChangeOdds(params: LottosettingUpdateChangeOddsParams) {
    return this.asyncAction<LottosettingUpdateChangeOddsEntity>(
      'updateChangeOddsEntity',
      lottosettingUpdateChangeOdds(params)
    )
  }
}

export const lottosettingUpdateChangeOddsStore = new LottosettingUpdateChangeOddsStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 管理员登录日志分页数据【管理员登录日志】
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 厂商名称
   */
  venderName: string

  /**
   * 分站编码
   */
  siteNo: string

  /**
   * 登录用户类型(1--厂商管理账号 2-- 子厂商管理账号  3--分站账号 )
   */
  userType: string

  /**
   * 登录用户账号
   */
  loginUserName: string

  /**
   * 登录者昵称
   */
  loginNickName: string

  /**
   * 登录时间
   */
  loginDateTime: string

  /**
   * 登录IP
   */
  loginIP: string

  /**
   * 登录MAC
   */
  loginMAC: string

  /**
   * 登录用户类型(1--厂商管理账号 2-- 子厂商管理账号  3--分站账号 )
   */
  userTypeName: string
}
export interface LogGetSiteLoginLogListEntity {
  recondCount: number
  data: data[]
}

/**
 * 管理员登录日志分页数据【管理员登录日志】
 */
export interface LogGetSiteLoginLogListParams {
  /**
   * 开始时间(eg:[2017-06-12 12:00:00])
   */
  BeginTime?: string

  /**
   * 结束时间(eg:[2018-06-12 12:00:00])
   */
  EndTime?: string

  /**
   * 登录用户账号
   */
  LoginUserName?: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 管理员登录日志分页数据【管理员登录日志】
 */
export const logGetSiteLoginLogList = (
  params: LogGetSiteLoginLogListParams
) => {
  return client.get<IClientResp<LogGetSiteLoginLogListEntity>>(
    '/Log/GetSiteLoginLogList',
    { params }
  )
}

/**
 * - **通过** `Inject('logGetSiteLoginLogListStore')` 依赖注入到组件
 * - **管理员登录日志分页数据【管理员登录日志】** LogGetSiteLoginLogListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetSiteLoginLogListStore: LogGetSiteLoginLogListStore
		}
		@Inject('logGetSiteLoginLogListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetSiteLoginLogListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetSiteLoginLogListStore extends AsyncStore {
  @observable
  getSiteLoginLogListEntity?: LogGetSiteLoginLogListEntity = undefined
  @action
  async getSiteLoginLogList(params: LogGetSiteLoginLogListParams) {
    return this.asyncAction<LogGetSiteLoginLogListEntity>(
      'getSiteLoginLogListEntity',
      logGetSiteLoginLogList(params)
    )
  }
}

export const logGetSiteLoginLogListStore = new LogGetSiteLoginLogListStore()

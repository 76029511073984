/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 更新系统限额配置--felix
 */
export interface LottosettingUpdatesystemlimitEntity {
  id: number

  /**
   * 彩种编号
   */
  lottoID: number
  currencyNo: string

  /**
   * 彩票玩法1级分组ID
   */
  playTypeID: number

  /**
   * 最小额度
   */
  betMinMoney: number

  /**
   * 单注上限
   */
  betMaxMoney: number

  /**
   * 单项限额
   */
  itemLimitMoney: number

  /**
   * 最高赔率
   */
  maxLine: number

  /**
   * 退水 0-99 百分比单位
   */
  backMoneyPercent: number
}

/**
 * 更新系统限额配置--felix
 */
export interface LottosettingUpdatesystemlimitParams {
  data: {
    id: number

    /**
     * 彩种编号
     */
    lottoID: number
    currencyNo: string

    /**
     * 彩票玩法1级分组ID
     */
    playTypeID: number

    /**
     * 最小额度
     */
    betMinMoney: number

    /**
     * 单注上限
     */
    betMaxMoney: number

    /**
     * 单项限额
     */
    itemLimitMoney: number

    /**
     * 最高赔率
     */
    maxLine: number

    /**
     * 退水 0-99 百分比单位
     */
    backMoneyPercent: number
  }[]
}

/**
 * 更新系统限额配置--felix
 */
export const lottosettingUpdatesystemlimit = (
  params: LottosettingUpdatesystemlimitParams
) => {
  return client.post<IClientResp<LottosettingUpdatesystemlimitEntity>>(
    '/lottosetting/updatesystemlimit',
    params
  )
}

/**
 * - **通过** `Inject('lottosettingUpdatesystemlimitStore')` 依赖注入到组件
 * - **更新系统限额配置--felix** LottosettingUpdatesystemlimitStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingUpdatesystemlimitStore: LottosettingUpdatesystemlimitStore
		}
		@Inject('lottosettingUpdatesystemlimitStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingUpdatesystemlimitStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingUpdatesystemlimitStore extends AsyncStore {
  @observable
  updatesystemlimitEntity?: LottosettingUpdatesystemlimitEntity = undefined
  @action
  async updatesystemlimit(params: LottosettingUpdatesystemlimitParams) {
    return this.asyncAction<LottosettingUpdatesystemlimitEntity>(
      'updatesystemlimitEntity',
      lottosettingUpdatesystemlimit(params)
    )
  }
}

export const lottosettingUpdatesystemlimitStore = new LottosettingUpdatesystemlimitStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改是否自动开盘【彩种启停】
 */
/**
 * 修改是否自动开盘
 */
export interface LottosettingUpdateAutoOpenPanEntity {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 是否自动开盘(1--自动开盘  0--手工开盘)，主要用于泰国彩
   */
  autoOpenPan: boolean
}

/**
 * 修改是否自动开盘【彩种启停】
 */
export interface LottosettingUpdateAutoOpenPanParams {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 是否自动开盘(1--自动开盘  0--手工开盘)，主要用于泰国彩
   */
  autoOpenPan: boolean
}

/**
 * 修改是否自动开盘【彩种启停】
 */
export const lottosettingUpdateAutoOpenPan = (
  params: LottosettingUpdateAutoOpenPanParams
) => {
  return client.post<IClientResp<LottosettingUpdateAutoOpenPanEntity>>(
    '/lottosetting/updateAutoOpenPan',
    params
  )
}

/**
 * - **通过** `Inject('lottosettingUpdateAutoOpenPanStore')` 依赖注入到组件
 * - **修改是否自动开盘【彩种启停】** LottosettingUpdateAutoOpenPanStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingUpdateAutoOpenPanStore: LottosettingUpdateAutoOpenPanStore
		}
		@Inject('lottosettingUpdateAutoOpenPanStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingUpdateAutoOpenPanStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingUpdateAutoOpenPanStore extends AsyncStore {
  @observable
  updateAutoOpenPanEntity?: LottosettingUpdateAutoOpenPanEntity = undefined
  @action
  async updateAutoOpenPan(params: LottosettingUpdateAutoOpenPanParams) {
    return this.asyncAction<LottosettingUpdateAutoOpenPanEntity>(
      'updateAutoOpenPanEntity',
      lottosettingUpdateAutoOpenPan(params)
    )
  }
}

export const lottosettingUpdateAutoOpenPanStore = new LottosettingUpdateAutoOpenPanStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取className列表【操作指令管理】
 */
export type SysGetClassNameEntity = string[]

/**
 * 获取className列表【操作指令管理】
 */
export const sysGetClassName = () => {
  return client.get<IClientResp<SysGetClassNameEntity>>('/Sys/GetClassName')
}

/**
 * - **通过** `Inject('sysGetClassNameStore')` 依赖注入到组件
 * - **获取className列表【操作指令管理】** SysGetClassNameStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysGetClassNameStore: SysGetClassNameStore
		}
		@Inject('sysGetClassNameStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysGetClassNameStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysGetClassNameStore extends AsyncStore {
  @observable getClassNameEntity?: SysGetClassNameEntity = undefined
  @action
  async getClassName() {
    return this.asyncAction<SysGetClassNameEntity>(
      'getClassNameEntity',
      sysGetClassName()
    )
  }
}

export const sysGetClassNameStore = new SysGetClassNameStore()

import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined
} from '@ant-design/icons'
import { Button, Col, Dropdown, Layout, Menu, message, Row, Spin } from 'antd'
import SiderMenu from 'components/SiderMenu'
import TopNotice from 'components/TopNotice'
import dayjs from 'dayjs'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
// import Marquee from 'react-marquee'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { HomeChangeSiteStore } from 'services/home/change_site'
import { HomeChangeVendersStore } from 'services/home/change_venders'
import { HomeGetMenuStore } from 'services/home/get_menu'
import { HomeGetSiteListStore } from 'services/home/get_site_list'
import { HomeGetVenderListStore } from 'services/home/get_vender_list'
import { HomeLogoutStore } from 'services/home/logout'
import { Inject } from 'store'
import {
  getAuth,
  isAuthorized,
  removeAuth,
  saveAuth
} from 'utils/authentication'
import styles from './index.module.css'
import { RouteContent } from './routes'
const { Header, Footer } = Layout

export interface AppProps extends RouteComponentProps {
  isMobile: boolean
  homeChangeSiteStore?: HomeChangeSiteStore
  homeGetSiteListStore?: HomeGetSiteListStore
  homeLogoutStore?: HomeLogoutStore
  homeGetMenuStore?: HomeGetMenuStore
  venderListStore?: HomeGetVenderListStore
  homeChangeVendersStore?: HomeChangeVendersStore
}

@Inject(s => ({
  homeGetMenuStore: s.homeGetMenuStore,
  homeGetSiteListStore: s.homeGetSiteListStore,
  homeChangeSiteStore: s.homeChangeSiteStore,
  homeLogoutStore: s.homeLogoutStore,
  venderListStore: s.homeGetVenderListStore,
  homeChangeVendersStore: s.homeChangeVendersStore
}))
@observer
class App extends Component<AppProps, any> {
  @observable
  user: any | null = null

  @observable
  collapsed: boolean = this.props.isMobile

  componentDidMount() {
    message.config({
      maxCount: 1
    })

    const {
      homeGetSiteListStore,
      homeGetMenuStore,
      venderListStore
    } = this.props
    if (isAuthorized()) {
      this.user = getAuth()
      homeGetMenuStore!.getMenu()
      venderListStore!.getVenderList()
      homeGetSiteListStore!.getSiteList()
    } else {
      this.toLogin(
        <FormattedMessage
          id="loginInvalid"
          defaultMessage="登录失效，请重新登录"
        />
      )
    }
  }

  toggle = () => {
    this.collapsed = !this.collapsed
  }

  closeCollapsed = () => {
    this.collapsed = true
  }

  /**
   * 跳转到登录页面
   */
  toLogin = msg => {
    removeAuth()
    message.info(msg, () => {
      this.props.history.push('/login')
    })
  }

  logout = async () => {
    const { homeLogoutStore } = this.props
    const res = await homeLogoutStore!.logout()
    if (res && res.code === 0) {
      this.toLogin(res.msg)
    }
  }
  /**
   * 获取分站列表
   */
  getSiteList = () => {
    const { getSiteListEntity } = this.props.homeGetSiteListStore!
    if (getSiteListEntity) {
      let initName: Element | any = <Spin size="small" />
      const menuList = getSiteListEntity.map(item => {
        if (item.siteNo !== this.user.siteNo) {
          return <Menu.Item key={item.siteNo}>{item.siteName}</Menu.Item>
        } else {
          initName = item.siteName
          return null
        }
      })

      return (
        <Dropdown overlay={<Menu onClick={this.changeSite}>{menuList}</Menu>}>
          <Button>
            {initName}
            <DownOutlined />
          </Button>
        </Dropdown>
      )
    } else {
      return null
    }
  }

  /**
   * 切换分站
   */
  changeSite = async ({ key }) => {
    const { homeChangeSiteStore } = this.props
    await homeChangeSiteStore!.changeSite({ siteNo: key })
    saveAuth({ ...this.user, siteNo: key })
    window.location.reload()
  }

  getVenderList = venders => {
    let initName = <Spin size="small" />
    const menuList =
      venders &&
      venders.map((item, index) => {
        if (item.venderName !== this.user.venderName) {
          return (
            <Menu.Item key={index}>
              <span
                onClick={() => {
                  this.changeVender(item.venderName)
                }}
              >
                {item.nickName}
              </span>
            </Menu.Item>
          )
        } else {
          initName = item.nickName
          return null
        }
      })
    return (
      <Dropdown overlay={<Menu>{menuList}</Menu>}>
        <Button>
          {initName}
          <DownOutlined />
        </Button>
      </Dropdown>
    )
  }

  /**
   * 切换厂商
   */
  async changeVender(venderName) {
    const res = await this.props.homeChangeVendersStore!.changeVenders({
      venderName
    })

    if (res && res.code === 0) {
      saveAuth({
        ...this.user,
        venderName,
        siteNo: res.body
      })
      window.location.reload()
    }
  }

  getKey(path, type = 'key') {
    let keys
    let key
    if (type === 'routeKey') {
      keys = path.endsWith('/')
        ? path.slice(0, path.length - 1).split('/')
        : path.split('/')

      key = keys[keys.length - 1]
    } else {
      keys = path.split('/')
      key = keys[2]
    }

    if (!key || key === 'app') {
      key = 'home'
    }

    return key.toUpperCase()
  }

  render() {
    const { isMobile, homeGetMenuStore, venderListStore } = this.props
    const { loading, getMenuEntity } = homeGetMenuStore!
    const { getVenderListEntity } = venderListStore!
    if (!isAuthorized()) {
      return <Redirect to="/login" />
    }
    if (!this.user || loading) {
      return (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Spin />
        </div>
      )
    }

    const venders = getVenderListEntity

    return (
      <Layout>
        <SiderMenu
          collapsed={this.collapsed}
          data={getMenuEntity}
          onCollapse={this.closeCollapsed}
          isMobile={isMobile}
        />
        <Layout>
          <Header className={styles.header}>
            <Row align="middle" justify="space-between">
              <Col>
                <div onClick={() => this.toggle()} className={styles.trigger}>
                  {this.collapsed ? (
                    <MenuUnfoldOutlined />
                  ) : (
                    <MenuFoldOutlined />
                  )}
                </div>
              </Col>
              <Col style={{ width: 'calc(100% - 624px)' }}>
                <Row justify="start" align="middle" gutter={[8, 0]}>
                  <Col>
                    <FormattedMessage id="noticeText" defaultMessage="公告：" />
                  </Col>
                  <Col style={{ width: 'calc(100% - 80px)' }}>
                    <TopNotice />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row gutter={[8, 0]} justify="end" align="middle">
                  <Col>
                    <FormattedMessage id="welcome" defaultMessage="欢迎您" />
                    &nbsp;&nbsp;{this.user.nickName}
                  </Col>
                  <Col>
                    {/* 1--系统管理员 2-- 厂商管理员  3--分站管理员  */}
                    {this.user.userType === 1
                      ? this.getVenderList(venders)
                      : ''}
                  </Col>
                  <Col>
                    {this.user.userType !== 3 ? this.getSiteList() : ''}
                  </Col>
                  <Col>
                    <Button
                      icon={<PoweroffOutlined />}
                      size="middle"
                      onClick={this.logout}
                    >
                      <span>
                        <FormattedMessage id="exit" defaultMessage="退出" />
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>
          <RouteContent menu={getMenuEntity} />
          <Footer style={{ textAlign: 'center' }}>
            <FormattedMessage id="title" defaultMessage="泰国彩后台" /> ©
            {dayjs().year()}
          </Footer>
        </Layout>
      </Layout>
    )
  }
}

export default App

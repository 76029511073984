/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 状态修改
 */
export interface NoticeStateEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 状态修改
 */
export interface NoticeStateParams {
  id: number
  state: boolean
}

/**
 * 状态修改
 */
export const noticeState = (params: NoticeStateParams) => {
  return client.get<IClientResp<NoticeStateEntity>>('/notice/state', { params })
}

/**
 * - **通过** `Inject('noticeStateStore')` 依赖注入到组件
 * - **状态修改** NoticeStateStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			noticeStateStore: NoticeStateStore
		}
		@Inject('noticeStateStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			noticeStateStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class NoticeStateStore extends AsyncStore {
  @observable stateEntity?: NoticeStateEntity = undefined
  @action
  async state(params: NoticeStateParams) {
    return this.asyncAction<NoticeStateEntity>(
      'stateEntity',
      noticeState(params)
    )
  }
}

export const noticeStateStore = new NoticeStateStore()

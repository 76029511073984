/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取操作指令分页数据【操作指令管理】
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 分类名称(分类名称长度不能超过30个字符)
   */
  className: string

  /**
   * 命令名称(格式：[分类名称_命令名称])
   */
  keyName: string

  /**
   * 登录用户类型(0--系统管理员 1--厂商管理账号 2-- 子厂商管理账号  3--分站账号)
   */
  userType: string

  /**
   * 备注
   */
  remark: string

  /**
   * 操作命令名称(简体)
   */
  commandName_zh_cn: string

  /**
   * 操作命令名称(繁体)
   */
  commandName_zh_tw: string

  /**
   * 操作命令名称(英文)
   */
  commandName_en: string

  /**
   * 操作命令名称(越南文)
   */
  commandName_vn: string

  /**
   * 操作命令名称(泰文)
   */
  commandName_th: string
}
export interface SysGetOperateCommandListEntity {
  recondCount: number
  data: data[]
}

/**
 * 获取操作指令分页数据【操作指令管理】
 */
export interface SysGetOperateCommandListParams {
  className?: string
  commandName?: string
  userType?: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 获取操作指令分页数据【操作指令管理】
 */
export const sysGetOperateCommandList = (
  params: SysGetOperateCommandListParams
) => {
  return client.get<IClientResp<SysGetOperateCommandListEntity>>(
    '/Sys/GetOperateCommandList',
    { params }
  )
}

/**
 * - **通过** `Inject('sysGetOperateCommandListStore')` 依赖注入到组件
 * - **获取操作指令分页数据【操作指令管理】** SysGetOperateCommandListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysGetOperateCommandListStore: SysGetOperateCommandListStore
		}
		@Inject('sysGetOperateCommandListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysGetOperateCommandListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysGetOperateCommandListStore extends AsyncStore {
  @observable
  getOperateCommandListEntity?: SysGetOperateCommandListEntity = undefined
  @action
  async getOperateCommandList(params: SysGetOperateCommandListParams) {
    return this.asyncAction<SysGetOperateCommandListEntity>(
      'getOperateCommandListEntity',
      sysGetOperateCommandList(params)
    )
  }
}

export const sysGetOperateCommandListStore = new SysGetOperateCommandListStore()

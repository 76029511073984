/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取菜单权限组【RY编辑菜单权限】
 */
export interface menuPermissionExtension {
  /**
   * 一级菜单ID
   */
  id: number

  /**
   * 是否被选中
   */
  isSelected: boolean

  /**
   * 菜单名称
   */
  menuName_zh_cn: string
  menuName_zh_tw: string
  menuName_en: string
  menuName_th: string
  menuName_vn: string
  menuName: string

  /**
   * 是否需要配置权限
   */
  hasSetting: boolean
}
export interface MenuMenuActionConfigEntityItem {
  /**
   * 一级菜单ID
   */
  id: number

  /**
   * 是否被选中
   */
  isSelected: boolean

  /**
   * 菜单名称
   */
  menuName_zh_cn: string
  menuName_zh_tw: string
  menuName_en: string
  menuName_th: string
  menuName_vn: string
  menuName: string

  /**
   * 是否需要配置权限
   */
  hasSetting: boolean
  menuPermissionExtension: menuPermissionExtension[]
}
export type MenuMenuActionConfigEntity = MenuMenuActionConfigEntityItem[]

/**
 * 获取菜单权限组【RY编辑菜单权限】
 */
export interface MenuMenuActionConfigParams {
  menuActionConfigID: number
  venderName?: string
  siteNo?: string
  LanguageNo?: string
}

/**
 * 获取菜单权限组【RY编辑菜单权限】
 */
export const menuMenuActionConfig = (params: MenuMenuActionConfigParams) => {
  return client.get<IClientResp<MenuMenuActionConfigEntity>>(
    '/Menu/MenuActionConfig',
    { params }
  )
}

/**
 * - **通过** `Inject('menuMenuActionConfigStore')` 依赖注入到组件
 * - **获取菜单权限组【RY编辑菜单权限】** MenuMenuActionConfigStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuMenuActionConfigStore: MenuMenuActionConfigStore
		}
		@Inject('menuMenuActionConfigStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuMenuActionConfigStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuMenuActionConfigStore extends AsyncStore {
  @observable menuActionConfigEntity?: MenuMenuActionConfigEntity = undefined
  @action
  async menuActionConfig(params: MenuMenuActionConfigParams) {
    return this.asyncAction<MenuMenuActionConfigEntity>(
      'menuActionConfigEntity',
      menuMenuActionConfig(params)
    )
  }
}

export const menuMenuActionConfigStore = new MenuMenuActionConfigStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取开盘/封盘数量【一键启停管理】
 */
/**
 * 获取开盘/封盘数量
 */
export interface LottosettingGetPanCountEntity {
  /**
   * 开盘
   */
  open: number

  /**
   * 封盘
   */
  close: number
}

/**
 * 获取开盘/封盘数量【一键启停管理】
 */
export interface LottosettingGetPanCountParams {
  /**
   * 彩种ID
   */
  lottoID: number
}

/**
 * 获取开盘/封盘数量【一键启停管理】
 */
export const lottosettingGetPanCount = (
  params: LottosettingGetPanCountParams
) => {
  return client.get<IClientResp<LottosettingGetPanCountEntity>>(
    '/lottosetting/getPanCount',
    { params }
  )
}

/**
 * - **通过** `Inject('lottosettingGetPanCountStore')` 依赖注入到组件
 * - **获取开盘/封盘数量【一键启停管理】** LottosettingGetPanCountStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingGetPanCountStore: LottosettingGetPanCountStore
		}
		@Inject('lottosettingGetPanCountStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingGetPanCountStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingGetPanCountStore extends AsyncStore {
  @observable getPanCountEntity?: LottosettingGetPanCountEntity = undefined
  @action
  async getPanCount(params: LottosettingGetPanCountParams) {
    return this.asyncAction<LottosettingGetPanCountEntity>(
      'getPanCountEntity',
      lottosettingGetPanCount(params)
    )
  }
}

export const lottosettingGetPanCountStore = new LottosettingGetPanCountStore()

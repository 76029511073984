import client from './client'
const tglottoAdminAuth = 'TGLOTTO_ADMIN_AUTH'
/**
 * 获取认证
 */
export const getAuth = () => {
  const user = localStorage.getItem(tglottoAdminAuth)
  if (user) {
    return JSON.parse(user)
  }
  return null
}
/**
 * 设置请求头
 */
export const setHeader = (token: string) =>
  (client.defaults.headers.common['X-Access-Token'] = token)

/**
 * 是否认证
 */
export const isAuthorized = () => {
  const user = getAuth()
  if (user) {
    setHeader(user.token)
    return true
  }
  return false
}

/**
 * 设置认证
 * @param user 用户信息
 */
export const saveAuth = (user: any) => {
  localStorage.setItem(tglottoAdminAuth, JSON.stringify(user))
  setHeader(user.token)
}

/**
 * 清理认证
 */
export const removeAuth = () => localStorage.removeItem(tglottoAdminAuth)

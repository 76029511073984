/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 登录验证
 */
export interface HomeLoginEntity {
  /**
   * 厂商名
   */
  venderName: string

  /**
   * 分站编码
   */
  siteNo: string

  /**
   * 账户类型
   */
  userType: 1 | 2 | 3

  /**
   * Token
   */
  token: string

  /**
   * 帐号ID
   */
  id: number

  /**
   * 用户帐号
   */
  userName: string

  /**
   * 昵称
   */
  nickName: string

  /**
   * 头像
   */
  headImg: string

  /**
   * 报表时间
   */
  reportTime: string
}

/**
 * 登录验证
 */
/**
 * code=0 登录成功，code=1帐号密码错误，code=2 登录失败, code=3 帐号停用 ，code=4 IP验证不通过
 */
export interface HomeLoginParams {
  /**
   * 登录帐号
   */
  userName: string

  /**
   * 登录密码
   */
  pwd: string

  /**
   * 语言选择
   */
  langNo?: string
}

/**
 * 登录验证
 */
export const homeLogin = (params: HomeLoginParams) => {
  return client.post<IClientResp<HomeLoginEntity>>('/Home/Login', params)
}

/**
 * - **通过** `Inject('homeLoginStore')` 依赖注入到组件
 * - **登录验证** HomeLoginStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			homeLoginStore: HomeLoginStore
		}
		@Inject('homeLoginStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			homeLoginStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class HomeLoginStore extends AsyncStore {
  @observable loginEntity?: HomeLoginEntity = undefined
  @action
  async login(params: HomeLoginParams) {
    return this.asyncAction<HomeLoginEntity>('loginEntity', homeLogin(params))
  }
}

export const homeLoginStore = new HomeLoginStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取厂商数据列表【厂商管理】
 */
export interface data {
  /**
   * 厂商名称
   */
  venderName: string

  /**
   * 厂商编码
   */
  venderNo: string

  /**
   * 厂商别称
   */
  nickName: string

  /**
   * URL
   */
  venderAPIUrl: string

  /**
   * URL
   */
  mode: string
}
export interface VenderGetVendersListEntity {
  data: data[]
}

/**
 * 获取厂商数据列表【厂商管理】
 */
export const venderGetVendersList = () => {
  return client.get<IClientResp<VenderGetVendersListEntity>>(
    '/Vender/GetVendersList'
  )
}

/**
 * - **通过** `Inject('venderGetVendersListStore')` 依赖注入到组件
 * - **获取厂商数据列表【厂商管理】** VenderGetVendersListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			venderGetVendersListStore: VenderGetVendersListStore
		}
		@Inject('venderGetVendersListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			venderGetVendersListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class VenderGetVendersListStore extends AsyncStore {
  @observable getVendersListEntity?: VenderGetVendersListEntity = undefined
  @action
  async getVendersList() {
    return this.asyncAction<VenderGetVendersListEntity>(
      'getVendersListEntity',
      venderGetVendersList()
    )
  }
}

export const venderGetVendersListStore = new VenderGetVendersListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取分站列表【分站管理】
 */
export interface data {
  /**
   * 厂商名称(不可以为空)
   */
  venderName: string

  /**
   * 分站编码(不可以为空)
   */
  siteNo: string

  /**
   * 分站昵称(不可以为空)
   */
  siteName: string
}
export interface VenderGetSitesListEntity {
  data: data[]
}

/**
 * 获取分站列表【分站管理】
 */
export const venderGetSitesList = () => {
  return client.get<IClientResp<VenderGetSitesListEntity>>(
    '/Vender/GetSitesList'
  )
}

/**
 * - **通过** `Inject('venderGetSitesListStore')` 依赖注入到组件
 * - **获取分站列表【分站管理】** VenderGetSitesListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			venderGetSitesListStore: VenderGetSitesListStore
		}
		@Inject('venderGetSitesListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			venderGetSitesListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class VenderGetSitesListStore extends AsyncStore {
  @observable getSitesListEntity?: VenderGetSitesListEntity = undefined
  @action
  async getSitesList() {
    return this.asyncAction<VenderGetSitesListEntity>(
      'getSitesListEntity',
      venderGetSitesList()
    )
  }
}

export const venderGetSitesListStore = new VenderGetSitesListStore()

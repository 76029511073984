/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 添加系统管理员【系统用户管理】
 */
export interface SysAddCtrlUserEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 添加系统管理员【系统用户管理】
 */
/**
 * 返回值 code=0 执行成功 ， code = 1 执行失败 ，code = 2 帐号已存在，&gt;=10000 其他系统错误
 */
export interface SysAddCtrlUserParams {
  /**
   * 登录账号
   */
  ctrlUserName: string

  /**
   * 昵称
   */
  nickName: string

  /**
   * 登录密码
   */
  pwd: string

  /**
   * 状态(0-正常 1-停用)
   */
  state: number

  /**
   * 所属用户组(多个使用[,]分隔)
   */
  userGroupIDList: string
}

/**
 * 添加系统管理员【系统用户管理】
 */
export const sysAddCtrlUser = (params: SysAddCtrlUserParams) => {
  return client.post<IClientResp<SysAddCtrlUserEntity>>(
    '/Sys/AddCtrlUser',
    params
  )
}

/**
 * - **通过** `Inject('sysAddCtrlUserStore')` 依赖注入到组件
 * - **添加系统管理员【系统用户管理】** SysAddCtrlUserStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysAddCtrlUserStore: SysAddCtrlUserStore
		}
		@Inject('sysAddCtrlUserStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysAddCtrlUserStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysAddCtrlUserStore extends AsyncStore {
  @observable addCtrlUserEntity?: SysAddCtrlUserEntity = undefined
  @action
  async addCtrlUser(params: SysAddCtrlUserParams) {
    return this.asyncAction<SysAddCtrlUserEntity>(
      'addCtrlUserEntity',
      sysAddCtrlUser(params)
    )
  }
}

export const sysAddCtrlUserStore = new SysAddCtrlUserStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取系统限额配置--felix
 */
export interface LottosettingSystemlimitEntityItem {
  id: number

  /**
   * 彩种编号
   */
  lottoID: number
  currencyNo: string

  /**
   * 彩票玩法1级分组ID
   */
  playTypeID: number

  /**
   * 最小额度
   */
  betMinMoney: number

  /**
   * 单注上限
   */
  betMaxMoney: number

  /**
   * 单项限额
   */
  itemLimitMoney: number

  /**
   * 最高赔率
   */
  maxLine: number

  /**
   * 退水 0-99 百分比单位
   */
  backMoneyPercent: number
}
export type LottosettingSystemlimitEntity = LottosettingSystemlimitEntityItem[]

/**
 * 获取系统限额配置--felix
 */
export interface LottosettingSystemlimitParams {
  /**
   * 彩种ID
   */
  lottoId: number
}

/**
 * 获取系统限额配置--felix
 */
export const lottosettingSystemlimit = (
  params: LottosettingSystemlimitParams
) => {
  return client.get<IClientResp<LottosettingSystemlimitEntity>>(
    '/lottosetting/systemlimit',
    { params }
  )
}

/**
 * - **通过** `Inject('lottosettingSystemlimitStore')` 依赖注入到组件
 * - **获取系统限额配置--felix** LottosettingSystemlimitStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingSystemlimitStore: LottosettingSystemlimitStore
		}
		@Inject('lottosettingSystemlimitStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingSystemlimitStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingSystemlimitStore extends AsyncStore {
  @observable systemlimitEntity?: LottosettingSystemlimitEntity = undefined
  @action
  async systemlimit(params: LottosettingSystemlimitParams) {
    return this.asyncAction<LottosettingSystemlimitEntity>(
      'systemlimitEntity',
      lottosettingSystemlimit(params)
    )
  }
}

export const lottosettingSystemlimitStore = new LottosettingSystemlimitStore()

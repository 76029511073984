/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 控制器列表【控制器管理】
 */
export interface data {
  /**
   * 控制器名称
   */
  controllerName: string

  /**
   * 控制器的别称
   */
  anotherName: string
}
export interface MenuGetControllersListEntity {
  recondCount: number
  data: data[]
}

/**
 * 控制器列表【控制器管理】
 */
export interface MenuGetControllersListParams {
  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 控制器列表【控制器管理】
 */
export const menuGetControllersList = (
  params: MenuGetControllersListParams
) => {
  return client.get<IClientResp<MenuGetControllersListEntity>>(
    '/Menu/GetControllersList',
    { params }
  )
}

/**
 * - **通过** `Inject('menuGetControllersListStore')` 依赖注入到组件
 * - **控制器列表【控制器管理】** MenuGetControllersListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetControllersListStore: MenuGetControllersListStore
		}
		@Inject('menuGetControllersListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetControllersListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetControllersListStore extends AsyncStore {
  @observable
  getControllersListEntity?: MenuGetControllersListEntity = undefined
  @action
  async getControllersList(params: MenuGetControllersListParams) {
    return this.asyncAction<MenuGetControllersListEntity>(
      'getControllersListEntity',
      menuGetControllersList(params)
    )
  }
}

export const menuGetControllersListStore = new MenuGetControllersListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取分站指定时间段的开始时间和结束时间[用于账务报表时间快捷选]
 */
export interface ReportsGetLottoNoEntityItem {
  /**
   * 期号
   */
  lottoNo: string
}
export type ReportsGetLottoNoEntity = ReportsGetLottoNoEntityItem[]

/**
 * 获取分站指定时间段的开始时间和结束时间[用于账务报表时间快捷选]
 */
export interface ReportsGetLottoNoParams {
  lottoId: number
}

/**
 * 获取分站指定时间段的开始时间和结束时间[用于账务报表时间快捷选]
 */
export const reportsGetLottoNo = (params: ReportsGetLottoNoParams) => {
  return client.get<IClientResp<ReportsGetLottoNoEntity>>(
    '/Reports/GetLottoNo',
    { params }
  )
}

/**
 * - **通过** `Inject('reportsGetLottoNoStore')` 依赖注入到组件
 * - **获取分站指定时间段的开始时间和结束时间[用于账务报表时间快捷选]** ReportsGetLottoNoStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			reportsGetLottoNoStore: ReportsGetLottoNoStore
		}
		@Inject('reportsGetLottoNoStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			reportsGetLottoNoStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ReportsGetLottoNoStore extends AsyncStore {
  @observable getLottoNoEntity?: ReportsGetLottoNoEntity = undefined
  @action
  async getLottoNo(params: ReportsGetLottoNoParams) {
    return this.asyncAction<ReportsGetLottoNoEntity>(
      'getLottoNoEntity',
      reportsGetLottoNo(params)
    )
  }
}

export const reportsGetLottoNoStore = new ReportsGetLottoNoStore()

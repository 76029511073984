export default {
  'sidermenu.title': '泰国彩后台',
  'app.loginInvalid': '登录失效，请重新登录',
  'app.logout': '登出成功',
  'app.welcome': '欢迎您',
  'app.exit': 'quit',
  'app.title': '泰国彩后台',
  'login.title': '泰国菜后台',
  'login.plsInputUserName': '请填写用户名',
  'login.plsInputPwd': '请填写密码',
  'login.atLeastEight': '最少八位数',
  'login.login': 'login',
  'common.search': 'search',
  'common.see': 'see'
}

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 新增菜单【菜单管理】
 */
export interface MenuAddMenusEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 新增菜单【菜单管理】
 */
/**
* 新增菜单<br />
            返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
*/
export interface MenuAddMenusParams {
  /**
   * 菜单层级(1--表示一级菜单)
   */
  menuLayer?: number

  /**
   * 父级菜单ID
   */
  pid?: number

  /**
   * 排序码
   */
  orderNo: number

  /**
   * 菜单对应的网址
   */
  menuUrl?: string

  /**
   * 标签页名称（用于固定在某一个标签页显示）
   */
  tabName?: string

  /**
   * 是否显示(0--不显示 1--显示)
   */
  display: boolean

  /**
   * 是否覆盖左边菜单栏(0--不覆盖 1--覆盖)
   */
  covered?: boolean

  /**
   * 页面呈现方式(0--当前窗口  1--新窗口)
   */
  pageDisplay?: number

  /**
   * 备注
   */
  remark?: string

  /**
   * 二级菜单小图标
   */
  icon?: string

  /**
   * 菜单项名称(简体)
   */
  menuName_zh_cn: string

  /**
   * 菜单项名称(繁体)
   */
  menuName_zh_tw?: string

  /**
   * 菜单项名称(英文)
   */
  menuName_en?: string

  /**
   * 菜单项名称(越南文)
   */
  menuName_vn?: string

  /**
   * 菜单项名称(泰文)
   */
  menuName_th?: string
}

/**
 * 新增菜单【菜单管理】
 */
export const menuAddMenus = (params: MenuAddMenusParams) => {
  return client.post<IClientResp<MenuAddMenusEntity>>('/Menu/AddMenus', params)
}

/**
 * - **通过** `Inject('menuAddMenusStore')` 依赖注入到组件
 * - **新增菜单【菜单管理】** MenuAddMenusStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuAddMenusStore: MenuAddMenusStore
		}
		@Inject('menuAddMenusStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuAddMenusStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuAddMenusStore extends AsyncStore {
  @observable addMenusEntity?: MenuAddMenusEntity = undefined
  @action
  async addMenus(params: MenuAddMenusParams) {
    return this.asyncAction<MenuAddMenusEntity>(
      'addMenusEntity',
      menuAddMenus(params)
    )
  }
}

export const menuAddMenusStore = new MenuAddMenusStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改开封盘【彩种启停】
 */
/**
 * 修改开封盘
 */
export interface LottosettingUpdatePanStateEntity {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 即时盘口状态(0--封盘  1--开盘)
   */
  panState: boolean
}

/**
 * 修改开封盘【彩种启停】
 */
export interface LottosettingUpdatePanStateParams {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 即时盘口状态(0--封盘  1--开盘)
   */
  panState: boolean
}

/**
 * 修改开封盘【彩种启停】
 */
export const lottosettingUpdatePanState = (
  params: LottosettingUpdatePanStateParams
) => {
  return client.post<IClientResp<LottosettingUpdatePanStateEntity>>(
    '/lottosetting/updatePanState',
    params
  )
}

/**
 * - **通过** `Inject('lottosettingUpdatePanStateStore')` 依赖注入到组件
 * - **修改开封盘【彩种启停】** LottosettingUpdatePanStateStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingUpdatePanStateStore: LottosettingUpdatePanStateStore
		}
		@Inject('lottosettingUpdatePanStateStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingUpdatePanStateStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingUpdatePanStateStore extends AsyncStore {
  @observable
  updatePanStateEntity?: LottosettingUpdatePanStateEntity = undefined
  @action
  async updatePanState(params: LottosettingUpdatePanStateParams) {
    return this.asyncAction<LottosettingUpdatePanStateEntity>(
      'updatePanStateEntity',
      lottosettingUpdatePanState(params)
    )
  }
}

export const lottosettingUpdatePanStateStore = new LottosettingUpdatePanStateStore()

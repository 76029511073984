/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取分站限额配置--felix
 */
export interface LottosettingSitelimitEntityItem {
  /**
   * 最小额度 对应的系统配置限制
   */
  betMinMoneySystemLimit: number

  /**
   * 单注上限 对应的系统配置限制
   */
  betMaxMoneySystemLimit: number

  /**
   * 单项限额 对应的系统配置限制
   */
  itemLimitMoneySystemLimit: number

  /**
   * 最高赔率 对应的系统配置限制
   */
  maxLineSystemLimit: number

  /**
   * 退水 0-99 百分比单位 对应的系统配置限制
   */
  backMoneyPercentSystemLimit: number

  /**
   * 玩法名称
   */
  playTypeName: string
  id: number

  /**
   * 厂商名称
   */
  venderName: string

  /**
   * 分站编码
   */
  siteNo: string

  /**
   * 彩种编号
   */
  lottoID: number
  currencyNo: string

  /**
   * 彩票玩法1级分组ID
   */
  lottoPlayTypeID: number

  /**
   * 最小额度
   */
  betMinMoney: number

  /**
   * 单注上限
   */
  betMaxMoney: number

  /**
   * 单项限额
   */
  itemLimitMoney: number

  /**
   * 最高赔率
   */
  maxLine: number

  /**
   * 退水 0-99 百分比单位
   */
  backMoneyPercent: number
}
export type LottosettingSitelimitEntity = LottosettingSitelimitEntityItem[]

/**
 * 获取分站限额配置--felix
 */
export interface LottosettingSitelimitParams {
  /**
   * 彩种ID
   */
  lottoId: number
}

/**
 * 获取分站限额配置--felix
 */
export const lottosettingSitelimit = (params: LottosettingSitelimitParams) => {
  return client.get<IClientResp<LottosettingSitelimitEntity>>(
    '/lottosetting/sitelimit',
    { params }
  )
}

/**
 * - **通过** `Inject('lottosettingSitelimitStore')` 依赖注入到组件
 * - **获取分站限额配置--felix** LottosettingSitelimitStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingSitelimitStore: LottosettingSitelimitStore
		}
		@Inject('lottosettingSitelimitStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingSitelimitStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingSitelimitStore extends AsyncStore {
  @observable sitelimitEntity?: LottosettingSitelimitEntity = undefined
  @action
  async sitelimit(params: LottosettingSitelimitParams) {
    return this.asyncAction<LottosettingSitelimitEntity>(
      'sitelimitEntity',
      lottosettingSitelimit(params)
    )
  }
}

export const lottosettingSitelimitStore = new LottosettingSitelimitStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 根据子菜单ID获取对应的权限组，权限项【权限模版管理】
 */
export interface actionList {
  /**
   * 节点编号
   */
  id: string

  /**
   * 父节点编号
   */
  pid: string

  /**
   * 节点名称
   */
  name_zh_cn: string
  name_zh_tw: string
  name_en: string
  name_vn: string
  name_th: string
  name: string

  /**
   * 是否勾选状态
   */
  check: boolean

  /**
   * 是否打开状态
   */
  open: boolean

  /**
   * 备注
   */
  remak: string

  /**
   * 是否不允许操作
   */
  disabled: boolean
}
export interface MenuSelectBySubMenuIdActionListEntityItem {
  id: number
  actionGroupName_zh_cn: string
  actionGroupName_zh_tw: string
  actionGroupName_en: string
  actionGroupName_th: string
  actionGroupName_vn: string
  actionGroupName: string
  isSelected: boolean
  actionList: actionList[]
}
export type MenuSelectBySubMenuIdActionListEntity = MenuSelectBySubMenuIdActionListEntityItem[]

/**
 * 根据子菜单ID获取对应的权限组，权限项【权限模版管理】
 */
export interface MenuSelectBySubMenuIdActionListParams {
  /**
   * 子菜单id
   */
  subMenuID: number

  /**
   * 权限模版id
   */
  menuTemplatesID: number
}

/**
 * 根据子菜单ID获取对应的权限组，权限项【权限模版管理】
 */
export const menuSelectBySubMenuIdActionList = (
  params: MenuSelectBySubMenuIdActionListParams
) => {
  return client.get<IClientResp<MenuSelectBySubMenuIdActionListEntity>>(
    '/Menu/SelectBySubMenuIDActionList',
    { params }
  )
}

/**
 * - **通过** `Inject('menuSelectBySubMenuIdActionListStore')` 依赖注入到组件
 * - **根据子菜单ID获取对应的权限组，权限项【权限模版管理】** MenuSelectBySubMenuIdActionListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuSelectBySubMenuIdActionListStore: MenuSelectBySubMenuIdActionListStore
		}
		@Inject('menuSelectBySubMenuIdActionListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuSelectBySubMenuIdActionListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuSelectBySubMenuIdActionListStore extends AsyncStore {
  @observable
  selectBySubMenuIdActionListEntity?: MenuSelectBySubMenuIdActionListEntity = undefined
  @action
  async selectBySubMenuIdActionList(
    params: MenuSelectBySubMenuIdActionListParams
  ) {
    return this.asyncAction<MenuSelectBySubMenuIdActionListEntity>(
      'selectBySubMenuIdActionListEntity',
      menuSelectBySubMenuIdActionList(params)
    )
  }
}

export const menuSelectBySubMenuIdActionListStore = new MenuSelectBySubMenuIdActionListStore()

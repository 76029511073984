/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 删除操作指令分类【操作指令分类管理】
 */
export interface SysDeleteOperateCommandClassEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 删除操作指令分类【操作指令分类管理】
 */
export interface SysDeleteOperateCommandClassParams {
  /**
   * 分类名称
   */
  className: string
}

/**
 * 删除操作指令分类【操作指令分类管理】
 */
export const sysDeleteOperateCommandClass = (
  params: SysDeleteOperateCommandClassParams
) => {
  return client.post<IClientResp<SysDeleteOperateCommandClassEntity>>(
    '/Sys/DeleteOperateCommandClass',
    params
  )
}

/**
 * - **通过** `Inject('sysDeleteOperateCommandClassStore')` 依赖注入到组件
 * - **删除操作指令分类【操作指令分类管理】** SysDeleteOperateCommandClassStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysDeleteOperateCommandClassStore: SysDeleteOperateCommandClassStore
		}
		@Inject('sysDeleteOperateCommandClassStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysDeleteOperateCommandClassStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysDeleteOperateCommandClassStore extends AsyncStore {
  @observable
  deleteOperateCommandClassEntity?: SysDeleteOperateCommandClassEntity = undefined
  @action
  async deleteOperateCommandClass(params: SysDeleteOperateCommandClassParams) {
    return this.asyncAction<SysDeleteOperateCommandClassEntity>(
      'deleteOperateCommandClassEntity',
      sysDeleteOperateCommandClass(params)
    )
  }
}

export const sysDeleteOperateCommandClassStore = new SysDeleteOperateCommandClassStore()

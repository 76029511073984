/**
 * Config SetGroup
 */
import { message, Modal, Spin, Tree } from 'antd'
import { lang } from 'lang'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { MenuMenuActionConfigEntityItem } from 'services/menu/menu_action_config'
import { MenuModifyActionStore } from 'services/menu/modify_action'
import { MenuRyActionsConfigEntityItem } from 'services/menu/ry_actions_config'
import { Inject } from 'store'
const TreeNode = Tree.TreeNode

declare interface SetGroupOwnProps {
  menuModifyActionStore?: MenuModifyActionStore
  visible: boolean
  menuData: MenuMenuActionConfigEntityItem
  onClose: () => void
  group?: MenuRyActionsConfigEntityItem[]
  loading: boolean
  restParams: any
}

declare interface SetGroupState {}

@Inject(s => ({
  menuModifyActionStore: s.menuModifyActionStore
}))
@observer
class SetGroup extends React.Component<SetGroupOwnProps, SetGroupState> {
  @observable selected: string[] = []

  componentDidUpdate(prevProps: SetGroupOwnProps) {
    if (this.props.group && this.props.group !== prevProps.group) {
      this.getCheckedKeys(this.props.group)
    }
  }

  handleOk = async () => {
    const { menuModifyActionStore, onClose, restParams } = this.props
    const groupIds = this.selected.filter(item => !isNaN(parseInt(item, 10)))
    const res = await menuModifyActionStore!.modifyAction({
      ...restParams,
      actionID: groupIds.join(','),
      allActionID: this.getAll()
    })
    if (res) {
      message.info(res.msg)
    }
    onClose()
  }

  getAll = () => {
    const all: any = []
    this.props.group!.forEach(item => {
      if (item.actionList) {
        item.actionList.forEach(i => {
          all.push(i.id)
        })
      }
    })
    return all.join(',')
  }

  onCheck = (checkedKeys, info) => {
    this.selected = checkedKeys
  }

  /**
   * 根据数据输出所有TreeNode
   *
   * @memberof SetGroup
   */
  loop = group => {
    return group.map(item => {
      if (item.actionList) {
        return (
          <TreeNode
            active
            key={item.actionGroupName}
            title={item.actionGroupName}
          >
            {this.loop(item.actionList)}
          </TreeNode>
        )
      }
      return <TreeNode active key={item.id.toString()} title={item.name} />
    })
  }

  /**
   * 获取权限组信息 用于处理已选中
   *
   * @memberof SetGroup
   */
  getCheckedKeys = (keys: MenuRyActionsConfigEntityItem[]) => {
    const selected: any[] = []
    keys.forEach(item => {
      if (item.actionList) {
        return item.actionList.forEach(i => {
          if (i.check) {
            selected.push(i.id.toString())
          }
        })
      }
    })
    this.selected = selected
  }

  render() {
    const {
      visible,
      menuData,
      group,
      menuModifyActionStore,
      onClose,
      loading
    } = this.props
    if (menuData) {
      return (
        <Modal
          title={`${menuData.menuName}${lang.messages.configPermissionEntry}`}
          visible={visible}
          onOk={this.handleOk}
          onCancel={() => {
            this.selected = []
            onClose()
          }}
          confirmLoading={menuModifyActionStore!.loading}
          maskClosable={false}
        >
          {group && !loading ? (
            <Tree checkable onCheck={this.onCheck} checkedKeys={this.selected}>
              {this.loop(group)}
            </Tree>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}
        </Modal>
      )
    } else {
      return null
    }
  }
}

export default SetGroup

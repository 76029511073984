/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 删除权限项【权限项管理】
 */
export interface MenuDeleteActionEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 删除权限项【权限项管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface MenuDeleteActionParams {
  /**
   * ID
   */
  id: number
}

/**
 * 删除权限项【权限项管理】
 */
export const menuDeleteAction = (params: MenuDeleteActionParams) => {
  return client.post<IClientResp<MenuDeleteActionEntity>>(
    '/Menu/DeleteAction',
    params
  )
}

/**
 * - **通过** `Inject('menuDeleteActionStore')` 依赖注入到组件
 * - **删除权限项【权限项管理】** MenuDeleteActionStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuDeleteActionStore: MenuDeleteActionStore
		}
		@Inject('menuDeleteActionStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuDeleteActionStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuDeleteActionStore extends AsyncStore {
  @observable deleteActionEntity?: MenuDeleteActionEntity = undefined
  @action
  async deleteAction(params: MenuDeleteActionParams) {
    return this.asyncAction<MenuDeleteActionEntity>(
      'deleteActionEntity',
      menuDeleteAction(params)
    )
  }
}

export const menuDeleteActionStore = new MenuDeleteActionStore()

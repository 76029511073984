/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取一键启停管理列表【一键启停管理】
 */
export interface data {
  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 厂商名称
   */
  venderName: string

  /**
   * 分站编码
   */
  siteNo: string

  /**
   * 分站名称
   */
  siteName: string

  /**
   * 盘口状态(0-封盘 1-开盘) 人工停盘功能
   */
  panState: boolean
}
export interface LottosettingGetTgLottosManagerEntity {
  recondCount: number
  data: data[]
}

/**
 * 获取一键启停管理列表【一键启停管理】
 */
export interface LottosettingGetTgLottosManagerParams {
  /**
   * 彩种ID
   */
  LottoID: number

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 获取一键启停管理列表【一键启停管理】
 */
export const lottosettingGetTgLottosManager = (
  params: LottosettingGetTgLottosManagerParams
) => {
  return client.get<IClientResp<LottosettingGetTgLottosManagerEntity>>(
    '/lottosetting/getTGLottosManager',
    { params }
  )
}

/**
 * - **通过** `Inject('lottosettingGetTgLottosManagerStore')` 依赖注入到组件
 * - **获取一键启停管理列表【一键启停管理】** LottosettingGetTgLottosManagerStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingGetTgLottosManagerStore: LottosettingGetTgLottosManagerStore
		}
		@Inject('lottosettingGetTgLottosManagerStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingGetTgLottosManagerStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingGetTgLottosManagerStore extends AsyncStore {
  @observable
  getTgLottosManagerEntity?: LottosettingGetTgLottosManagerEntity = undefined
  @action
  async getTgLottosManager(params: LottosettingGetTgLottosManagerParams) {
    return this.asyncAction<LottosettingGetTgLottosManagerEntity>(
      'getTgLottosManagerEntity',
      lottosettingGetTgLottosManager(params)
    )
  }
}

export const lottosettingGetTgLottosManagerStore = new LottosettingGetTgLottosManagerStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取一级菜单子菜单【系统用户组管理】
 */
export interface menuPermissionExtension {
  /**
   * 一级菜单ID
   */
  id: number

  /**
   * 是否被选中
   */
  isSelected: boolean

  /**
   * 菜单名称
   */
  menuName_zh_cn: string
  menuName_zh_tw: string
  menuName_en: string
  menuName_th: string
  menuName_vn: string
  menuName: string

  /**
   * 是否需要配置权限
   */
  hasSetting: boolean
}
export interface SysUserGroupMenuConfigEntityItem {
  /**
   * 一级菜单ID
   */
  id: number

  /**
   * 是否被选中
   */
  isSelected: boolean

  /**
   * 菜单名称
   */
  menuName_zh_cn: string
  menuName_zh_tw: string
  menuName_en: string
  menuName_th: string
  menuName_vn: string
  menuName: string

  /**
   * 是否需要配置权限
   */
  hasSetting: boolean
  menuPermissionExtension: menuPermissionExtension[]
}
export type SysUserGroupMenuConfigEntity = SysUserGroupMenuConfigEntityItem[]

/**
 * 获取一级菜单子菜单【系统用户组管理】
 */
export interface SysUserGroupMenuConfigParams {
  /**
   * 用户组id
   */
  userGroupId: number
}

/**
 * 获取一级菜单子菜单【系统用户组管理】
 */
export const sysUserGroupMenuConfig = (
  params: SysUserGroupMenuConfigParams
) => {
  return client.get<IClientResp<SysUserGroupMenuConfigEntity>>(
    '/Sys/UserGroupMenuConfig',
    { params }
  )
}

/**
 * - **通过** `Inject('sysUserGroupMenuConfigStore')` 依赖注入到组件
 * - **获取一级菜单子菜单【系统用户组管理】** SysUserGroupMenuConfigStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysUserGroupMenuConfigStore: SysUserGroupMenuConfigStore
		}
		@Inject('sysUserGroupMenuConfigStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysUserGroupMenuConfigStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysUserGroupMenuConfigStore extends AsyncStore {
  @observable
  userGroupMenuConfigEntity?: SysUserGroupMenuConfigEntity = undefined
  @action
  async userGroupMenuConfig(params: SysUserGroupMenuConfigParams) {
    return this.asyncAction<SysUserGroupMenuConfigEntity>(
      'userGroupMenuConfigEntity',
      sysUserGroupMenuConfig(params)
    )
  }
}

export const sysUserGroupMenuConfigStore = new SysUserGroupMenuConfigStore()

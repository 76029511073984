/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取系统用户组【系统用户管理】
 */
export interface SysGetCtrlUserGroupsEntityItem {
  /**
   * ID
   */
  id: number

  /**
   * 用户组名称(简体)
   */
  userGroupName: string
}
export type SysGetCtrlUserGroupsEntity = SysGetCtrlUserGroupsEntityItem[]

/**
 * 获取系统用户组【系统用户管理】
 */
export const sysGetCtrlUserGroups = () => {
  return client.get<IClientResp<SysGetCtrlUserGroupsEntity>>(
    '/Sys/GetCtrlUserGroups'
  )
}

/**
 * - **通过** `Inject('sysGetCtrlUserGroupsStore')` 依赖注入到组件
 * - **获取系统用户组【系统用户管理】** SysGetCtrlUserGroupsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysGetCtrlUserGroupsStore: SysGetCtrlUserGroupsStore
		}
		@Inject('sysGetCtrlUserGroupsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysGetCtrlUserGroupsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysGetCtrlUserGroupsStore extends AsyncStore {
  @observable getCtrlUserGroupsEntity?: SysGetCtrlUserGroupsEntity = undefined
  @action
  async getCtrlUserGroups() {
    return this.asyncAction<SysGetCtrlUserGroupsEntity>(
      'getCtrlUserGroupsEntity',
      sysGetCtrlUserGroups()
    )
  }
}

export const sysGetCtrlUserGroupsStore = new SysGetCtrlUserGroupsStore()

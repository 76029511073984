/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取账务统计
 */
export interface data {
  /**
   * 分站编号
   */
  siteNo: string

  /**
   * 分组字段
   */
  groupByName: string

  /**
   * 玩法名
   */
  playTypeTextName: string

  /**
   * 会员帐号名称
   */
  memberName: string

  /**
   * 下注笔数
   */
  betNums: number

  /**
   * 下注金额
   */
  betMoney: number

  /**
   * 有效下注金额
   */
  validBetMoney: number

  /**
   * 下注总金额
   */
  totalBetMoney: number

  /**
   * 会员退水金额
   */
  backMoney: number

  /**
   * 输赢金额
   */
  winLoseMoney: number

  /**
   * 彩种ID
   */
  lottoId: number
}

/**
 * 后台返回给前端的报表结构
 */
export interface ReportsGetAccountEntity {
  recondCount: number
  data: data[]

  /**
   * 当前帐账务结构
   */
  countData: {
    /**
     * 分站编号
     */
    siteNo: string

    /**
     * 分组字段
     */
    groupByName: string

    /**
     * 玩法名
     */
    playTypeTextName: string

    /**
     * 会员帐号名称
     */
    memberName: string

    /**
     * 下注笔数
     */
    betNums: number

    /**
     * 下注金额
     */
    betMoney: number

    /**
     * 有效下注金额
     */
    validBetMoney: number

    /**
     * 下注总金额
     */
    totalBetMoney: number

    /**
     * 会员退水金额
     */
    backMoney: number

    /**
     * 输赢金额
     */
    winLoseMoney: number

    /**
     * 彩种ID
     */
    lottoId: number
  }
}

/**
 * 获取账务统计
 */
export interface ReportsGetAccountParams {
  /**
   * 查询类型[0:按照日期;1:按照期数]
   */
  TypeModel: number

  /**
   * 报表类型[0:交收报表;1:分类报表]
   */
  ReportType: number

  /**
   * 游戏ID
   */
  LottoID: number

  /**
   * 期数
   */
  LottoNo?: string

  /**
   * 玩法ID
   */
  PlayTypeID: number

  /**
   * 开始时间
   */
  StartDateTime?: string

  /**
   * 结束时间
   */
  EndDateTime?: string

  /**
   * 分组查询关键字[CurrencyNo:货币分组;LottoMemberID:会员分组;AccountDateTime:日期分组,LottoID:游戏分组,LottoNo:期号分组,PlayTypeID:玩法分类]
   */
  GroupByKey: string

  /**
   * 子系统会员编号
   */
  LottoMemberID?: number

  /**
   * 是否排除试玩
   */
  RemoveTest?: boolean

  /**
   * 排序关键字[0:按照分组关键字排序:,1:游戏输赢倒序,2:游戏输赢顺序,3:下注金额倒序,4:下注金额顺序]
   */
  OrderBy: number

  /**
   * 会员平台帐号名称
   */
  VenderMemberName?: string

  /**
   * 是否统计总计(0-不统计,1-统计)
   */
  IsCount: number

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 获取账务统计
 */
export const reportsGetAccount = (params: ReportsGetAccountParams) => {
  return client.get<IClientResp<ReportsGetAccountEntity>>(
    '/Reports/GetAccount',
    { params }
  )
}

/**
 * - **通过** `Inject('reportsGetAccountStore')` 依赖注入到组件
 * - **获取账务统计** ReportsGetAccountStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			reportsGetAccountStore: ReportsGetAccountStore
		}
		@Inject('reportsGetAccountStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			reportsGetAccountStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ReportsGetAccountStore extends AsyncStore {
  @observable getAccountEntity?: ReportsGetAccountEntity = undefined
  @action
  async getAccount(params: ReportsGetAccountParams) {
    return this.asyncAction<ReportsGetAccountEntity>(
      'getAccountEntity',
      reportsGetAccount(params)
    )
  }
}

export const reportsGetAccountStore = new ReportsGetAccountStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 多语言修改【多语言管理】
 */
export interface ConfigLanguageModifyEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 多语言修改【多语言管理】
 */
export interface ConfigLanguageModifyParams {
  /**
   * 类名/ViewName...
   */
  className: string

  /**
   * 属性名称
   */
  propertyName: string

  /**
   * 备注
   */
  remark: string

  /**
   * 内容(简体)
   */
  value_zh_cn: string

  /**
   * 内容(繁体)
   */
  value_zh_tw: string

  /**
   * 内容(英文)
   */
  value_en: string

  /**
   * 内容(越南菜)
   */
  value_vn: string

  /**
   * 内容(泰文)
   */
  value_th: string
}

/**
 * 多语言修改【多语言管理】
 */
export const configLanguageModify = (params: ConfigLanguageModifyParams) => {
  return client.post<IClientResp<ConfigLanguageModifyEntity>>(
    '/Config/LanguageModify',
    params
  )
}

/**
 * - **通过** `Inject('configLanguageModifyStore')` 依赖注入到组件
 * - **多语言修改【多语言管理】** ConfigLanguageModifyStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			configLanguageModifyStore: ConfigLanguageModifyStore
		}
		@Inject('configLanguageModifyStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			configLanguageModifyStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ConfigLanguageModifyStore extends AsyncStore {
  @observable languageModifyEntity?: ConfigLanguageModifyEntity = undefined
  @action
  async languageModify(params: ConfigLanguageModifyParams) {
    return this.asyncAction<ConfigLanguageModifyEntity>(
      'languageModifyEntity',
      configLanguageModify(params)
    )
  }
}

export const configLanguageModifyStore = new ConfigLanguageModifyStore()

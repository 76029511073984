/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 删除公告
 */
export interface NoticeDeleteEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 删除公告
 */
export interface NoticeDeleteParams {
  id: number
}

/**
 * 删除公告
 */
export const noticeDelete = (params: NoticeDeleteParams) => {
  return client.get<IClientResp<NoticeDeleteEntity>>('/notice/delete', {
    params
  })
}

/**
 * - **通过** `Inject('noticeDeleteStore')` 依赖注入到组件
 * - **删除公告** NoticeDeleteStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			noticeDeleteStore: NoticeDeleteStore
		}
		@Inject('noticeDeleteStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			noticeDeleteStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class NoticeDeleteStore extends AsyncStore {
  @observable deleteEntity?: NoticeDeleteEntity = undefined
  @action
  async delete(params: NoticeDeleteParams) {
    return this.asyncAction<NoticeDeleteEntity>(
      'deleteEntity',
      noticeDelete(params)
    )
  }
}

export const noticeDeleteStore = new NoticeDeleteStore()

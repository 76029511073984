export default {
  pwd: '密碼',
  setting: '設定',
  bet: '投注',
  profitLoss: '盈虧',
  numberOfPeople: '人數',
  todaySumarry: '今日統計',
  loginAccount: '登錄賬號',
  playTypeReport: '玩法報表',
  memberReport: '會員報表',
  dateReport: '日期報表',
  betDetail: '下注明細',
  editSuccess: '修改成功',
  saveSuccess: '保存成功',
  title: '泰國彩後臺',
  systemAdmin: '系統管理員',
  vernderAdmin: '廠商管理員',
  siteAccount: '分站賬號',
  operatorAccount: '操作者賬號',
  operateConDetail: '操作內容詳情',
  plsSelectClassify: '請選擇分類',
  logining: '登錄跳轉中...',
  inputMemberName: '輸入會員帳號',
  plsInputUserName: '請輸入用戶名',
  inputLangConQuery: '輸入語言內容查詢',
  configPermissionEntry: '配置權限項',
  operatorType: '操作類型',
  memberAccount: '會員賬號',
  allInstructions: '全部指令',
  totalClassify: '全部分類',
  memberNickname: '會員暱稱',
  currencyNo: '幣別報表',
  memberIDLottery: '會員ID(泰國彩)',
  memberIDCS: '會員ID(廠商)',
  inputContent: '輸入內容',
  totalTimeMS: '總耗時(毫秒)',
  currentAccountSearchRange: '當前賬查詢時間範圍：',
  permission: '權限',
  allType: '所有類型',
  allClassify: '所有分類',
  editPermission: '編輯權限',
  permissionConfig: '權限配置',
  inputInstructContent: '輸入指令內容查詢',
  plsInputMaintainExplain: '請輸入維護說明',
  allControl: '--所有控制器--',
  inputAuthName: '輸入權限組名',
  plSelectType: '請選擇分類',
  configMenuAuth: '配置菜單權限',
  open: '開',
  close: '關',
  lotNo: '期數',
  alllotterytype: '全部彩種',
  allstatus: '全部狀態',
  InputNoteNumberOrMemberAccount: '輸入注單號或會員賬號',
  plsInputMemberAccount: '請輸入會員賬號',
  plsInputAmount: '輸入金額',
  manualRefresh: '手動刷新',
  total: '合計',
  // Tab菜單
  '/app': '首頁',
  '/app/statisticsreport': '注單統計',
  '/app/financereport': '財務報表',
  '/app/openmanage': '開獎管理',
  '/app/gamesettings': '遊戲設定',
  '/app/syssetting': '系統設定',
  '/app/logs': '日誌',
  '/app/systemlogs': '系統日誌',
  '/app/menu': '菜單管理',
  '/app/syssetting/user': '系統用戶管理',
  '/app/syssetting/usergroup': '系統用戶組管理',
  '/app/syssetting/sitemanagement': '分站管理',
  '/app/syssetting/sysmaintain': '系統維護',
  '/app/syssetting/vendermanagement': '廠商管理',
  '/app/syssetting/languagemanagement': '多語言管理',
  '/app/syssetting/operatecommand': '操作指令管理',
  '/app/syssetting/operatecommandclass': '操作指令分類管理',
  '/app/menu/config': '菜單配置',
  '/app/menu/authoritygroup': '權限組管理',
  '/app/menu/authorityitem': '權限項管理',
  '/app/menu/controller': '控制器管理',
  '/app/menu/usertemplate': '權限模板管理',
  '/app/systemlogs/ctrlloginlogs': '管理員登錄日誌',
  '/app/systemlogs/errorlogs': '錯誤日誌',
  '/app/systemlogs/apilogs': 'API日誌',
  '/app/logs/memberdynamiclogs': '會員動態日誌',
  '/app/logs/operatelogs': '操作日誌',
  '/app/logs/memberloginlogs': '會員登錄日誌',
  '/app/logs/siteloginlogs': '分站登錄日誌',
  '/app/financereport/currentaccount': '當前賬',
  '/app/financereport/countdetail': '注單查詢',
  '/app/statisticsreport/today': '今日注單',
  '/app/statisticsreport/realtime': '實時注單',
  '/app/userinfo/userlist': '用戶列表',
  '/app/openmanage/openmanagedetail': '開獎管理',
  '/app/openmanage/openresult': '開獎歷史',
  '/app/gamesettings/playconfig': '玩法配置',
  '/app/gamesettings/lotteryconfig': '彩種啓停',
  '/app/gamesettings/lotteryonekey': '一鍵啓停管理'
}

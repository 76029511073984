/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 权限项列表【权限项管理】
 */
export interface data {
  /**
   * 权限ID
   */
  id: number

  /**
   * 所属分类(控制器名称)
   */
  controllerName: string

  /**
   * 权限项名称
   */
  actionName: string

  /**
   * 权限分组ID
   */
  actionGroupID: number

  /**
   * 是否自动选中（1--自动选中 0--不选中）
   */
  autoSelected: boolean

  /**
   * 排序码
   */
  orderNo: number

  /**
   * 备注
   */
  remark: string

  /**
   * 权限名称(简体)
   */
  actionName_zh_cn: string

  /**
   * 权限名称(繁体)
   */
  actionName_zh_tw: string

  /**
   * 权限名称(英文)
   */
  actionName_en: string

  /**
   * 权限名称(越南文)
   */
  actionName_vn: string

  /**
   * 权限名称(泰文)
   */
  actionName_th: string

  /**
   * 彩种名称
   */
  name: string
}
export interface MenuGetActionListEntity {
  recondCount: number
  data: data[]
}

/**
 * 权限项列表【权限项管理】
 */
export interface MenuGetActionListParams {
  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number

  /**
   * 权限项名称
   */
  actionsName?: string

  /**
   * 控制器名
   */
  controllerName?: string
}

/**
 * 权限项列表【权限项管理】
 */
export const menuGetActionList = (params: MenuGetActionListParams) => {
  return client.get<IClientResp<MenuGetActionListEntity>>(
    '/Menu/GetActionList',
    { params }
  )
}

/**
 * - **通过** `Inject('menuGetActionListStore')` 依赖注入到组件
 * - **权限项列表【权限项管理】** MenuGetActionListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetActionListStore: MenuGetActionListStore
		}
		@Inject('menuGetActionListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetActionListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetActionListStore extends AsyncStore {
  @observable getActionListEntity?: MenuGetActionListEntity = undefined
  @action
  async getActionList(params: MenuGetActionListParams) {
    return this.asyncAction<MenuGetActionListEntity>(
      'getActionListEntity',
      menuGetActionList(params)
    )
  }
}

export const menuGetActionListStore = new MenuGetActionListStore()

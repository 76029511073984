/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改系统维护【系统维护】
 */
export interface SysUpdateMaintenancesEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 修改系统维护【系统维护】
 */
export interface SysUpdateMaintenancesParams {
  /**
   * 站点类型（1--前台下注端 2--后台）
   */
  siteType: number

  /**
   * 状态(1--开放 0--维护)
   */
  state: boolean

  /**
   * 维护开始时间
   */
  startDateTime: string

  /**
   * 维护开始时间
   */
  endDateTime: string

  /**
   * 维护说明(简体)
   */
  msg_zh_cn: string

  /**
   * 维护说明(繁体)
   */
  msg_zh_tw: string

  /**
   * 维护说明(英文)
   */
  msg_en: string

  /**
   * 维护说明(越南文)
   */
  msg_vn: string

  /**
   * 维护说明(泰文)
   */
  msg_th: string

  /**
   * 是否T线(前端才T线)
   */
  isKickOut: boolean
}

/**
 * 修改系统维护【系统维护】
 */
export const sysUpdateMaintenances = (params: SysUpdateMaintenancesParams) => {
  return client.post<IClientResp<SysUpdateMaintenancesEntity>>(
    '/Sys/UpdateMaintenances',
    params
  )
}

/**
 * - **通过** `Inject('sysUpdateMaintenancesStore')` 依赖注入到组件
 * - **修改系统维护【系统维护】** SysUpdateMaintenancesStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysUpdateMaintenancesStore: SysUpdateMaintenancesStore
		}
		@Inject('sysUpdateMaintenancesStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysUpdateMaintenancesStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysUpdateMaintenancesStore extends AsyncStore {
  @observable updateMaintenancesEntity?: SysUpdateMaintenancesEntity = undefined
  @action
  async updateMaintenances(params: SysUpdateMaintenancesParams) {
    return this.asyncAction<SysUpdateMaintenancesEntity>(
      'updateMaintenancesEntity',
      sysUpdateMaintenances(params)
    )
  }
}

export const sysUpdateMaintenancesStore = new SysUpdateMaintenancesStore()

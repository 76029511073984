/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改开奖结果
 */
export interface OpenManageUpdateResultEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 修改开奖结果
 */
export interface OpenManageUpdateResultParams {
  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 期号查询
   */
  lottoNo: string

  /**
   * 开奖结果传入的格式 逗号隔开
   */
  openResult: string
}

/**
 * 修改开奖结果
 */
export const openManageUpdateResult = (
  params: OpenManageUpdateResultParams
) => {
  return client.post<IClientResp<OpenManageUpdateResultEntity>>(
    '/OpenManage/UpdateResult',
    params
  )
}

/**
 * - **通过** `Inject('openManageUpdateResultStore')` 依赖注入到组件
 * - **修改开奖结果** OpenManageUpdateResultStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			openManageUpdateResultStore: OpenManageUpdateResultStore
		}
		@Inject('openManageUpdateResultStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			openManageUpdateResultStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class OpenManageUpdateResultStore extends AsyncStore {
  @observable updateResultEntity?: OpenManageUpdateResultEntity = undefined
  @action
  async updateResult(params: OpenManageUpdateResultParams) {
    return this.asyncAction<OpenManageUpdateResultEntity>(
      'updateResultEntity',
      openManageUpdateResult(params)
    )
  }
}

export const openManageUpdateResultStore = new OpenManageUpdateResultStore()

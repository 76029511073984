/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 一键设置开盘/封盘【一键启停管理】
 */
/**
 * 一键设置开盘/封盘
 */
export interface LottosettingUpdatePanManagerEntity {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 状态(0-封盘 1-开盘)
   */
  state: boolean
}

/**
 * 一键设置开盘/封盘【一键启停管理】
 */
export interface LottosettingUpdatePanManagerParams {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 状态(0-封盘 1-开盘)
   */
  state: boolean
}

/**
 * 一键设置开盘/封盘【一键启停管理】
 */
export const lottosettingUpdatePanManager = (
  params: LottosettingUpdatePanManagerParams
) => {
  return client.post<IClientResp<LottosettingUpdatePanManagerEntity>>(
    '/lottosetting/updatePanManager',
    params
  )
}

/**
 * - **通过** `Inject('lottosettingUpdatePanManagerStore')` 依赖注入到组件
 * - **一键设置开盘/封盘【一键启停管理】** LottosettingUpdatePanManagerStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingUpdatePanManagerStore: LottosettingUpdatePanManagerStore
		}
		@Inject('lottosettingUpdatePanManagerStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingUpdatePanManagerStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingUpdatePanManagerStore extends AsyncStore {
  @observable
  updatePanManagerEntity?: LottosettingUpdatePanManagerEntity = undefined
  @action
  async updatePanManager(params: LottosettingUpdatePanManagerParams) {
    return this.asyncAction<LottosettingUpdatePanManagerEntity>(
      'updatePanManagerEntity',
      lottosettingUpdatePanManager(params)
    )
  }
}

export const lottosettingUpdatePanManagerStore = new LottosettingUpdatePanManagerStore()

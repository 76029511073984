/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改开关【彩种启停】
 */
/**
 * 修改开关
 */
export interface LottosettingUpdateOpenStateEntity {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 开放/关闭(0--关闭  1--开放)主要显示功能
   */
  openDisplayState: boolean
}

/**
 * 修改开关【彩种启停】
 */
export interface LottosettingUpdateOpenStateParams {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 开放/关闭(0--关闭  1--开放)主要显示功能
   */
  openDisplayState: boolean
}

/**
 * 修改开关【彩种启停】
 */
export const lottosettingUpdateOpenState = (
  params: LottosettingUpdateOpenStateParams
) => {
  return client.post<IClientResp<LottosettingUpdateOpenStateEntity>>(
    '/lottosetting/updateOpenState',
    params
  )
}

/**
 * - **通过** `Inject('lottosettingUpdateOpenStateStore')` 依赖注入到组件
 * - **修改开关【彩种启停】** LottosettingUpdateOpenStateStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingUpdateOpenStateStore: LottosettingUpdateOpenStateStore
		}
		@Inject('lottosettingUpdateOpenStateStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingUpdateOpenStateStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingUpdateOpenStateStore extends AsyncStore {
  @observable
  updateOpenStateEntity?: LottosettingUpdateOpenStateEntity = undefined
  @action
  async updateOpenState(params: LottosettingUpdateOpenStateParams) {
    return this.asyncAction<LottosettingUpdateOpenStateEntity>(
      'updateOpenStateEntity',
      lottosettingUpdateOpenState(params)
    )
  }
}

export const lottosettingUpdateOpenStateStore = new LottosettingUpdateOpenStateStore()

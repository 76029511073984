import { Button, message, Spin, Tree } from 'antd'
import Page from 'components/Page'
import { lang } from 'lang'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps } from 'react-router-dom'
import {
  MenuMenuActionConfigEntityItem,
  MenuMenuActionConfigStore
} from 'services/menu/menu_action_config'
import { MenuModifyAuthorityStore } from 'services/menu/modify_authority'
import { MenuRyActionsConfigStore } from 'services/menu/ry_actions_config'
import { Inject } from 'store'
import SetGroup from './setGroup'

const { Content } = Page
const TreeNode = Tree.TreeNode

interface BindMenuProps extends RouteComponentProps {
  menuMenuActionConfigStore: MenuMenuActionConfigStore
  menuModifyAuthorityStore: MenuModifyAuthorityStore
  menuRyActionsConfigStore: MenuRyActionsConfigStore
}

@Inject(s => ({
  menuMenuActionConfigStore: s.menuMenuActionConfigStore,
  menuModifyAuthorityStore: s.menuModifyAuthorityStore,
  menuRyActionsConfigStore: s.menuRyActionsConfigStore
}))
@observer
export default class MenuSetting extends Component<BindMenuProps> {
  searchParam = {
    venderName: '',
    siteNo: '',
    menuActionConfigID: 0,
    languageNo: ''
  }
  @observable editVisable = false
  @observable selected: string[] = []

  @observable groupModalShow = false
  @observable groupMenuData: MenuMenuActionConfigEntityItem | {} = {}

  componentDidMount = () => {
    this.getRequest()
    this.getConfig()
  }

  getConfig = async () => {
    const { menuMenuActionConfigStore } = this.props
    const res = await menuMenuActionConfigStore.menuActionConfig(
      this.searchParam
    )
    if (res && res.code === 0) {
      this.selected = this.getDefaultChecked(res.body).checked
    }
  }

  getRequest() {
    const url = this.props.location.search
    if (url.indexOf('?') !== -1) {
      const str = url.substr(1)
      const strs = str.split('&')

      strs.forEach((item, index) => {
        const each = strs[index].split('=')
        switch (each[0]) {
          case 'VenderName':
            this.searchParam.venderName = each[1]
            break
          case 'SiteNo':
            this.searchParam.siteNo = each[1]
            break
          case 'MenuActionConfigID':
            this.searchParam.menuActionConfigID = parseInt(each[1], 10)
            break
          case 'LanguageNo':
            this.searchParam.languageNo = each[1]
            break
        }
      })
    }
  }

  /**
   * 获取选中项
   */
  getDefaultChecked = data => {
    const checked: string[] = []
    const childChecked: string[] = []
    data.forEach(item => {
      const hasChild = item.menuPermissionExtension.length
      if (item.isSelected) {
        checked.push(item.id.toString())
        if (!hasChild) {
          childChecked.push(item.id.toString())
        }
      }
      if (hasChild) {
        item.menuPermissionExtension.forEach(i => {
          if (i.isSelected) {
            childChecked.push(i.id.toString())
            checked.push(i.id.toString())
          }
        })
      }
    })

    return {
      checked,
      childChecked
    }
  }

  loop = data => {
    return data.map(item => {
      const title = (
        <div data-item={item}>
          {item.menuName}
          {item.hasSetting ? (
            <span style={{ color: '#f00' }}>
              &nbsp;&nbsp;[{lang.messages.setting}]
            </span>
          ) : null}
        </div>
      )
      if (item.menuPermissionExtension) {
        return (
          <TreeNode key={item.id} active title={title}>
            {this.loop(item.menuPermissionExtension)}
          </TreeNode>
        )
      }
      return <TreeNode key={item.id} active title={title} />
    })
  }

  onCheck = (checkedKeys, info) => {
    this.selected = checkedKeys.concat(info.halfCheckedKeys)
  }

  onSelect = async (k, info) => {
    const item = info.node.title.props['data-item']
    if (item.hasSetting) {
      this.groupMenuData = item
      const res = await this.props.menuRyActionsConfigStore.ryActionsConfig({
        ...this.searchParam,
        subMenuID: (this.groupMenuData as MenuMenuActionConfigEntityItem).id
      })
      if (res && res.code === 0) {
        this.groupModalShow = true
      }
    }
  }

  save = async () => {
    const { menuModifyAuthorityStore, menuMenuActionConfigStore } = this.props
    const res = await menuModifyAuthorityStore.modifyAuthority({
      ...this.searchParam,
      menuIDs: this.selected.join(',')
    })
    if (res) {
      message.info(res.msg)
    }
    menuMenuActionConfigStore.menuActionConfig(this.searchParam)
  }

  render() {
    const {
      menuMenuActionConfigStore,
      menuModifyAuthorityStore,
      menuRyActionsConfigStore
    } = this.props
    const { menuActionConfigEntity } = menuMenuActionConfigStore
    const { ryActionsConfigEntity } = menuRyActionsConfigStore

    return (
      <Page>
        <Content>
          {menuActionConfigEntity && !menuMenuActionConfigStore.loading ? (
            <Tree
              checkable
              onCheck={this.onCheck}
              onSelect={this.onSelect}
              defaultCheckedKeys={
                this.getDefaultChecked(menuActionConfigEntity).childChecked
              }
            >
              {this.loop(menuActionConfigEntity)}
            </Tree>
          ) : (
            <Spin />
          )}
          <div style={{ marginTop: '20px' }}>
            <Button
              loading={menuModifyAuthorityStore.loading}
              onClick={this.save}
              type="primary"
            >
              <FormattedMessage id="save" defaultMessage="保存" />
            </Button>
          </div>
          <SetGroup
            visible={this.groupModalShow}
            group={ryActionsConfigEntity}
            menuData={this.groupMenuData as MenuMenuActionConfigEntityItem}
            onClose={() => {
              this.groupMenuData = {}
              this.groupModalShow = false
            }}
            loading={menuRyActionsConfigStore.loading}
            restParams={this.searchParam}
          />
        </Content>
      </Page>
    )
  }
}

import { Col, Row } from 'antd'
import React from 'react'

declare interface HeaderProps {
  children?: React.ReactNode
  title?: string
  style?: React.CSSProperties
  className?: string
}
declare interface HeaderState {}
class Header extends React.Component<HeaderProps, HeaderState> {
  render() {
    return (
      <Row gutter={[0, 32]}>
        <Col>{this.props.children}</Col>
      </Row>
    )
  }
}

export default Header

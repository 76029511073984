import { message } from 'antd'
import { AxiosPromise } from 'axios'
import { action, observable, runInAction } from 'mobx'
import { IClientResp } from './client'
/**
 * 基础 store
 * - 请求数据 设置entity
 */
export abstract class AsyncStore {
  @observable loading = false
  @observable error = false
  @action
  protected async asyncAction<T>(
    entity: string,
    apiCall: AxiosPromise<IClientResp<T>>
  ) {
    this.loading = true
    this.error = false
    try {
      const res = await apiCall
      if (res.data.code === 0) {
        runInAction(() => {
          this[entity] = res.data.body
        })
      } else {
        message.info(res.data.msg)
      }
      runInAction(() => {
        this.loading = false
      })
      return res.data
    } catch (error) {
      runInAction(() => {
        this.error = true
        this.loading = false
      })
    }
  }
}

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改权限模版【权限模版管理】
 */
export interface MenuModifyMenuActionConfigTemplatesEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 修改权限模版【权限模版管理】
 */
/**
 * 返回值 code=0 修改成功 ， code = 1 修改失败 ，&gt;=10000 其他系统错误
 */
export interface MenuModifyMenuActionConfigTemplatesParams {
  /**
   * ID
   */
  id?: number

  /**
   * 模板类型(0--系统管理员 1--分站账号 2-厂商管理员)
   */
  templateType?: string

  /**
   * 模板名称
   */
  templateName_zh_cn: string
  templateName_zh_tw: string
  templateName_en: string
  templateName_th: string
  templateName_vn: string

  /**
   * 备注
   */
  remark: string
}

/**
 * 修改权限模版【权限模版管理】
 */
export const menuModifyMenuActionConfigTemplates = (
  params: MenuModifyMenuActionConfigTemplatesParams
) => {
  return client.post<IClientResp<MenuModifyMenuActionConfigTemplatesEntity>>(
    '/Menu/ModifyMenuActionConfigTemplates',
    params
  )
}

/**
 * - **通过** `Inject('menuModifyMenuActionConfigTemplatesStore')` 依赖注入到组件
 * - **修改权限模版【权限模版管理】** MenuModifyMenuActionConfigTemplatesStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuModifyMenuActionConfigTemplatesStore: MenuModifyMenuActionConfigTemplatesStore
		}
		@Inject('menuModifyMenuActionConfigTemplatesStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuModifyMenuActionConfigTemplatesStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuModifyMenuActionConfigTemplatesStore extends AsyncStore {
  @observable
  modifyMenuActionConfigTemplatesEntity?: MenuModifyMenuActionConfigTemplatesEntity = undefined
  @action
  async modifyMenuActionConfigTemplates(
    params: MenuModifyMenuActionConfigTemplatesParams
  ) {
    return this.asyncAction<MenuModifyMenuActionConfigTemplatesEntity>(
      'modifyMenuActionConfigTemplatesEntity',
      menuModifyMenuActionConfigTemplates(params)
    )
  }
}

export const menuModifyMenuActionConfigTemplatesStore = new MenuModifyMenuActionConfigTemplatesStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取分站自动降赔列表 by felix
 */
export interface LottosettingGetLowerOddsConfigEntityItem {
  /**
   * 玩法名称
   */
  playTypeName: string
  venderName: string
  siteNo: string
  lottoID: number
  lottoPlayTypeID: number
  state: boolean
  betMoneyCount: number
  lowerLines: number
}
export type LottosettingGetLowerOddsConfigEntity = LottosettingGetLowerOddsConfigEntityItem[]

/**
 * 获取分站自动降赔列表 by felix
 */
export interface LottosettingGetLowerOddsConfigParams {
  /**
   * 彩种ID
   */
  lottoId: number
}

/**
 * 获取分站自动降赔列表 by felix
 */
export const lottosettingGetLowerOddsConfig = (
  params: LottosettingGetLowerOddsConfigParams
) => {
  return client.get<IClientResp<LottosettingGetLowerOddsConfigEntity>>(
    '/lottosetting/getLowerOddsConfig',
    { params }
  )
}

/**
 * - **通过** `Inject('lottosettingGetLowerOddsConfigStore')` 依赖注入到组件
 * - **获取分站自动降赔列表 by felix** LottosettingGetLowerOddsConfigStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingGetLowerOddsConfigStore: LottosettingGetLowerOddsConfigStore
		}
		@Inject('lottosettingGetLowerOddsConfigStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingGetLowerOddsConfigStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingGetLowerOddsConfigStore extends AsyncStore {
  @observable
  getLowerOddsConfigEntity?: LottosettingGetLowerOddsConfigEntity = undefined
  @action
  async getLowerOddsConfig(params: LottosettingGetLowerOddsConfigParams) {
    return this.asyncAction<LottosettingGetLowerOddsConfigEntity>(
      'getLowerOddsConfigEntity',
      lottosettingGetLowerOddsConfig(params)
    )
  }
}

export const lottosettingGetLowerOddsConfigStore = new LottosettingGetLowerOddsConfigStore()

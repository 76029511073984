import numeral from 'numeral'
import { prefix } from './config'

/**
 * check source is equals target
 * @param {any} s source
 * @param {any} t target
 * @returns {boolean}
 */
export const isEqual = (s: any, t: any) => {
  if (typeof s !== 'object') {
    return s === t
  }
  return JSON.stringify(s) === JSON.stringify(t)
}

/**
 * 为路径加上前缀
 * @param path 需要连接的路径 /reports => /app/reports
 */
export const getUrlWithPrefix = (path: string = '') => `${prefix}${path}`

/**
 *  格式刻度显示
 * @param value
 */
export const formatScale = value => {
  if (value >= 10000 && value <= 99999) {
    return `${Math.floor(value / 1000)} k`
  }
  if (value > 99999) {
    return `${Math.floor(value / 10000)} w`
  }
  return value
}

/**
 * 格式数字显示
 * flag为true时，数字会被转换为精确度为两位小数的数字。如果数字等于或大于10万时，会被除10000。
 * flag为false时，数字会被转换为格式为（'0,0'）的数字
 *
 * @param num 需要处理的数字
 * @param flag 处理金额标识符。默认值为false
 */
export const formatNumber = (num: number | string, flag = false) => {
  if (flag) {
    if (Math.abs(num as number) < 100000) {
      // return num
      return [num, '']
    } else {
      const temp = (num as number) / 10000
      return temp.toFixed(2)
    }
  } else {
    return numeral(num).format('0,0')
  }
}

/**
 * 格式化以万为单位的数字
 *
 * @param val 需要处理的数字
 */
export const formatTenThousand = val => {
  let num = 0
  let unit = ''

  if (val.length) {
    return `${val[0]}`
  } else {
    num = val
    unit = '万'
    return `${num} ${unit}`
  }
}

export const filterEmptyParam = val => {
  const temp = {}

  for (const key of val) {
    if (val[key]) {
      temp[key] = val[key]
    }
  }

  return temp
}

/**
 * 将非布尔类型转换为布尔类型
 *
 */
export function convertToBoolProperty(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim()

    return val === 'true' || val === ''
  }

  return !!val
}

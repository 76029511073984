/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 用户信息分页列表【用户信息】
 */
export interface data {
  /**
   * 彩票平台的会员ID
   */
  lottoMemberID: number

  /**
   * 厂商名称
   */
  venderName: string

  /**
   * 分站编码
   */
  siteNo: string

  /**
   * 厂商的会员ID
   */
  venderMemberID: string

  /**
   * 厂商的会员账号
   */
  venderMemberName: string

  /**
   * 厂商的会员昵称
   */
  venderNickName: string

  /**
   * 余额
   */
  balance: number

  /**
   * 货币编码
   */
  currencyNo: string

  /**
   * 最后登录时间
   */
  lastLoginDateTime: string

  /**
   * 最后登录IP
   */
  lastLoginIP: string
}
export interface LottoMemberGetLottoMemberEntity {
  recondCount: number
  data: data[]
}

/**
 * 用户信息分页列表【用户信息】
 */
export interface LottoMemberGetLottoMemberParams {
  /**
   * true-查询在线，false-查询全部
   */
  Online?: boolean

  /**
   * 开始时间(eg:[2020-03-01 12:00:00])
   */
  BeginTime?: string

  /**
   * 结束时间(eg:[2020-03-07 12:00:00])
   */
  EndTime?: string

  /**
   * 搜索类型【1.会员账号，2.会员昵称，3.会员ID(泰国彩)，4.会员ID(厂商)】
   */
  SearchType?: number

  /**
   * 搜索内容
   */
  SearchContent?: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 用户信息分页列表【用户信息】
 */
export const lottoMemberGetLottoMember = (
  params: LottoMemberGetLottoMemberParams
) => {
  return client.get<IClientResp<LottoMemberGetLottoMemberEntity>>(
    '/lottoMember/getLottoMember',
    { params }
  )
}

/**
 * - **通过** `Inject('lottoMemberGetLottoMemberStore')` 依赖注入到组件
 * - **用户信息分页列表【用户信息】** LottoMemberGetLottoMemberStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottoMemberGetLottoMemberStore: LottoMemberGetLottoMemberStore
		}
		@Inject('lottoMemberGetLottoMemberStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottoMemberGetLottoMemberStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottoMemberGetLottoMemberStore extends AsyncStore {
  @observable getLottoMemberEntity?: LottoMemberGetLottoMemberEntity = undefined
  @action
  async getLottoMember(params: LottoMemberGetLottoMemberParams) {
    return this.asyncAction<LottoMemberGetLottoMemberEntity>(
      'getLottoMemberEntity',
      lottoMemberGetLottoMember(params)
    )
  }
}

export const lottoMemberGetLottoMemberStore = new LottoMemberGetLottoMemberStore()

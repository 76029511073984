export default {
  "noticeText": "公告",
  "cancel": "取消",
  "define": "确定",
  "title": "泰国彩后台",
  "totalRecord": "共{total}条记录",
  "loginInvalid": "登录失效，请重新登录",
  "welcome": "欢迎您",
  "exit": "退出",
  "betNo": "注单编号",
  "betDateTime": "下注时间",
  "lottery": "彩种",
  "playType": "玩法",
  "betNumber": "投注号码",
  "multiple": "赔率",
  "betMoney": "投注金额",
  "waterWithdrawal": "退水",
  "memberWinOrLose": "会员输赢",
  "state": "状态",
  "completed": "已完成",
  "changedOrder": "已改单",
  "invalidNote": "无效注单",
  "counterSettlement": "反结算",
  "reversed": "已撤单",
  "pageTotal": "小计",
  "total": "总计",
  "query": "查询",
  "currentAccountHome": "当前账首页",
  "date": "日期:",
  "betNums": "笔数",
  "member": "会员",
  "betDetail": "下注明细",
  "currency": "货币",
  "excludeTestAccount": "排除测试账号",
  "settlementStatement": "交收报表",
  "classifiedReport": "分类报表",
  "dealed": "已结算",
  "byDate": "按日期",
  "lotteryTypeName": "彩种名称",
  "queryTime": "查询时间",
  "yesterday": "昨天",
  "today": "今天",
  "lastWeek": "上周",
  "thisWeek": "本周",
  "lastMonth": "上月",
  "thisMonth": "本月",
  "reportType": "报表类型",
  "reset": "重置",
  "orderByGroupKey": "按照分组关键字排序",
  "inverseByGameWinLose": "游戏输赢倒序",
  "orderByGameWinLose": "游戏输赢顺序",
  "inverseByBetMoney": "下注金额倒序",
  "orderByBetMoney": "下注金额顺序",
  "search": "搜索",
  "openDraw": "开盘",
  "closeDraw": "封盘",
  "close": "关闭",
  "editConfig": "修改配置",
  "save": "保存",
  "currentPeriod": "当前期数",
  "switch": "开关",
  "closeAutoState": "你确定要关闭自动结算吗?",
  "openAutoState": "你确定要开启自动结算吗?",
  "startDateTime": "开始时间",
  "aotuOpen": "是否自动开盘",
  "closePanDateTime": "封盘时间",
  "countDown": "倒计时",
  "openResultTime": "开奖时间",
  "venderName": "厂商名称",
  "siteNo": "分站编号",
  "siteName": "分站名称",
  "currentState": "当前状态",
  "allPlatform": "全平台",
  "openPanNumber": "开盘数量",
  "oneKeyClosePan": "确定对所有分站进行封盘吗？",
  "oneKeyClose": "一键封盘",
  "closePanNumber": "封盘数量",
  "oneKeyOpenPan": "确定对所有分站进行开盘吗？",
  "oneKeyOpen": "一键开盘",
  "EveryTimeBet": "额度每投注",
  "everyTimeReduce": "每次降低",
  "startUsing": "启用",
  "configType": "配置类型",
  "playTypeConfig": "玩法配置",
  "oddsChange": "赔率变动",
  "autoOdds": "自动降赔",
  "assignedNumber": "指定号码",
  "fillBetContent": "请输入指定号码",
  "fillChangeValues": "请输入赔率",
  "expiry": "有效期",
  "operation": "操作",
  "edit": "编辑",
  "delete": "删除",
  "allPlayType": "全部玩法",
  "deleteSelectedConfirm": "确定要删除所选?",
  "deleteSelected": "删除所选",
  "add": "新增",
  "editPlayType": "编辑玩法",
  "addPlayType": "新增玩法",
  "minBet": "最小投注",
  "min": "最小",
  "singleInjectionLimit": "单注上限",
  "max": "最大",
  "singleLimit": "单期限额",
  "onlineMember": "在线会员",
  "sevenDataGameData": "近七天投注数据",
  "todayGameData": "今日游戏数据",
  "plsInputUserName": "请填写用户名",
  "plsInputPwd": "请填写密码",
  "atLeastEight": "最少八位数",
  "login": "登录",
  "normal": "正常",
  "warning": "警告",
  "artificialBan": "人工禁言",
  "systemBan": "系统禁言",
  "memberUser": "会员昵称/账号",
  "remarksContent": "备注内容",
  "operateTime": "操作时间",
  "loginDateTime": "登录时间",
  "loginIP": "登录IP",
  "operateUserName": "操作者姓名",
  "operatorUserType": "操作者用户类型",
  "csManageAccount": "厂商管理账号",
  "zcsManageAccount": "子厂商管理账号",
  "siteAccount": "分站帐号",
  "operateCommand": "操作命令",
  "operatorIP": "操作者IP",
  "operateContent": "操作内容",
  "see": "查看",
  "userType": "用户类型",
  "authName": "权限组名称",
  "plsInputName": "请输入名称",
  "authNameTw": "权限组名称(繁体)",
  "authNameEn": "权限组名称(英文)",
  "authNameVn": "权限组名称(越南文)",
  "authNameTh": "权限组名称(泰文)",
  "autoSelected": "自动选中",
  "orderNumber": "排序码",
  "remark": "备注",
  "addNewAuthGroup": "添加权限组",
  "updateNewAuthGroup": "修改控权限组",
  "typeName": "分类名称",
  "true": "是",
  "false": "否",
  "order": "排序",
  "modify": "修改",
  "sureDelete": "你确定要删除吗？",
  "configAuth": "配置权限组",
  "addNewAuth": "新增权限组",
  "bindAuth": "绑定权限组",
  "actionName": "权限项名称",
  "actionNameZhCn": "权限项别称",
  "plsInputNameZh": "请输入名称",
  "actionName_zh_tw": "权限项别称(繁体)",
  "plsInputNameTw": "请输入名称(繁体)",
  "actionName_en": "权限项别称(英文)",
  "plsInputNameEn": "请输入名称(英文)",
  "actionName_vn": "权限项别称(越南文)",
  "plsInputNameVn": "请输入名称(越南文)",
  "actionName_th": "权限项别称(泰文)",
  "plsInputNameTh": "请输入名称(泰文)",
  "controllerName": "控制器名称",
  "isShow": "是否显示",
  "addNewAuthItem": "新增权限项",
  "belongMenu": "所属菜单",
  "mainMenu": "主菜单",
  "titleZh": "主菜单(简体)",
  "outputLimit": "超出限制",
  "titleTw": "菜单名称(繁体)",
  "titleEn": "菜单名称(英文)",
  "titleVn": "菜单名称(越南文)",
  "titleTh": "菜单名称(泰文)",
  "website": "对应网址",
  "tabName": "标签页名称",
  "plsInputOrderNumber": "请输入排序码",
  "plsInputNumber": "请输入数字",
  "icon": "图标",
  "addMenu": "新增菜单",
  "addSubMenu": "新增子菜单",
  "updateMenu": "修改菜单",
  "menuName": "菜单名称",
  "subTitle": "子菜单",
  "anotherNameZh": "控制器别称(简体)",
  "plsInputNickName": "请输入别称",
  "controllerNickNameTw": "控制器别称(繁体)",
  "controllerNickNameEn": "控制器别称(英文)",
  "controllerNickNameVn": "控制器别称(越南文)",
  "controllerNickNameTh": "控制器别称(泰文)",
  "addNewControl": "新增控制器",
  "modifyController": "修改控制器",
  "controllerNickName": "控制器别称",
  "return": "返回",
  "templateType": "模板类型",
  "templateNameZh": "模板名称(简体)",
  "templateNameTw": "模板名称(繁体)",
  "templateNameEn": "模板名称(英文)",
  "templateNameVn": "模板名称(越南文)",
  "templateNameTh": "模板名称(泰文)",
  "addTemplate": "新增模板",
  "modifyTemplate": "修改模板",
  "templateName": "模板名称",
  "notFound": "抱歉，你访问的页面不存在。",
  "returnHome": "返回首页",
  "addNoticeText": "发布公告",
  "editNoticeText": "编辑公告",
  "sendWebSite": "发送分站",
  "noticeContentText": "公告内容",
  "textZh": "简体",
  "textTw": "繁体",
  "textEn": "英文",
  "textTh": "泰文",
  "textVn": "越南文",
  "noticeTime": "公告时间",
  "isState": "是否启用",
  "isTop": "是否置顶",
  "object": "对象",
  "backstage": "后台",
  "reception": "前台",
  "noticeType": "公告类型",
  "horseRaceLamp": "跑马灯",
  "popUpNotice": "弹出公告",
  "endDateTime": "结束时间",
  "no": "序号",
  "openPanDateTime": "开盘时间",
  "addNewNo": "添加期数",
  "openResult": "开奖号码",
  "autoDeal": "自动结算",
  "deal": "未结算",
  "dealing": "结算中",
  "backDealing": "反结算中",
  "againDealing": "重新结算中",
  "noSignDealState": "无效结算状态",
  "unknowState": "未知状态",
  "firstPrize": "一等奖",
  "thirdPrizeFront": "前3位数字",
  "thirdPrizeEnd": "后3位数字",
  "secondPrizeEnd": "后2位数字",
  "thirdPrizeUp": "三定位上",
  "secondPrizeUp": "二定位上",
  "secondPrizeDown": "二定位下",
  "failBill": "无效结算",
  "settle": "结算",
  "againSettle": "重新结算",
  "defineDo": "你确定要这样做吗？",
  "notToDrawTime": "未到开奖时间",
  "resultInput": "结果录入",
  "clearNumber": "清除号码",
  "exportReport": "导出注单",
  "greaterThan": "大于",
  "amountHighlight": "金额高亮",
  "lessThan": "小于",
  "amountNotShow": "金额不显示",
  "excludeTrialAccount": "排除试玩账号",
  "second": "秒",
  "reload": "刷新",
  "plsInputKey": "请输入KEY",
  "formatError": "格式错误",
  "explain": "说明",
  "plsInputExplain": "请输入说明",
  "contentZh": "简体内容",
  "plsInputContentZh": "请输入简体内容",
  "contentTw": "繁体内容",
  "plsInputContentTw": "请输入繁体内容",
  "contentEn": "英文内容",
  "plsInputContentEn": "请输入英文内容",
  "editLanguage": "编辑多语言",
  "addLanguage": "添加多语言",
  "addMultilingual": "新增多语言",
  "time": "时间",
  "plsInputID": "请输入ID",
  "plsSelectUserType": "请选择用户类型",
  "plsInputKeyName": "请输入KeyName",
  "addInstruct": "新增指令",
  "plsInputClassName": "请输入ClassName",
  "addInstructClassify": "新增指令分类",
  "siteType": "类型",
  "maintainTimeSlot": "维护时间段",
  "maintainExplain": "维护说明",
  "maintainState": "维护状态",
  "openUp": "开放",
  "maintain": "维护",
  "maintainExplainZh": "维护说明(简体)：",
  "maintainTime": "维护时间：",
  "plsInputTime": "请填写时间！",
  "memberOperation": "会员操作：",
  "kickOutAllMember": "踢出所有会员",
  "maintainSettings": "维护设置",
  "account": "账号",
  "nickname": "昵称",
  "userGroupList": "用户组",
  "stopUsing": "停用",
  "ipManagement": "IP管理",
  "addUser": "新增用户",
  "blackIPList": "IP黑名单",
  "multipleIPRule": "多个IP用,隔开",
  "bindIPList": "IP白名单",
  "twoToEightEnglishletterChinese": "2-8位英文、中文",
  "twoToEightEnglishletter": "2-8位英文",
  "twoToEightEnglishletterThai": "2-8位英文、泰文",
  "twoToEightEnglishletterVietnam": "2-8位英文、越南傣文",
  "plsInputAccount": "请输入账号",
  "numbersFromSixToEighteenInEnglish": "6~18的数字英文",
  "accountNickname": "账号昵称",
  "plsInputNickname": "请输入昵称",
  "loginPwd": "登录密码",
  "eightToEighteenLettersNumbersSymbols": "8-18位字母、数字、符号",
  "permissionStatus": "权限状态",
  "modifyUser": "修改用户",
  "userGroupName": "用户组名称",
  "userGroupRemark": "用户组备注",
  "permissionGroupStatus": "权限组状态",
  "addUserGroup": "新增用户组",
  "plsInputRemark": "请输入备注",
  "modifyUserGroup": "修改用户组",
  "venderNo": "厂商编码",
  "venderNickname": "厂商昵称",
  "callLog": "调用日志",
  "calledLog": "被调用日志",
  "classify": "分类",
  "httpFormData": "传入参数",
  "outParam": "传出参数",
  "totalTimes": "总耗时",
  "recordingTime": "记录时间",
  "detailedInformation": "详细信息",
  "errorMessage": "错误信息",
  "venderMemberID": "会员ID（厂商）",
  "lottoMemberID": "泰国彩",
  "balance": "余额",
  "currencyNo": "货币编号",
  "lastLogin": "最后登录",
  "loginLogs": "登录日志",
  "lookatOnline": "只看在线"
}
/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 泰国彩 人工新增期号
 */
export interface OpenManageAddNewOpenEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 泰国彩 人工新增期号
 */
export interface OpenManageAddNewOpenParams {
  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 开盘时间
   */
  openPanDateTime: string

  /**
   * 开奖时间
   */
  openResultTime: string

  /**
   * 是否自动开盘
   */
  isAutoOpen: boolean
}

/**
 * 泰国彩 人工新增期号
 */
export const openManageAddNewOpen = (params: OpenManageAddNewOpenParams) => {
  return client.post<IClientResp<OpenManageAddNewOpenEntity>>(
    '/OpenManage/AddNewOpen',
    params
  )
}

/**
 * - **通过** `Inject('openManageAddNewOpenStore')` 依赖注入到组件
 * - **泰国彩 人工新增期号** OpenManageAddNewOpenStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			openManageAddNewOpenStore: OpenManageAddNewOpenStore
		}
		@Inject('openManageAddNewOpenStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			openManageAddNewOpenStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class OpenManageAddNewOpenStore extends AsyncStore {
  @observable addNewOpenEntity?: OpenManageAddNewOpenEntity = undefined
  @action
  async addNewOpen(params: OpenManageAddNewOpenParams) {
    return this.asyncAction<OpenManageAddNewOpenEntity>(
      'addNewOpenEntity',
      openManageAddNewOpen(params)
    )
  }
}

export const openManageAddNewOpenStore = new OpenManageAddNewOpenStore()

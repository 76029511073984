/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 配置模板权限组权限【权限模版管理】
 */
export interface MenuModifyActionAuthorityEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 配置模板权限组权限【权限模版管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface MenuModifyActionAuthorityParams {
  /**
   * 权限模版id
   */
  templateID: number

  /**
   * 当前选中权限项
   */
  actions: string

  /**
   * 当前权限组下的所有权限项
   */
  allActionID: string
}

/**
 * 配置模板权限组权限【权限模版管理】
 */
export const menuModifyActionAuthority = (
  params: MenuModifyActionAuthorityParams
) => {
  return client.post<IClientResp<MenuModifyActionAuthorityEntity>>(
    '/Menu/ModifyActionAuthority',
    params
  )
}

/**
 * - **通过** `Inject('menuModifyActionAuthorityStore')` 依赖注入到组件
 * - **配置模板权限组权限【权限模版管理】** MenuModifyActionAuthorityStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuModifyActionAuthorityStore: MenuModifyActionAuthorityStore
		}
		@Inject('menuModifyActionAuthorityStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuModifyActionAuthorityStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuModifyActionAuthorityStore extends AsyncStore {
  @observable
  modifyActionAuthorityEntity?: MenuModifyActionAuthorityEntity = undefined
  @action
  async modifyActionAuthority(params: MenuModifyActionAuthorityParams) {
    return this.asyncAction<MenuModifyActionAuthorityEntity>(
      'modifyActionAuthorityEntity',
      menuModifyActionAuthority(params)
    )
  }
}

export const menuModifyActionAuthorityStore = new MenuModifyActionAuthorityStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 通过主菜单id获取子菜单数据【菜单管理】
 */
export interface MenuMenuPidListEntityItem {
  /**
   * 菜单ID
   */
  id: number

  /**
   * 菜单层级(1--表示一级菜单)
   */
  menuLayer: number

  /**
   * 父级菜单ID
   */
  pid: number

  /**
   * 排序码
   */
  orderNo: number

  /**
   * 菜单对应的网址
   */
  menuUrl: string

  /**
   * 标签页名称（用于固定在某一个标签页显示）
   */
  tabName: string

  /**
   * 是否显示(0--不显示 1--显示)
   */
  display: boolean

  /**
   * 是否覆盖左边菜单栏(0--不覆盖 1--覆盖)
   */
  covered: boolean

  /**
   * 页面呈现方式(0--当前窗口  1--新窗口
   */
  pageDisplay: number

  /**
   * 备注
   */
  remark: string

  /**
   * 二级菜单小图标
   */
  icon: string

  /**
   * 菜单项名称(简体)
   */
  menuName_zh_cn: string

  /**
   * 菜单项名称(繁体)
   */
  menuName_zh_tw: string

  /**
   * 菜单项名称(英文)
   */
  menuName_en: string

  /**
   * 菜单项名称(越南文)
   */
  menuName_vn: string

  /**
   * 菜单项名称(泰文)
   */
  menuName_th: string

  /**
   * 子菜单数量
   */
  childCount: number
}
export type MenuMenuPidListEntity = MenuMenuPidListEntityItem[]

/**
 * 通过主菜单id获取子菜单数据【菜单管理】
 */
export interface MenuMenuPidListParams {
  id: number
}

/**
 * 通过主菜单id获取子菜单数据【菜单管理】
 */
export const menuMenuPidList = (params: MenuMenuPidListParams) => {
  return client.get<IClientResp<MenuMenuPidListEntity>>('/Menu/MenuPidList', {
    params
  })
}

/**
 * - **通过** `Inject('menuMenuPidListStore')` 依赖注入到组件
 * - **通过主菜单id获取子菜单数据【菜单管理】** MenuMenuPidListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuMenuPidListStore: MenuMenuPidListStore
		}
		@Inject('menuMenuPidListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuMenuPidListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuMenuPidListStore extends AsyncStore {
  @observable menuPidListEntity?: MenuMenuPidListEntity = undefined
  @action
  async menuPidList(params: MenuMenuPidListParams) {
    return this.asyncAction<MenuMenuPidListEntity>(
      'menuPidListEntity',
      menuMenuPidList(params)
    )
  }
}

export const menuMenuPidListStore = new MenuMenuPidListStore()

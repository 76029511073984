/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取操作指令分类列表【操作指令分类管理】
 */
export interface data {
  /**
   * 分类名称
   */
  className: string

  /**
   * 操作命令分类名称(简体)
   */
  classNickName_zh_cn: string

  /**
   * 操作命令分类名称(繁体)
   */
  classNickName_zh_tw: string

  /**
   * 操作命令分类名称(英文)
   */
  classNickName_en: string

  /**
   * 操作命令分类名称(越南)
   */
  classNickName_vn: string

  /**
   * 操作命令分类名称(泰语)
   */
  classNickName_th: string
}
export interface SysGetOperateCommandClassListEntity {
  recondCount: number
  data: data[]
}

/**
 * 获取操作指令分类列表【操作指令分类管理】
 */
export interface SysGetOperateCommandClassListParams {
  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 获取操作指令分类列表【操作指令分类管理】
 */
export const sysGetOperateCommandClassList = (
  params: SysGetOperateCommandClassListParams
) => {
  return client.get<IClientResp<SysGetOperateCommandClassListEntity>>(
    '/Sys/GetOperateCommandClassList',
    { params }
  )
}

/**
 * - **通过** `Inject('sysGetOperateCommandClassListStore')` 依赖注入到组件
 * - **获取操作指令分类列表【操作指令分类管理】** SysGetOperateCommandClassListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysGetOperateCommandClassListStore: SysGetOperateCommandClassListStore
		}
		@Inject('sysGetOperateCommandClassListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysGetOperateCommandClassListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysGetOperateCommandClassListStore extends AsyncStore {
  @observable
  getOperateCommandClassListEntity?: SysGetOperateCommandClassListEntity = undefined
  @action
  async getOperateCommandClassList(
    params: SysGetOperateCommandClassListParams
  ) {
    return this.asyncAction<SysGetOperateCommandClassListEntity>(
      'getOperateCommandClassListEntity',
      sysGetOperateCommandClassList(params)
    )
  }
}

export const sysGetOperateCommandClassListStore = new SysGetOperateCommandClassListStore()

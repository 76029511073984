/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 新增控制器【控制器管理】
 */
export interface MenuAddControllersEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 新增控制器【控制器管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface MenuAddControllersParams {
  /**
   * 控制器名称（用于权限组和权限项的分类）
   */
  controllerName: string

  /**
   * 控制器的别称（用中文描述）
   */
  anotherName_zh_cn: string
  anotherName_zh_tw: string
  anotherName_en: string
  anotherName_th: string
  anotherName_vn: string
}

/**
 * 新增控制器【控制器管理】
 */
export const menuAddControllers = (params: MenuAddControllersParams) => {
  return client.post<IClientResp<MenuAddControllersEntity>>(
    '/Menu/AddControllers',
    params
  )
}

/**
 * - **通过** `Inject('menuAddControllersStore')` 依赖注入到组件
 * - **新增控制器【控制器管理】** MenuAddControllersStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuAddControllersStore: MenuAddControllersStore
		}
		@Inject('menuAddControllersStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuAddControllersStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuAddControllersStore extends AsyncStore {
  @observable addControllersEntity?: MenuAddControllersEntity = undefined
  @action
  async addControllers(params: MenuAddControllersParams) {
    return this.asyncAction<MenuAddControllersEntity>(
      'addControllersEntity',
      menuAddControllers(params)
    )
  }
}

export const menuAddControllersStore = new MenuAddControllersStore()

import { Button, Result } from 'antd'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { getUrlWithPrefix } from 'utils'

class NotFound extends Component {
  render() {
    return (
      <Result
        status={404}
        title="404"
        subTitle={
          <FormattedMessage
            id="notFound"
            defaultMessage="抱歉，你访问的页面不存在。"
          />
        }
        extra={
          <Link to={`${getUrlWithPrefix()}`}>
            <Button>
              <FormattedMessage id="returnHome" defaultMessage="返回首页" />
            </Button>
          </Link>
        }
      />
    )
  }
}

export default NotFound

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改权限项【权限项管理】
 */
export interface MenuUpdateActionEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 修改权限项【权限项管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface MenuUpdateActionParams {
  /**
   * 权限项ID
   */
  id?: number

  /**
   * 所属分类(控制器名称)
   */
  controllerName: string

  /**
   * 权限项名称
   */
  actionName: string

  /**
   * 是否自动选中（1--自动选中 0--不选中）
   */
  autoSelected: boolean

  /**
   * 排序码
   */
  orderNo: number

  /**
   * 备注
   */
  remark?: string

  /**
   * 权限名称(简体)
   */
  actionName_zh_cn: string

  /**
   * 权限名称(繁体)
   */
  actionName_zh_tw?: string

  /**
   * 权限名称(英文)
   */
  actionName_en?: string

  /**
   * 权限名称(越南文)
   */
  actionName_vn?: string

  /**
   * 权限名称(泰文)
   */
  actionName_th?: string
}

/**
 * 修改权限项【权限项管理】
 */
export const menuUpdateAction = (params: MenuUpdateActionParams) => {
  return client.post<IClientResp<MenuUpdateActionEntity>>(
    '/Menu/UpdateAction',
    params
  )
}

/**
 * - **通过** `Inject('menuUpdateActionStore')` 依赖注入到组件
 * - **修改权限项【权限项管理】** MenuUpdateActionStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuUpdateActionStore: MenuUpdateActionStore
		}
		@Inject('menuUpdateActionStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuUpdateActionStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuUpdateActionStore extends AsyncStore {
  @observable updateActionEntity?: MenuUpdateActionEntity = undefined
  @action
  async updateAction(params: MenuUpdateActionParams) {
    return this.asyncAction<MenuUpdateActionEntity>(
      'updateActionEntity',
      menuUpdateAction(params)
    )
  }
}

export const menuUpdateActionStore = new MenuUpdateActionStore()

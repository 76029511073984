/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取彩种启停列表【彩种启停】
 */
export interface LottosettingGetTgLottosConfigEntityItem {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 彩种名称
   */
  lottoName: string

  /**
   * 当前期数
   */
  currentPeriod: string

  /**
   * 开放/关闭(0--关闭  1--开放)主要显示功能
   */
  openDisplayState: boolean

  /**
   * 即时盘口状态(0--封盘  1--开盘)
   */
  panState: boolean

  /**
   * 是否自动开盘(1--自动开盘  0--手工开盘)，主要用于泰国彩
   */
  autoOpenPan: boolean

  /**
   * 提前N秒封盘
   */
  beforeCloseTime: number

  /**
   * 开盘时间
   */
  startDateTime: string

  /**
   * 封盘时间
   */
  closeDateTime: string

  /**
   * 开奖时间
   */
  openResultDateTime: string

  /**
   * 封盘倒计时
   */
  closeDateCountdown: number

  /**
   * 开奖时间倒计时
   */
  openResultCountdown: number
}
export type LottosettingGetTgLottosConfigEntity = LottosettingGetTgLottosConfigEntityItem[]

/**
 * 获取彩种启停列表【彩种启停】
 */
export const lottosettingGetTgLottosConfig = () => {
  return client.get<IClientResp<LottosettingGetTgLottosConfigEntity>>(
    '/lottosetting/getTGLottosConfig'
  )
}

/**
 * - **通过** `Inject('lottosettingGetTgLottosConfigStore')` 依赖注入到组件
 * - **获取彩种启停列表【彩种启停】** LottosettingGetTgLottosConfigStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingGetTgLottosConfigStore: LottosettingGetTgLottosConfigStore
		}
		@Inject('lottosettingGetTgLottosConfigStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingGetTgLottosConfigStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingGetTgLottosConfigStore extends AsyncStore {
  @observable
  getTgLottosConfigEntity?: LottosettingGetTgLottosConfigEntity = undefined
  @action
  async getTgLottosConfig() {
    return this.asyncAction<LottosettingGetTgLottosConfigEntity>(
      'getTgLottosConfigEntity',
      lottosettingGetTgLottosConfig()
    )
  }
}

export const lottosettingGetTgLottosConfigStore = new LottosettingGetTgLottosConfigStore()

import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/th'
import 'dayjs/locale/vi'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'
import { Provider } from 'mobx-react'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import Media from 'react-media'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { getUrlWithPrefix } from 'utils'
import { storage } from 'utils/config'
import language, { lang } from '../lang'
import { stores } from '../store'
import App from './App'
import Login from './Login'
import Context from './context'
import SsoLogin from './Login/ssoLogin'
import MenuSetting from './MenuSetting'
import NotFound from './NotFound'

/**
 * 需要登录以后才能访问的路由
 * @param SubRoute
 */
// const RequireAuthorize = (SubRoute: any) => {
//   class PrivateRoute extends Component {
//     render() {
//       return isAuthorized() ? (
//         <SubRoute {...this.props} />
//       ) : (
//         <Redirect to={'/login'} />
//       )
//     }
//   }
//   return PrivateRoute
// }
export interface MainState {
  locale: string
  messages: any
  switchLocale: any
}
class Main extends Component<any, MainState> {
  async componentDidMount() {
    const itemLocale = await localStorage.getItem(storage.locale)
    const { locale } = this.state
    dayjs.locale(locale)
    if (itemLocale) {
      this.setState({
        locale: itemLocale
      })
    } else {
      localStorage.setItem(storage.locale, locale)
    }
    this.setLang(itemLocale || locale)
  }
  /**
   * 切换多语言
   */
  switchLocale = locale => {
    dayjs.locale(locale)
    this.setState({ locale })
    this.setLang(locale)
    localStorage.setItem(storage.locale, locale)
  }

  setLang = locale => {
    const m = language[locale].infos
    lang.messages = m
    this.setState({
      messages: m
    })
  }

  state = {
    locale: 'th',
    messages: lang.messages,
    switchLocale: this.switchLocale
  }
  render() {
    const { locale, messages } = this.state
    return (
      <Context.Provider value={this.state as any}>
        <IntlProvider locale={locale} messages={language[locale].messages}>
          <ConfigProvider locale={language[locale].ant}>
            <Helmet>
              <title>{messages.title}</title>
              <html
                className={
                  localStorage.getItem(storage.locale) === 'th' ? 'th' : ''
                }
              />
            </Helmet>
            <Media query="(max-width: 599px)">
              {isMobile => (
                <Provider {...stores}>
                  <HashRouter>
                    <Switch>
                      <Redirect exact from="/" to={getUrlWithPrefix()} />
                      <Route
                        path={getUrlWithPrefix()}
                        render={props => <App {...props} isMobile={isMobile} />}
                      />
                      <Route
                        path="/login/:postdata?/:from?/:path?"
                        component={props => (
                          <Login {...props} locale={locale} />
                        )}
                      />
                      <Route
                        path="/sso/:languageNo/:mD5DATA/:menuActionConfigIDs/:nowDateTime/:pageStyle/:securityKey/:selectedSiteNo/:siteNo/:timeStamp/:userName/:venderName/:venderNo"
                        component={SsoLogin}
                      />
                      <Route path="/menusetting" component={MenuSetting} />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </HashRouter>
                </Provider>
              )}
            </Media>
          </ConfigProvider>
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export default Main

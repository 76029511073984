export default {
  title: 'หน้าการจัดการล็อตเตอรีไทย',
  loginInvalid: 'เข้าสู่ระบบล้มเหลว โปรดเข้าสู่ระบบอีกครั้ง',
  welcome: 'ยินดีต้อนรับคุณ',
  exit: 'ออก',
  betNo: 'หมายเลขเดิมพัน',
  betDateTime: 'เวลาเดิมพัน',
  lottery: 'ล็อตเตอรี่',
  playType: 'วิธีการเล่น',
  betNumber: 'หมายเลขเดิมพัน',
  multiple: 'อัตราจ่าย',
  betMoney: 'จำนวนเงินเดิมพัน',
  waterWithdrawal: 'ค่าน้ำ',
  memberWinOrLose: 'สมาชิกแพ้ชนะ',
  state: 'สถานะ',
  completed: 'เสร็จสิ้นแล้ว',
  changedOrder: 'แก้ไขบิลแล้ว',
  invalidNote: 'เดิมพันที่ไม่มีผล',
  counterSettlement: 'การคำนวณกลับ',
  reversed: 'รายการถูกยกเลิก',
  pageTotal: 'ยอดรวม',
  total: 'ยอดรวมทั้งหมด',
  query: 'การสอบสวน',
  currentAccountHome: 'หน้าบัญชีปัจจุบัน',
  currencyNo: 'หมายเลขสกุลเงิน',
  date: 'วันที่:',
  betNums: 'รายการ',
  betDetail: 'รายละเอียดการเดิมพัน',
  currency: 'สกุลเงิน',
  excludeTestAccount: 'ไม่รวมบัญชีทดลองเล่น',
  settlementStatement: 'รายงานการส่งรับ',
  classifiedReport: 'ประเภทรายงาน',
  dealed: 'คำนวณแล้ว',
  byDate: 'ตามวันที่',
  lotteryTypeName: 'ชื่อล็อตเตอรี่',
  queryTime: 'เวลาตรวจสอบ',
  yesterday: 'เมื่อวาน',
  today: 'วันนี้',
  lastWeek: 'สัปดาห์ที่แล้ว',
  thisWeek: 'สัปดาห์นี้',
  lastMonth: 'เดือนที่แล้ว',
  thisMonth: 'เดือนนี้',
  reportType: 'ประเภทรายงาน',
  reset: 'ตั้งค่าใหม่',
  orderByGroupKey: 'เรียงตามคีย์เวิร์ดการจัดกลุ่ม',
  inverseByGameWinLose: 'เรียงตามคีย์เวิร์ดการจัดกลุ่ม',
  orderByGameWinLose: 'เรียงลำดับแพ้ชนะเกมส์',
  inverseByBetMoney: 'เรียงลำดับกลับจำนวนเงินเดิมพัน',
  orderByBetMoney: 'เรียงลำดับจำนวนเงินเดิมพัน',
  search: 'ค้นหา',
  member: 'สมาชิก',
  openDraw: 'เปิดแผง',
  closeDraw: 'ปิดแผง',
  close: 'ปิด',
  editConfig: 'แก้ไขการกำหนดค่า',
  save: 'บันทึก',
  cancel: 'ยกเลิก',
  currentPeriod: 'รอบปัจจุบัน',
  switch: 'เปิดปิด',
  startDateTime: 'เวลาเริ่มต้น',
  aotuOpen: 'ต้องการให้เปิดแผงอัตโนมัติหรือไม่',
  closePanDateTime: 'เวลาการปิดแผง',
  countDown: 'นับถอยหลัง',
  openResultTime: 'เวลาเปิดรางวัล',
  venderName: 'ชื่อผู้ผลิต',
  siteNo: 'หมายเลขเว็บไซต์',
  siteName: 'ชื่อเว็บไซต์',
  currentState: 'สถานะปัจจุบัน',
  allPlatform: 'แพลตฟอร์มเต็มรูปแบบ',
  openPanNumber: 'จำนวนการเปิดแผง',
  oneKeyClose: 'คลิกเดียวปิดแผง',
  closePanNumber: 'จำนวนการปิดแผง',
  oneKeyOpen: 'คลิกเดียวเปิดแผง',
  EveryTimeBet: 'ยอดเงินต่อการเดิมพัน',
  everyTimeReduce: 'ลดลงทุกครั้ง',
  startUsing: 'เปิดใช้',
  configType: 'ประเภทการกำหนดค่า',
  playTypeConfig: 'การกำหนดค่าวิธีการเล่นเกมส์',
  oddsChange: 'การเปลี่ยนแปลงอัตราจ่าย',
  autoOdds: 'จ่ายชดเชยอัตโนมัติ',
  assignedNumber: 'หมายเลขที่กำหนด',
  fillBetContent: 'กรุณาใส่หมายเลขที่ระบุ',
  fillChangeValues: 'กรุณาใส่อัตราจ่าย',
  expiry: 'วันหมดอายุ',
  operation: 'การดำเนินการ',
  edit: 'แก้ไข',
  delete: 'ลบ',
  allPlayType: 'วิธีการเล่นทั้งหมด',
  add: 'เพิ่ม',
  editPlayType: 'แก้ไขวิธีการเล่น',
  addPlayType: 'เพิ่มวิธีการเล่น',
  minBet: 'เดิมพันขั้นต่ำ',
  min: 'ต่ำสุด',
  singleInjectionLimit: 'จำกัดการเดิมพันเดี่ยว',
  max: 'สูงสุด',
  singleLimit: 'ยอดจำกัดเดียว',
  onlineMember: 'สมาชิกออนไลน์',
  todayGameData: 'ข้อมูลเกมส์วันนี้',
  plsInputUserName: 'กรุณากรอกชื่อผู้ใช้',
  plsInputPwd: 'กรุณากรอกรหัสผ่าน',
  atLeastEight: 'อย่างน้อย 8 หลัก',
  login: 'เข้าสู่ระบบ',
  normal: 'ปกติ',
  warning: 'แจ้งเตือน',
  artificialBan: 'พนักงานปิดเสียง',
  systemBan: 'ระบบปิดเสียง',
  memberUser: 'ชื่อเล่นสมาชิก / บัญชี',
  remarksContent: 'เนื้อหาหมายเหตุ',
  operateTime: 'เวลาดำเนินการ',
  loginDateTime: 'เวลาเข้าสู่ระบบ',
  loginIP: 'IP เข้าสู่ระบบ ',
  operateUserName: 'ชื่อผู้ดำเนินการ',
  operatorUserType: 'ประเภทผู้ดำเนินการ',
  csManageAccount: 'บัญชีการจัดการผู้ผลิต',
  zcsManageAccount: 'บัญชีการจัดการผู้ผลิตย่อย',
  siteAccount: 'บัญชีเว็บไซต์',
  operateCommand: 'คำสั่งการดำเนินงาน',
  operatorIP: 'IP ผู้ดำเนินการ',
  operateContent: 'เนื้อหาการดำเนินการ',
  see: 'ตรวจดู',
  userType: 'ประเภทผู้ใช้',
  authName: 'ชื่อกลุ่มได้รับสิทธิ์',
  plsInputName: 'โปรดกรอกชื่อ',
  authNameTw: 'ชื่อกลุ่มที่มีสิทธิ์(ภาษาจีนตัวเต็ม)',
  authNameEn: 'ชื่อกลุ่มที่มีสิทธิ์(ภาษาอังกฤษ)',
  authNameVn: 'ชื่อกลุ่มที่มีสิทธิ์(ภาษาเวียดนาม)',
  authNameTh: 'ชื่อกลุ่มที่มีสิทธิ์(ภาษาไทย)',
  autoSelected: 'เลือกโดยอัตโนมัติ',
  orderNumber: 'เรียงรหัส',
  remark: 'หมายเหตุ',
  addNewAuthGroup: 'เพิ่มกลุ่มสิทธิ์',
  updateNewAuthGroup: 'แก้ไขกลุ่มควบคุมสิทธิ์',
  typeName: 'ชื่อประเภท',
  true: 'ใช่',
  false: 'ไม่',
  order: 'ลำดับ',
  modify: 'แก้ไข',
  sureDelete: 'คุณแน่ใจหรือว่าต้องการลบ',
  configAuth: 'กำหนดค่ากลุ่มสิทธิ์',
  addNewAuth: 'เพิ่มกลุ่มสิทธิ์',
  bindAuth: 'ผูกกลุ่มสิทธิ์',
  actionName: 'ชื่อรายการที่ได้รับสิทธิ์',
  actionNameZhCn: 'ชื่อเล่นรายการได้รับสิทธิ์',
  actionName_zh_tw: 'ชื่อเล่นรายการได้รับสิทธิ์(ภาษาจีนตัวเต็ม)',
  actionName_en: 'ชื่อเล่นรายการได้รับสิทธิ์(ภาษาอังกฤษ)',
  actionName_vn: 'ชื่อเล่นรายการได้รับสิทธิ์(ภาษาเวียดนาม)',
  actionName_th: 'ชื่อเล่นรายการได้รับสิทธิ์(ภาษาจีนตัวย่อ)',
  plsInputNameZh: 'โปรดกรอกชื่อ',
  plsInputNameTw: 'กรุณาใส่ชื่อ(ภาษาจีนตัวเต็ม)',
  plsInputNameEn: 'กรุณาใส่ชื่อ(ภาษาอังกฤษ)',
  plsInputNameVn: 'กรุณาใส่ชื่อ(ภาษาเวียดนาม)',
  plsInputNameTh: 'ชื่ออื่นของตัวควบคุม(ภาษาจีนตัวย่อ)',
  controllerName: 'ชื่อเครื่องควบคุม',
  isShow: 'แสดงหรือไม่',
  addNewAuthItem: 'เพิ่มรายการสิทธิ์',
  belongMenu: 'เมนูของตัวเอง',
  mainMenu: 'เมนูหลัก',
  titleZh: 'เมนูหลัก(ภาษาจีนย่อ)',
  outputLimit: 'เกินขีดจำกัด',
  titleTw: 'ชื่อเมนู(ภาษาจีนตัวเต็ม)',
  titleEn: 'ชื่อเมนู(ภาษาอังกฤษ)',
  titleVn: 'ชื่อเมนู(ภาษาเวียดนาม)',
  titleTh: 'ชื่อเมนู(ภาษาไทย)',
  website: 'URL ที่สอดคล้องกัน',
  tabName: 'ชื่อหน้าแท็บ',
  plsInputOrderNumber: 'กรุณาใส่รหัสเรียง',
  plsInputNumber: 'กรุณากรอกตัวเลข',
  icon: 'ไอคอน',
  addMenu: 'เพิ่มเมนู',
  addSubMenu: 'เพิ่มเมนูย่อย',
  updateMenu: 'แก้ไขเมนู',
  menuName: 'ชื่อเมนู',
  subTitle: 'เมนูย่อย',
  anotherNameZh: 'ชื่ออื่นของตัวควบคุม(ภาษาจีนตัวย่อ)',
  plsInputNickName: 'แก้ไขตัวควบคุม',
  controllerNickNameTw: 'ชื่ออื่นของตัวควบคุม(ภาษาจีนตัวเต็ม)',
  controllerNickNameEn: 'ชื่ออื่นของตัวควบคุม(ภาษาอังกฤษ)',
  controllerNickNameVn: 'ชื่ออื่นของตัวควบคุม(ภาษาเวียดนาม)',
  controllerNickNameTh: 'ชื่ออื่นของตัวควบคุม(ภาษาจีนตัวย่อ)',
  controllerNickName: 'ชื่ออื่นของตัวควบคุม',
  addNewControl: 'เพิ่มตัวควบคุม',
  return: 'กลับ',
  templateType: 'ประเภทเทมเพลต',
  templateNameZh: 'ชื่อเทมเพลต(ภาษาจีนตัวย่อ)',
  templateNameTw: 'ชื่อเทมเพลต(ภาษาจีนตัวเต็ม)',
  templateNameEn: 'ชื่อเทมเพลต(ภาษาอังกฤษ)',
  templateNameVn: 'ชื่อเทมเพลต(ภาษาเวียดนาม)',
  templateNameTh: 'ชื่อเทมเพลต(ภาษาไทย)',
  templateName: 'ชื่อเทมเพลต',
  notFound: 'ขออภัย ไม่มีหน้าที่คุณเข้าชม',
  returnHome: 'กลับสู่หน้าหลัก',
  openPanDateTime: 'เวลาเปิดแผง',
  addNewNo: 'เพิ่มช่วงเวลา',
  openResult: 'หมายเลขลอตเตอรี่',
  autoDeal: 'คำนวนอัตโนมัติ',
  closeAutoState: 'คุณแน่ใจหรือไม่ว่าต้องการปิดการคำนวณอัตโนมัติ',
  openAutoState: 'คุณแน่ใจหรือไม่ว่าต้องการเปิดการคำนวณอัตโนมัติ',
  deal: 'ยังไม่ได้คำนวณ',
  dealing: 'อยู่ระหว่างการคำนวณ',
  backDealing: 'อยู่ระหว่างการคำนวณกลับ',
  againDealing: 'อยู่ระหว่างการคำนวณใหม่',
  noSignDealState: 'สถานะการคำนวณที่ไม่มีผล',
  unknowState: 'ไม่ทราบสถานะ',
  firstPrize: 'รางวัลที่ 1',
  secondPrizeDown: 'ต่ำกว่าสองตำแหน่ง',
  thirdPrizeUp: 'สามตำแหน่งขึ้น',
  secondPrizeUp: 'สองตำแหน่งขึ้น',
  failBill: 'การคำนวนไม่มีผล',
  settle: 'คำนวณ',
  defineDo: 'คุณแน่ใจหรือไม่ว่าต้องการทำแบบนี้?',
  notToDrawTime: 'ยังไม่ถึงเวลาเปิดรางวัล',
  resultInput: 'รายการผลลัพธ์',
  clearNumber: 'เคลียร์หมายเลข',
  greaterThan: 'มากกว่า',
  amountHighlight: 'จำนวนเงินที่ไฮไลต์',
  lessThan: 'น้อยกว่า',
  amountNotShow: 'จำนวนเงินไม่แสดง',
  excludeTrialAccount: 'ไม่รวมบัญชีทดลอง',
  second: 'วินาที',
  reload: 'รีเฟรช',
  plsInputKey: 'กรุณากรอกKEY',
  formatError: 'รูปแบบผิดพลาด',
  explain: 'คำอธิบาย',
  plsInputExplain: 'กรุณากรอกคำอธิบาย',
  contentZh: 'เนื้อหาภาษาจีนตัวย่อ',
  plsInputContentZh: 'โปรดป้อนเนื้อหาภาษาจีนตัวย่อ',
  contentTw: 'เนื้อหาภาษาจีนตัวเต็ม',
  plsInputContentTw: 'กรุณาใส่เนื้อหาภาษาจีนตัวเต็ม',
  contentEn: 'เนื้อหาภาษาอังกฤษ',
  plsInputContentEn: 'กรุณาใส่เนื้อหาภาษาอังกฤษ',
  editLanguage: 'แก้ไขหลายภาษา',
  addLanguage: 'เพิ่มหลายภาษา',
  addMultilingual: 'เพิ่มหลายภาษา',
  time: 'เวลา',
  plsInputID: 'โปรดกรอก ID',
  plsSelectUserType: 'กรุณาเลือกประเภทผู้ใช้',
  plsInputKeyName: 'กรุณากรอก KeyName',
  addInstruct: 'เพิ่มคำสั่ง',
  plsInputClassName: 'กรุณาใส่ ClassName',
  addInstructClassify: 'เพิ่มประเภทคำสั่ง',
  siteType: 'ประเภท',
  reception: 'หน้าเว็บไซต์',
  backstage: 'หน้าเว็บไซต์',
  maintainTimeSlot: 'ช่วงเวลาการบำรุงรักษา',
  maintainExplain: 'คำแนะนำการบำรุงรักษา',
  maintainState: 'สถานะการบำรุงรักษา',
  openUp: 'เปิดบริการ',
  maintain: 'บำรุงรักษา',
  maintainExplainZh: 'คำแนะนำการบำรุงรักษา(ภาษาจีนตัวย่อ):',
  maintainTime: 'เวลาบำรุงรักษา:',
  plsInputTime: 'กรุณากรอกเวลา!',
  memberOperation: 'การดำเนินการของสมาชิก:',
  kickOutAllMember: 'ดีดสมาชิกทุกคนออก',
  maintainSettings: 'การตั้งค่าบำรุงรักษา',
  account: 'บัญชี',
  nickname: 'ชื่อเล่น',
  userGroupList: 'กลุ่มผู้ใช้',
  stopUsing: 'หยุดใช้',
  ipManagement: 'การจัดการIP',
  addUser: 'เพิ่มผู้ใช้',
  blackIPList: 'บัญชีดำ IP',
  multipleIPRule: 'สำหรับหลาย IP ให้แยกกัน',
  bindIPList: 'รายการ IP บัญชีขาว',
  twoToEightEnglishletterChinese: '2-8 ตัวภาษาอังกฤษ, จีน',
  twoToEightEnglishletter: '2-8 ตัวอักษรอังกฤษ',
  twoToEightEnglishletterThai: '2-8 ตัวภาษาอังกฤษ, ไทย',
  twoToEightEnglishletterVietnam: '2-8 ตัวอังกฤษ, เวียดนาม ไทย',
  plsInputAccount: 'กรุณากรอกบัญชี',
  numbersFromSixToEighteenInEnglish: '6~18 ตัวอักษรตัวเลข ภาษาอังกฤษ',
  accountNickname: 'ชื่อเล่นบัญชี',
  plsInputNickname: 'กรุณากรอกชื่อเล่น',
  loginPwd: 'รหัสผ่านเข้าสู่ระบบ',
  eightToEighteenLettersNumbersSymbols: '8-18 ตัวอักษร ตัวเลข สัญลักษณ์',
  permissionStatus: 'สถานะของสิทธิ์',
  modifyUser: 'แก้ไขผู้ใช้',
  userGroupName: 'ชื่อกลุ่มผู้ใช้',
  userGroupRemark: 'หมายเหตุกลุ่มผู้ใช้',
  permissionGroupStatus: 'สถานะกลุ่มที่ได้รับสิทธิ์',
  addUserGroup: 'เพิ่มกลุ่มผู้ใช้',
  plsInputRemark: 'โปรดกรอกหมายเหตุ',
  modifyUserGroup: 'แก้ไขกลุ่มผู้ใช้',
  venderNo: 'รหัสผู้ผลิต',
  venderNickname: 'ชื่อเล่นผู้ผลิต',
  callLog: 'บันทึกการปรับใช้',
  calledLog: 'บันทึกการถูกปรับใช้',
  classify: 'ประเภท',
  httpFormData: 'พารามิเตอร์นำเข้า',
  outParam: 'พารามิเตอร์นำออก',
  totalTimes: 'รวมเวลา',
  recordingTime: 'บันทึกเวลา',
  detailedInformation: 'รายละเอียดข้อมูล',
  errorMessage: 'ข้อมูลผิดพลาด',
  venderMemberID: 'IDสมาชิก(ผู้ผลิต)',
  lottoMemberID: 'ล็อตเตอรี่ไทย',
  balance: 'ยอดเงิน',
  lastLogin: 'เข้าสู่ระบบล่าสุด',
  loginLogs: 'บันทึกเข้าสู่ระบบ',
  lookatOnline: 'ดูเฉพาะออนไลน์',
  define: 'ยืนยัน',
  sevenDataGameData: 'ข้อมูลเดิมพัน7วันล่าสุด',
  totalRecord: 'รวม{total}รายการ',
  endDateTime: 'เวลาจบ',
  oneKeyClosePan: 'ปิดวางเดิมพันให้ทุกเว็บหรือไม่',
  oneKeyOpenPan: 'เปิดวางเดิมพันให้ทุกเว็บหรือไม่',
  modifyController: 'แก้ไขคอนโทรลเลอร์',
  addTemplate: 'เพิ่มเทมเพลต',
  modifyTemplate: 'แก้เทมเพลต',
  againSettle: 'คำนวณใหม่',
  no: 'เลข',
  noticeText: 'ประกาศ',
  noticeContentText: 'เนื้อหา',
  noticeType: 'ประเภท',
  object: 'เป้าหมาย',
  addNoticeText: 'ส่ง',
  editNoticeText: 'แก้ไข',
  isTop: 'แสดงบนสุด',
  isState: 'เปิดใช้หรือไม่',
  noticeTime: 'เวลาประกาศ',
  sendWebSite: 'ส่งที่เว็บ',
  horseRaceLamp: 'ข้อความลอย',
  popUpNotice: 'เต้งประกาศ',
  textZh: 'ภาษาจีนตัวย่อ',
  textTw: 'ภาษาจีนแบบดั้งเดิม',
  textEn: 'ภาษาอังกฤษ',
  textVn: 'เวียดนาม',
  textTh: 'ไทย',
  thirdPrizeFront: 'เลขหน้า 3 ตัว',
  thirdPrizeEnd: 'เลขท้าย 3 ตัว',
  secondPrizeEnd: 'เลขท้าย 2 ตัว',
  deleteSelectedConfirm: 'ยืนยันลบหรือไม่',
  deleteSelected: 'ลบ',
  exportReport: 'export ใบแทง'
}

import axios from 'axios'
import { removeAuth } from './authentication'
import { baseURL } from './config'

/**
 * 返回数据格式
 */
export interface IClientResp<T = any> {
  code: number
  body: T
  msg: string
}

const client = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  baseURL,
  // 超时时间 30s
  timeout: 30000
})

client.interceptors.response.use(
  (response: any) => {
    if (response.status === 200) {
      if (response.data.code === 10002) {
        const { begTime, endTime } = response.data.body
        window.location.href = `/maintain/${response.data.msg}/${begTime}/${endTime}`
      }
      if (response.data.code === 10003) {
        // 取消其他正在进行的请求
        // source.cancel()
        // 移除权限
        removeAuth()
        // App组件处，有做失效检测认证，所以不在这里重复了
        window.location.href = '#/login'
        // window.location.reload()
        // message.info('登录失效， 请重新登录', () => {
        //   window.location.href = '/login'
        // })
      }
    }
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default client

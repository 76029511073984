/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 分站跳转登录验证
 */
export interface HomeSsoLoginEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码<br />
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {
    /**
     * 厂商名
     */
    venderName: string

    /**
     * 分站编码
     */
    siteNo: string

    /**
     * 账户类型
     */
    userType: 1 | 2 | 3

    /**
     * Token
     */
    token: string

    /**
     * 帐号ID
     */
    id: number

    /**
     * 用户帐号
     */
    userName: string

    /**
     * 昵称
     */
    nickName: string

    /**
     * 头像
     */
    headImg: string

    /**
     * 报表时间
     */
    reportTime: string
  }
}

/**
 * 分站跳转登录验证
 */
/**
 * code=0 登录成功,code=1 登录失败(错误信息[登录信息超时(sso登录使用),6-VenderHeader配置有误(sso登录使用),7-存在非法的权限菜单ID(sso登录使用)])
 */
export interface HomeSsoLoginParams {
  /**
   * 分站编码(@Vender 表示厂商管理员,XX@Vender 表示子厂商管理员)
   */
  siteNo?: string

  /**
   * 选择的分站编码
   */
  selectedSiteNo?: string

  /**
   * 登录账号(可以是厂商/分站的账号)
   */
  userName: string

  /**
* 菜单权限 ID
当ConfigID=0时，表示使用当前SiteNo对应的菜单权限模板对应的权限清单（而不是到FruitGameMenuActionConfig表读取）
*/
  menuActionConfigIDs: string

  /**
   * 页面风格(没有传值则使用预设值 default)
   */
  pageStyle?: string

  /**
   * 语言编码
   */
  languageNo: string

  /**
   * 厂商名
   */
  venderName?: string
  timeStamp?: string

  /**
   * SSO登录 要验证的SecurityKey
   */
  securityKey?: string

  /**
   * 厂商编码
   */
  venderNo: string

  /**
   * 当前时间
   */
  nowDateTime: string

  /**
   * MD5加密数据
   */
  mD5DATA: string
}

/**
 * 分站跳转登录验证
 */
export const homeSsoLogin = (params: HomeSsoLoginParams) => {
  return client.post<IClientResp<HomeSsoLoginEntity>>('/Home/SSOLogin', params)
}

/**
 * - **通过** `Inject('homeSsoLoginStore')` 依赖注入到组件
 * - **分站跳转登录验证** HomeSsoLoginStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			homeSsoLoginStore: HomeSsoLoginStore
		}
		@Inject('homeSsoLoginStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			homeSsoLoginStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class HomeSsoLoginStore extends AsyncStore {
  @observable ssoLoginEntity?: HomeSsoLoginEntity = undefined
  @action
  async ssoLogin(params: HomeSsoLoginParams) {
    return this.asyncAction<HomeSsoLoginEntity>(
      'ssoLoginEntity',
      homeSsoLogin(params)
    )
  }
}

export const homeSsoLoginStore = new HomeSsoLoginStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 实时注单
 */
export interface data {
  /**
   * 流水号
   */
  sequenceID: number

  /**
   * 彩票期数
   */
  lottoNo: string

  /**
   * 注单编号
   */
  betNo: number

  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 彩种名称
   */
  lottoName: string

  /**
   * 玩法名称
   */
  playTypeName: string

  /**
   * 会员
   */
  venderMemberID: string

  /**
   * 会员账号
   */
  venderMemberName: string

  /**
   * 下注时间
   */
  betDateTime: string

  /**
   * 玩法ID
   */
  playTypeID: number
  playTypeGroupID: number
  odds: number

  /**
   * 下注内容
   */
  betContent: string

  /**
   * 下注金额
   */
  betMoney: number

  /**
   * 输赢金额
   */
  winLoseMoney: number

  /**
   * 可赢金额
   */
  predictWinMoney: number

  /**
   * 会员退水金额
   */
  backMoney: number

  /**
   * 会员退水比例
   */
  backPercent: number

  /**
   * 状态(0--正常  1--已改单  2--无效注单  3--反结算 4-已撤单 )
   */
  state: 0 | 1 | 2 | 3 | 4

  /**
   * 是否测试账号(0—正式 1—测试)
   */
  testState: number
}
export interface ReportsRealTimeEntity {
  recondCount: number
  data: data[]
  betSum: number
  backMoneySum: number
  totalBetSum: number
  totalBackMoneySum: number
}

/**
 * 实时注单
 */
export interface ReportsRealTimeParams {
  /**
   * 彩种ID
   */
  LottoId: number

  /**
   * 会员账号名
   */
  MemberName?: string

  /**
   * 是否排除试玩账号
   */
  IsExcludeTest: boolean

  /**
   * 投注金额
   */
  TotalBetMoney?: number

  /**
   * 是否导出
   */
  IsExport: boolean

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 实时注单
 */
export const reportsRealTime = (params: ReportsRealTimeParams) => {
  return client.get<IClientResp<ReportsRealTimeEntity>>('/Reports/RealTime', {
    params
  })
}

/**
 * - **通过** `Inject('reportsRealTimeStore')` 依赖注入到组件
 * - **实时注单** ReportsRealTimeStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			reportsRealTimeStore: ReportsRealTimeStore
		}
		@Inject('reportsRealTimeStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			reportsRealTimeStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ReportsRealTimeStore extends AsyncStore {
  @observable realTimeEntity?: ReportsRealTimeEntity = undefined
  @action
  async realTime(params: ReportsRealTimeParams) {
    return this.asyncAction<ReportsRealTimeEntity>(
      'realTimeEntity',
      reportsRealTime(params)
    )
  }
}

export const reportsRealTimeStore = new ReportsRealTimeStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 注单查询功能
 */
export interface data {
  /**
   * 流水号
   */
  sequenceID: number

  /**
   * 彩票期数
   */
  lottoNo: string

  /**
   * 注单编号
   */
  betNo: number

  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 会员
   */
  venderMemberID: string

  /**
   * 会员账号
   */
  venderMemberName: string

  /**
   * 下注时间
   */
  betDateTime: string

  /**
   * 玩法ID
   */
  playTypeID: number
  playTypeGroupID: number
  odds: number

  /**
   * 下注内容
   */
  betContent: string

  /**
   * 下注金额
   */
  betMoney: number

  /**
   * 输赢金额
   */
  winLoseMoney: number

  /**
   * 可赢金额
   */
  predictWinMoney: number

  /**
   * 会员退水金额
   */
  backMoney: number

  /**
   * 状态(0--正常  1--已改单  2--无效注单  3--反结算 4-已撤单 )
   */
  state: 0 | 1 | 2 | 3 | 4

  /**
   * 是否测试账号(0—正式 1—测试)
   */
  testState: number
  playTypeName: string

  /**
   * 彩种名称
   */
  lottoName: string
}
export interface ReportsBetQueryEntity {
  recondCount: number
  data: data[]
  betSum: number
  backMoneySum: number
  winLoseSum: number
  countData: {
    /**
     * 下注总金额
     */
    betMoneySum: number

    /**
     * 退水总金额
     */
    backMoneySum: number

    /**
     * 输赢总金额
     */
    winLoseSum: number
  }
}

/**
 * 注单查询功能
 */
export interface ReportsBetQueryParams {
  LottoId: number
  SearchKey?: string

  /**
   * 注单状态(默认-1 全部 0--正常  1--已改单  2--无效注单  3--反结算 4-已撤单 )
   */
  BetState?: number
  BeginTime: string
  EndTime: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 注单查询功能
 */
export const reportsBetQuery = (params: ReportsBetQueryParams) => {
  return client.get<IClientResp<ReportsBetQueryEntity>>('/Reports/BetQuery', {
    params
  })
}

/**
 * - **通过** `Inject('reportsBetQueryStore')` 依赖注入到组件
 * - **注单查询功能** ReportsBetQueryStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			reportsBetQueryStore: ReportsBetQueryStore
		}
		@Inject('reportsBetQueryStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			reportsBetQueryStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ReportsBetQueryStore extends AsyncStore {
  @observable betQueryEntity?: ReportsBetQueryEntity = undefined
  @action
  async betQuery(params: ReportsBetQueryParams) {
    return this.asyncAction<ReportsBetQueryEntity>(
      'betQueryEntity',
      reportsBetQuery(params)
    )
  }
}

export const reportsBetQueryStore = new ReportsBetQueryStore()

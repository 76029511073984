/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 添加分站赔率变动记录 by felix
 */
export interface LottosettingAddChangeOddsEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 添加分站赔率变动记录 by felix
 */
export interface LottosettingAddChangeOddsParams {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 玩法ID
   */
  lottoPlayTypeID: number

  /**
   * 指定号码
   */
  betContent: string

  /**
   * 赔率
   */
  changeValues: number
}

/**
 * 添加分站赔率变动记录 by felix
 */
export const lottosettingAddChangeOdds = (
  params: LottosettingAddChangeOddsParams
) => {
  return client.post<IClientResp<LottosettingAddChangeOddsEntity>>(
    '/lottosetting/addChangeOdds',
    params
  )
}

/**
 * - **通过** `Inject('lottosettingAddChangeOddsStore')` 依赖注入到组件
 * - **添加分站赔率变动记录 by felix** LottosettingAddChangeOddsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingAddChangeOddsStore: LottosettingAddChangeOddsStore
		}
		@Inject('lottosettingAddChangeOddsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingAddChangeOddsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingAddChangeOddsStore extends AsyncStore {
  @observable addChangeOddsEntity?: LottosettingAddChangeOddsEntity = undefined
  @action
  async addChangeOdds(params: LottosettingAddChangeOddsParams) {
    return this.asyncAction<LottosettingAddChangeOddsEntity>(
      'addChangeOddsEntity',
      lottosettingAddChangeOdds(params)
    )
  }
}

export const lottosettingAddChangeOddsStore = new LottosettingAddChangeOddsStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 根据子菜单ID获取对应的权限组，权限项目【系统用户组管理】
 */
export interface actionList {
  /**
   * 节点编号
   */
  id: string

  /**
   * 父节点编号
   */
  pid: string

  /**
   * 节点名称
   */
  name_zh_cn: string
  name_zh_tw: string
  name_en: string
  name_vn: string
  name_th: string
  name: string

  /**
   * 是否勾选状态
   */
  check: boolean

  /**
   * 是否打开状态
   */
  open: boolean

  /**
   * 备注
   */
  remak: string

  /**
   * 是否不允许操作
   */
  disabled: boolean
}
export interface SysUserGroupsActionsConfigEntityItem {
  id: number
  actionGroupName_zh_cn: string
  actionGroupName_zh_tw: string
  actionGroupName_en: string
  actionGroupName_th: string
  actionGroupName_vn: string
  actionGroupName: string
  isSelected: boolean
  actionList: actionList[]
}
export type SysUserGroupsActionsConfigEntity = SysUserGroupsActionsConfigEntityItem[]

/**
 * 根据子菜单ID获取对应的权限组，权限项目【系统用户组管理】
 */
export interface SysUserGroupsActionsConfigParams {
  subMenuID: number
  userGroupId: number
}

/**
 * 根据子菜单ID获取对应的权限组，权限项目【系统用户组管理】
 */
export const sysUserGroupsActionsConfig = (
  params: SysUserGroupsActionsConfigParams
) => {
  return client.get<IClientResp<SysUserGroupsActionsConfigEntity>>(
    '/Sys/UserGroupsActionsConfig',
    { params }
  )
}

/**
 * - **通过** `Inject('sysUserGroupsActionsConfigStore')` 依赖注入到组件
 * - **根据子菜单ID获取对应的权限组，权限项目【系统用户组管理】** SysUserGroupsActionsConfigStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysUserGroupsActionsConfigStore: SysUserGroupsActionsConfigStore
		}
		@Inject('sysUserGroupsActionsConfigStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysUserGroupsActionsConfigStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysUserGroupsActionsConfigStore extends AsyncStore {
  @observable
  userGroupsActionsConfigEntity?: SysUserGroupsActionsConfigEntity = undefined
  @action
  async userGroupsActionsConfig(params: SysUserGroupsActionsConfigParams) {
    return this.asyncAction<SysUserGroupsActionsConfigEntity>(
      'userGroupsActionsConfigEntity',
      sysUserGroupsActionsConfig(params)
    )
  }
}

export const sysUserGroupsActionsConfigStore = new SysUserGroupsActionsConfigStore()

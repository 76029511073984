/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取API日志分类【API日志】
 */
export type LogGetApiLogsClassNameEntity = string[]

/**
 * 获取API日志分类【API日志】
 */
export interface LogGetApiLogsClassNameParams {
  selectType?: number
}

/**
 * 获取API日志分类【API日志】
 */
export const logGetApiLogsClassName = (
  params: LogGetApiLogsClassNameParams
) => {
  return client.get<IClientResp<LogGetApiLogsClassNameEntity>>(
    '/Log/GetAPILogsClassName',
    { params }
  )
}

/**
 * - **通过** `Inject('logGetApiLogsClassNameStore')` 依赖注入到组件
 * - **获取API日志分类【API日志】** LogGetApiLogsClassNameStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetApiLogsClassNameStore: LogGetApiLogsClassNameStore
		}
		@Inject('logGetApiLogsClassNameStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetApiLogsClassNameStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetApiLogsClassNameStore extends AsyncStore {
  @observable
  getApiLogsClassNameEntity?: LogGetApiLogsClassNameEntity = undefined
  @action
  async getApiLogsClassName(params: LogGetApiLogsClassNameParams) {
    return this.asyncAction<LogGetApiLogsClassNameEntity>(
      'getApiLogsClassNameEntity',
      logGetApiLogsClassName(params)
    )
  }
}

export const logGetApiLogsClassNameStore = new LogGetApiLogsClassNameStore()

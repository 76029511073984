/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 系统管理员登录日志分页数据【系统管理员登录日志】
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 登录用户ID
   */
  loginUserID: number

  /**
   * 登录时间
   */
  loginDateTime: string

  /**
   * 登录IP
   */
  loginIP: string

  /**
   * 登录MAC
   */
  loginMAC: string

  /**
   * 账号
   */
  ctrlUserName: string

  /**
   * 昵称
   */
  nickName: string
}
export interface LogGetCtrlLoginLogListEntity {
  recondCount: number
  data: data[]
}

/**
 * 系统管理员登录日志分页数据【系统管理员登录日志】
 */
export interface LogGetCtrlLoginLogListParams {
  /**
   * 开始时间(eg:[2017-06-12 12:00:00])
   */
  BeginTime?: string

  /**
   * 结束时间(eg:[2018-06-12 12:00:00])
   */
  EndTime?: string

  /**
   * 登录用户账号
   */
  CtrlUserName?: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 系统管理员登录日志分页数据【系统管理员登录日志】
 */
export const logGetCtrlLoginLogList = (
  params: LogGetCtrlLoginLogListParams
) => {
  return client.get<IClientResp<LogGetCtrlLoginLogListEntity>>(
    '/Log/GetCtrlLoginLogList',
    { params }
  )
}

/**
 * - **通过** `Inject('logGetCtrlLoginLogListStore')` 依赖注入到组件
 * - **系统管理员登录日志分页数据【系统管理员登录日志】** LogGetCtrlLoginLogListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetCtrlLoginLogListStore: LogGetCtrlLoginLogListStore
		}
		@Inject('logGetCtrlLoginLogListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetCtrlLoginLogListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetCtrlLoginLogListStore extends AsyncStore {
  @observable
  getCtrlLoginLogListEntity?: LogGetCtrlLoginLogListEntity = undefined
  @action
  async getCtrlLoginLogList(params: LogGetCtrlLoginLogListParams) {
    return this.asyncAction<LogGetCtrlLoginLogListEntity>(
      'getCtrlLoginLogListEntity',
      logGetCtrlLoginLogList(params)
    )
  }
}

export const logGetCtrlLoginLogListStore = new LogGetCtrlLoginLogListStore()

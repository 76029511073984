import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Layout, Tabs } from 'antd'
import { lang } from 'lang'
import queryString from 'query-string'
import React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { breadcrumbNameMap } from './breadcrumbNameMap'
import { routeEvent } from './route-event'
const { Content } = Layout
const { TabPane } = Tabs
export interface TabPaneRoute {
  title: string
  key: string
  path: string
  content: React.ReactNode
  location: any
}

export interface TabRouteState {
  activeKey: string
  keys: string[]
  path: string
}

export interface CachedRoute {
  [key: string]: TabPaneRoute
}

export interface TabRouteProps extends RouteComponentProps {
  cacheRoutes: CachedRoute
  location: any
  history: any
  activeKey: string
  onRemove: (key: string) => void
}

class TabRoute extends React.Component<TabRouteProps, TabRouteState> {
  state: TabRouteState = {
    activeKey: '/app',
    keys: ['/app'],
    path: '/app'
  }
  cacheKeys = {}
  componentDidMount() {
    const { activeKey, cacheRoutes } = this.props
    this.setState({
      activeKey,
      keys: Object.keys(cacheRoutes)
    })
    this.props.history.listen((location, action) => {
      this.setState({ path: this.cacheKeys[location.pathname] })
    })
  }
  static getDerivedStateFromProps(props: TabRouteProps, state: TabRouteState) {
    const { cacheRoutes, location } = props
    const { pathname } = location
    if (pathname !== state.path) {
      const keys = Object.keys(cacheRoutes)
      const activeKey = keys.find(k => cacheRoutes[k].path === pathname)
      return {
        keys,
        activeKey,
        path: pathname
      }
    }

    return null
  }
  onChange = activeKey => {
    this.setState({ activeKey })
  }
  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }
  remove = key => {
    const { onRemove, history } = this.props
    const index = this.state.keys.indexOf(key)
    if (index === 1) {
      history.push('/app')
    }

    this.setState(
      prevState => ({
        keys: prevState.keys.filter(k => k !== key)
      }),
      () => {
        this.setState({
          activeKey: this.state.keys[this.state.keys.length - 1]
        })
      }
    )
    onRemove(key)
  }
  goStaticIndex = k => {
    const { history, cacheRoutes } = this.props
    const cache = cacheRoutes[k]
    this.cacheKeys[cache.path] = k
    if (k === '/app') {
      if (!cache.content) {
        history.push('/app')
      } else {
        this.setState({
          activeKey: '/app'
        })
      }
    }
    routeEvent.emit(`${cache.key}:${cache.path}`)
  }

  render() {
    const { activeKey, keys } = this.state
    const { cacheRoutes } = this.props
    const tr = cacheRoutes[activeKey]
    const breadcrumbItems = tr ? this.getBreadcrumbs(tr.location) : null

    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 52,
            left: 22,
            zIndex: 3
          }}
        >
          <Breadcrumb style={{ marginBottom: '12px' }}>
            {breadcrumbItems}
          </Breadcrumb>
        </div>
        <Tabs
          onChange={this.onChange}
          onEdit={this.onEdit}
          activeKey={activeKey}
          type="editable-card"
          onTabClick={this.goStaticIndex}
          hideAdd
        >
          {keys.map((k, index) => {
            return (
              <TabPane
                tab={cacheRoutes[k].title}
                key={cacheRoutes[k].key}
                closable={index !== 0}
              >
                <Content
                  style={{
                    margin: '24px 24px 0'
                  }}
                >
                  {cacheRoutes[k].content}
                </Content>
              </TabPane>
            )
          })}
        </Tabs>
      </div>
    )
  }
  getBreadcrumbs(location) {
    if (!location) {
      return null
    }
    const pathSnippets = location.pathname.split('/').filter(i => i)
    const qs = queryString.parse(location.search)
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      const b = breadcrumbNameMap[url]
      const title = lang.messages[url]
      if (!b) {
        return null
      }
      return (
        <Breadcrumb.Item key={url}>
          {b.hasUrl ? (
            url === '/app' ? (
              <span
                onClick={() => this.goStaticIndex(url)}
                style={{ cursor: 'pointer' }}
              >
                <HomeOutlined style={{ marginRight: '8px' }} />
                {title}
              </span>
            ) : (
              <Link to={url}>{title}</Link>
            )
          ) : (
            <span>{title}</span>
          )}
        </Breadcrumb.Item>
      )
    })
    const breadcrumbItems = extraBreadcrumbItems.filter((e: null) => e !== null)
    if (qs.title) {
      breadcrumbItems.push(
        <Breadcrumb.Item key={'un_url'}>
          <span>{qs.title}</span>
        </Breadcrumb.Item>
      )
    }

    return breadcrumbItems
  }
}

export default withRouter(TabRoute) as any

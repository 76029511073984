/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 编辑用户组状态【系统用户组管理】
 */
export interface SysModifyUserGroupStateEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 编辑用户组状态【系统用户组管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface SysModifyUserGroupStateParams {
  /**
   * 用户组ID
   */
  id: number

  /**
   * 状态
   */
  state: boolean
}

/**
 * 编辑用户组状态【系统用户组管理】
 */
export const sysModifyUserGroupState = (
  params: SysModifyUserGroupStateParams
) => {
  return client.post<IClientResp<SysModifyUserGroupStateEntity>>(
    '/Sys/ModifyUserGroupState',
    params
  )
}

/**
 * - **通过** `Inject('sysModifyUserGroupStateStore')` 依赖注入到组件
 * - **编辑用户组状态【系统用户组管理】** SysModifyUserGroupStateStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysModifyUserGroupStateStore: SysModifyUserGroupStateStore
		}
		@Inject('sysModifyUserGroupStateStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysModifyUserGroupStateStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysModifyUserGroupStateStore extends AsyncStore {
  @observable
  modifyUserGroupStateEntity?: SysModifyUserGroupStateEntity = undefined
  @action
  async modifyUserGroupState(params: SysModifyUserGroupStateParams) {
    return this.asyncAction<SysModifyUserGroupStateEntity>(
      'modifyUserGroupStateEntity',
      sysModifyUserGroupState(params)
    )
  }
}

export const sysModifyUserGroupStateStore = new SysModifyUserGroupStateStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取错误日志分类【错误日志】
 */
export type LogGetErrorLogsClassNameEntity = string[]

/**
 * 获取错误日志分类【错误日志】
 */
export const logGetErrorLogsClassName = () => {
  return client.get<IClientResp<LogGetErrorLogsClassNameEntity>>(
    '/Log/GetErrorLogsClassName'
  )
}

/**
 * - **通过** `Inject('logGetErrorLogsClassNameStore')` 依赖注入到组件
 * - **获取错误日志分类【错误日志】** LogGetErrorLogsClassNameStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetErrorLogsClassNameStore: LogGetErrorLogsClassNameStore
		}
		@Inject('logGetErrorLogsClassNameStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetErrorLogsClassNameStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetErrorLogsClassNameStore extends AsyncStore {
  @observable
  getErrorLogsClassNameEntity?: LogGetErrorLogsClassNameEntity = undefined
  @action
  async getErrorLogsClassName() {
    return this.asyncAction<LogGetErrorLogsClassNameEntity>(
      'getErrorLogsClassNameEntity',
      logGetErrorLogsClassName()
    )
  }
}

export const logGetErrorLogsClassNameStore = new LogGetErrorLogsClassNameStore()

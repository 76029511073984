import { Layout } from 'antd'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import logo from 'routes/App/logo.png'
import { HomeGetMenuEntity } from 'services/home/get_menu'
import { storage } from 'utils/config'
import BaseMenu from './BaseMenu'
import styles from './index.module.css'

const { Sider } = Layout

export interface SiderMenuProps {
  data: HomeGetMenuEntity | undefined
  collapsed: boolean
  isMobile: boolean
  onCollapse: any
}

class SiderMenu extends Component<SiderMenuProps, any> {
  render() {
    const { collapsed } = this.props
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        width={localStorage.getItem(storage.locale) === 'th' ? 265 : 256}
        theme="dark"
        className={styles.sider}
      >
        <div className={styles.logo} id="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
            <h1>
              <FormattedMessage id="title" defaultMessage="泰国彩后台" />
            </h1>
          </Link>
        </div>
        <BaseMenu {...this.props} />
      </Sider>
    )
  }
}

export default SiderMenu

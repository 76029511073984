/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 权限组列表【权限组管理】
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 菜单项ID
   */
  menuID: number

  /**
   * 所属分类(控制器名称)
   */
  controllerName: string

  /**
   * 是否自动选中（1--自动选中 0--不选中）
   */
  autoSelected: boolean

  /**
   * 排序码
   */
  orderNo: number

  /**
   * 备注
   */
  remark: string

  /**
   * 权限分组名称(简体)
   */
  actionGroupName_zh_cn: string

  /**
   * 权限分组名称(繁体)
   */
  actionGroupName_zh_tw: string

  /**
   * 权限分组名称(英文)
   */
  actionGroupName_en: string

  /**
   * 权限分组名称(越南文)
   */
  actionGroupName_vn: string

  /**
   * 权限分组名称(泰文)
   */
  actionGroupName_th: string
}
export interface MenuGetActionGroupsListEntity {
  recondCount: number
  data: data[]
}

/**
 * 权限组列表【权限组管理】
 */
export interface MenuGetActionGroupsListParams {
  actionGroupName?: string
  controllerName?: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 权限组列表【权限组管理】
 */
export const menuGetActionGroupsList = (
  params: MenuGetActionGroupsListParams
) => {
  return client.get<IClientResp<MenuGetActionGroupsListEntity>>(
    '/Menu/GetActionGroupsList',
    { params }
  )
}

/**
 * - **通过** `Inject('menuGetActionGroupsListStore')` 依赖注入到组件
 * - **权限组列表【权限组管理】** MenuGetActionGroupsListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetActionGroupsListStore: MenuGetActionGroupsListStore
		}
		@Inject('menuGetActionGroupsListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetActionGroupsListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetActionGroupsListStore extends AsyncStore {
  @observable
  getActionGroupsListEntity?: MenuGetActionGroupsListEntity = undefined
  @action
  async getActionGroupsList(params: MenuGetActionGroupsListParams) {
    return this.asyncAction<MenuGetActionGroupsListEntity>(
      'getActionGroupsListEntity',
      menuGetActionGroupsList(params)
    )
  }
}

export const menuGetActionGroupsListStore = new MenuGetActionGroupsListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取某期的开奖信息
 */
export interface historyList {
  /**
   * ID
   */
  id: number

  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 彩票期数
   */
  lottoNo: string

  /**
   * 开奖号码列表，开奖结果多个，以逗号隔开，具体表示请看程序说明
   */
  openResult: string

  /**
   * 开盘时间
   */
  openPanDateTime: string

  /**
   * 开奖日期
   */
  openDate: string

  /**
   * 开奖时间
   */
  openResultTime: string

  /**
   * 账务时间
   */
  accountDateTime: string

  /**
   * 结算状态(0--未结算  1--结算中 2--已结算  3--反结算状态  4--重新结算状态 5--无效结算状态)
   */
  countState: number

  /**
   * 开始结算时间
   */
  startCountDateTime: string

  /**
   * 结算完成时间
   */
  endCountDateTime: string

  /**
   * 备注
   */
  remark: string

  /**
   * 是否采集后自动结算
   */
  isAuto: boolean
}
export interface OpenManageGetHistoryEntity {
  /**
   * 前10期的期数列表
   */
  lottoNoList: string[]

  /**
   * 当前开奖期数信息
   */
  historyList: historyList[]

  /**
   * 是否为超级管理员
   */
  isSuperAdmin: boolean
}

/**
 * 获取某期的开奖信息
 */
export interface OpenManageGetHistoryParams {
  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 期号查询
   */
  lottoNo: string
}

/**
 * 获取某期的开奖信息
 */
export const openManageGetHistory = (params: OpenManageGetHistoryParams) => {
  return client.post<IClientResp<OpenManageGetHistoryEntity>>(
    '/OpenManage/GetHistory',
    params
  )
}

/**
 * - **通过** `Inject('openManageGetHistoryStore')` 依赖注入到组件
 * - **获取某期的开奖信息** OpenManageGetHistoryStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			openManageGetHistoryStore: OpenManageGetHistoryStore
		}
		@Inject('openManageGetHistoryStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			openManageGetHistoryStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class OpenManageGetHistoryStore extends AsyncStore {
  @observable getHistoryEntity?: OpenManageGetHistoryEntity = undefined
  @action
  async getHistory(params: OpenManageGetHistoryParams) {
    return this.asyncAction<OpenManageGetHistoryEntity>(
      'getHistoryEntity',
      openManageGetHistory(params)
    )
  }
}

export const openManageGetHistoryStore = new OpenManageGetHistoryStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改配置【彩种启停】
 */
/**
 * 修改配置
 */
export interface LottosettingUpdateConfigEntity {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 开盘时间
   */
  startDateTime: string

  /**
   * 提前封盘时间(分钟)
   */
  beforeCloseTime: number
}

/**
 * 修改配置【彩种启停】
 */
export interface LottosettingUpdateConfigParams {
  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 开盘时间
   */
  startDateTime: string

  /**
   * 提前封盘时间(分钟)
   */
  beforeCloseTime: number
}

/**
 * 修改配置【彩种启停】
 */
export const lottosettingUpdateConfig = (
  params: LottosettingUpdateConfigParams
) => {
  return client.post<IClientResp<LottosettingUpdateConfigEntity>>(
    '/lottosetting/updateConfig',
    params
  )
}

/**
 * - **通过** `Inject('lottosettingUpdateConfigStore')` 依赖注入到组件
 * - **修改配置【彩种启停】** LottosettingUpdateConfigStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingUpdateConfigStore: LottosettingUpdateConfigStore
		}
		@Inject('lottosettingUpdateConfigStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingUpdateConfigStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingUpdateConfigStore extends AsyncStore {
  @observable updateConfigEntity?: LottosettingUpdateConfigEntity = undefined
  @action
  async updateConfig(params: LottosettingUpdateConfigParams) {
    return this.asyncAction<LottosettingUpdateConfigEntity>(
      'updateConfigEntity',
      lottosettingUpdateConfig(params)
    )
  }
}

export const lottosettingUpdateConfigStore = new LottosettingUpdateConfigStore()

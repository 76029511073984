import { Card } from 'antd'
import * as React from 'react'
import Content from './Content'
import Header from './Header'

declare interface PageProps extends React.HTMLProps<HTMLDivElement> {}
declare interface PageState {}

class Page extends React.Component<PageProps, PageState> {
  /**
   * config header area
   *
   * @static
   *
   * @memberOf Page
   */
  static Header = Header

  /**
   * content area
   *
   * @static
   *
   * @memberOf Page
   */
  static Content = Content

  render() {
    return <Card bordered={false}>{this.props.children}</Card>
  }
}

export default Page

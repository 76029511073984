/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 配置权限项【权限组管理】
 */
export interface MenuActionBindActionGroupEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 配置权限项【权限组管理】
 */
/**
 * 返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
 */
export interface MenuActionBindActionGroupParams {
  /**
   * 权限组ID
   */
  actionGroupID: number

  /**
   * 勾选的权限项ID,多个以[,]分隔
   */
  actionIDs: string
}

/**
 * 配置权限项【权限组管理】
 */
export const menuActionBindActionGroup = (
  params: MenuActionBindActionGroupParams
) => {
  return client.post<IClientResp<MenuActionBindActionGroupEntity>>(
    '/Menu/ActionBindActionGroup',
    params
  )
}

/**
 * - **通过** `Inject('menuActionBindActionGroupStore')` 依赖注入到组件
 * - **配置权限项【权限组管理】** MenuActionBindActionGroupStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuActionBindActionGroupStore: MenuActionBindActionGroupStore
		}
		@Inject('menuActionBindActionGroupStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuActionBindActionGroupStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuActionBindActionGroupStore extends AsyncStore {
  @observable
  actionBindActionGroupEntity?: MenuActionBindActionGroupEntity = undefined
  @action
  async actionBindActionGroup(params: MenuActionBindActionGroupParams) {
    return this.asyncAction<MenuActionBindActionGroupEntity>(
      'actionBindActionGroupEntity',
      menuActionBindActionGroup(params)
    )
  }
}

export const menuActionBindActionGroupStore = new MenuActionBindActionGroupStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 操作日志分页数据【操作日志】
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 厂商名称
   */
  venderName: string

  /**
   * 分站编码
   */
  siteNo: string

  /**
   * 操作者账号
   */
  operateUserName: string

  /**
   * 操作者昵称
   */
  operateNickName: string

  /**
   * 操作者用户类型(0--系统管理员 1--厂商管理账号 2-- 子厂商管理账号  3--分站账号)
   */
  operatorUserType: string

  /**
   * 操作命令ID
   */
  operateCommandID: number

  /**
   * 操作时间
   */
  operateDateTime: string

  /**
   * 操作内容
   */
  operateContent: string

  /**
   * 被操作者账号
   */
  beOperateUserName: string

  /**
   * 被操作者昵称
   */
  beOperateNickName: string

  /**
   * 操作者IP
   */
  operatorIP: string

  /**
   * 分站名称
   */
  siteName: string

  /**
   * 操作者类型名称
   */
  operatorUserTypeName: string

  /**
   * 操作命令名称
   */
  operateCommandName: string
}
export interface LogGetOperateLogListEntity {
  recondCount: number
  data: data[]
}

/**
 * 操作日志分页数据【操作日志】
 */
export interface LogGetOperateLogListParams {
  /**
   * 开始时间(eg:[2017-06-12 12:00:00])
   */
  BeginTime?: string

  /**
   * 结束时间(eg:[2018-06-12 12:00:00])
   */
  EndTime?: string

  /**
   * 用户类型
   */
  UserType?: string

  /**
   * 操作者账号/昵称
   */
  OperateUserName?: string

  /**
   * 被操作者账号/昵称
   */
  BeOperateUserName?: string

  /**
   * 操作命令ID
   */
  OperateCommandID?: number

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 操作日志分页数据【操作日志】
 */
export const logGetOperateLogList = (params: LogGetOperateLogListParams) => {
  return client.get<IClientResp<LogGetOperateLogListEntity>>(
    '/Log/GetOperateLogList',
    { params }
  )
}

/**
 * - **通过** `Inject('logGetOperateLogListStore')` 依赖注入到组件
 * - **操作日志分页数据【操作日志】** LogGetOperateLogListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetOperateLogListStore: LogGetOperateLogListStore
		}
		@Inject('logGetOperateLogListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetOperateLogListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetOperateLogListStore extends AsyncStore {
  @observable getOperateLogListEntity?: LogGetOperateLogListEntity = undefined
  @action
  async getOperateLogList(params: LogGetOperateLogListParams) {
    return this.asyncAction<LogGetOperateLogListEntity>(
      'getOperateLogListEntity',
      logGetOperateLogList(params)
    )
  }
}

export const logGetOperateLogListStore = new LogGetOperateLogListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 修改操作指令【操作指令管理】
 */
export interface SysOperateCommandModifyEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 修改操作指令【操作指令管理】
 */
export interface SysOperateCommandModifyParams {
  /**
   * ID(手动输入1至10000之间的整数,eg:[1])
   */
  id: number

  /**
   * 分类名称(分类名称长度不能超过30个字符,eg:[UserGroup])
   */
  className: string

  /**
   * 命令名称(格式：[分类名称_命令名称] eg: [User_AddUser])
   */
  keyName: string

  /**
   * 登录用户类型(0--系统管理员 1--厂商管理账号 2-- 子厂商管理账号  3--分站账号 )eg:[0]
   */
  userType: string

  /**
   * 备注(长度不能超过50个字符)eg:[用户组添加]
   */
  remark: string

  /**
   * 操作命令名称(简体)(长度不能超过30个字符)eg:[用户组添加]
   */
  commandName_zh_cn: string

  /**
   * 操作命令名称(繁体)(长度不能超过30个字符)eg:[用戶組添加]
   */
  commandName_zh_tw: string

  /**
   * 操作命令名称(英文)(长度不能超过30个字符)eg:[UserGroup Add]
   */
  commandName_en: string

  /**
   * 操作命令名称(越南文)(长度不能超过30个字符)eg:[UserGroup Add]
   */
  commandName_vn: string

  /**
   * 操作命令名称(泰文)(长度不能超过30个字符)eg:[UserGroup Add]
   */
  commandName_th: string
}

/**
 * 修改操作指令【操作指令管理】
 */
export const sysOperateCommandModify = (
  params: SysOperateCommandModifyParams
) => {
  return client.post<IClientResp<SysOperateCommandModifyEntity>>(
    '/Sys/OperateCommandModify',
    params
  )
}

/**
 * - **通过** `Inject('sysOperateCommandModifyStore')` 依赖注入到组件
 * - **修改操作指令【操作指令管理】** SysOperateCommandModifyStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysOperateCommandModifyStore: SysOperateCommandModifyStore
		}
		@Inject('sysOperateCommandModifyStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysOperateCommandModifyStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysOperateCommandModifyStore extends AsyncStore {
  @observable
  operateCommandModifyEntity?: SysOperateCommandModifyEntity = undefined
  @action
  async operateCommandModify(params: SysOperateCommandModifyParams) {
    return this.asyncAction<SysOperateCommandModifyEntity>(
      'operateCommandModifyEntity',
      sysOperateCommandModify(params)
    )
  }
}

export const sysOperateCommandModifyStore = new SysOperateCommandModifyStore()

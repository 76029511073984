/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 当前账务明细【当前账务】
 */
export interface data {
  /**
   * 会员ID
   */
  lottoMemberID: number

  /**
   * 会员名称
   */
  memberName: string

  /**
   * 注单编号
   */
  betNo: number

  /**
   * 注数
   */
  betCount: number

  /**
   * 下注时间
   */
  betDateTime: string

  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 彩种名称
   */
  lottoName: string

  /**
   * 玩法ID
   */
  playTypeID: number

  /**
   * 玩法名称
   */
  playTypeName: string

  /**
   * 期号
   */
  lottoNo: string

  /**
   * 下注内容
   */
  betContent: string

  /**
   * 赔率
   */
  odds: number

  /**
   * 下注金额
   */
  betMoney: number

  /**
   * 总下注金额
   */
  totalBetMoney: number

  /**
   * 有效下注金额
   */
  validBetMoney: number

  /**
   * 会员退水金额
   */
  backMoney: number

  /**
   * 会员输赢
   */
  winLoseMoney: number

  /**
   * 状态(0--已完成  1--已改单  2--无效注单  3--反结算  4--已撤单)
   */
  state: number
}

/**
 * 后台返回给前端的报表明细结构
 */
export interface ReportsGetDetailEntity {
  recondCount: number
  data: data[]
  countData: {
    /**
     * 会员ID
     */
    lottoMemberID: number

    /**
     * 会员名称
     */
    memberName: string

    /**
     * 注单编号
     */
    betNo: number

    /**
     * 注数
     */
    betCount: number

    /**
     * 下注时间
     */
    betDateTime: string

    /**
     * 彩种ID
     */
    lottoID: number

    /**
     * 彩种名称
     */
    lottoName: string

    /**
     * 玩法ID
     */
    playTypeID: number

    /**
     * 玩法名称
     */
    playTypeName: string

    /**
     * 期号
     */
    lottoNo: string

    /**
     * 下注内容
     */
    betContent: string

    /**
     * 赔率
     */
    odds: number

    /**
     * 下注金额
     */
    betMoney: number

    /**
     * 总下注金额
     */
    totalBetMoney: number

    /**
     * 有效下注金额
     */
    validBetMoney: number

    /**
     * 会员退水金额
     */
    backMoney: number

    /**
     * 会员输赢
     */
    winLoseMoney: number

    /**
     * 状态(0--已完成  1--已改单  2--无效注单  3--反结算  4--已撤单)
     */
    state: number
  }
}

/**
 * 当前账务明细【当前账务】
 */
export interface ReportsGetDetailParams {
  /**
   * 查询类型[0:按照日期;1:按照期数]
   */
  TypeModel: number

  /**
   * 报表类型[0:交收报表;1:分类报表]
   */
  ReportType: number

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[0])
   */
  PageIndex: number

  /**
   * 开始时间
   */
  StartDateTime: string

  /**
   * 结束时间
   */
  EndDateTime: string

  /**
   * 子系统会员编号
   */
  LottoMemberID: number

  /**
   * 游戏ID
   */
  LottoID: number

  /**
   * 玩法ID
   */
  PlayTypeID?: number

  /**
   * 期数
   */
  LottoNo?: string
}

/**
 * 当前账务明细【当前账务】
 */
export const reportsGetDetail = (params: ReportsGetDetailParams) => {
  return client.get<IClientResp<ReportsGetDetailEntity>>('/Reports/GetDetail', {
    params
  })
}

/**
 * - **通过** `Inject('reportsGetDetailStore')` 依赖注入到组件
 * - **当前账务明细【当前账务】** ReportsGetDetailStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			reportsGetDetailStore: ReportsGetDetailStore
		}
		@Inject('reportsGetDetailStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			reportsGetDetailStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ReportsGetDetailStore extends AsyncStore {
  @observable getDetailEntity?: ReportsGetDetailEntity = undefined
  @action
  async getDetail(params: ReportsGetDetailParams) {
    return this.asyncAction<ReportsGetDetailEntity>(
      'getDetailEntity',
      reportsGetDetail(params)
    )
  }
}

export const reportsGetDetailStore = new ReportsGetDetailStore()

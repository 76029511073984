/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 编辑新增菜单所属菜单 下拉框【菜单管理】
 */
export interface MenuGetMenusPidListEntityItem {
  /**
   * ID
   */
  id: number

  /**
   * 菜单项名称(简体)
   */
  menuName_zh_cn: string

  /**
   * 菜单项名称(繁体)
   */
  menuName_zh_tw: string

  /**
   * 菜单项名称(英文)
   */
  menuName_en: string

  /**
   * 菜单项名称(越南文)
   */
  menuName_vn: string

  /**
   * 菜单项名称(泰文)
   */
  menuName_th: string
  menuName: string
}
export type MenuGetMenusPidListEntity = MenuGetMenusPidListEntityItem[]

/**
 * 编辑新增菜单所属菜单 下拉框【菜单管理】
 */
export const menuGetMenusPidList = () => {
  return client.get<IClientResp<MenuGetMenusPidListEntity>>(
    '/Menu/GetMenusPidList'
  )
}

/**
 * - **通过** `Inject('menuGetMenusPidListStore')` 依赖注入到组件
 * - **编辑新增菜单所属菜单 下拉框【菜单管理】** MenuGetMenusPidListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetMenusPidListStore: MenuGetMenusPidListStore
		}
		@Inject('menuGetMenusPidListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetMenusPidListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetMenusPidListStore extends AsyncStore {
  @observable getMenusPidListEntity?: MenuGetMenusPidListEntity = undefined
  @action
  async getMenusPidList() {
    return this.asyncAction<MenuGetMenusPidListEntity>(
      'getMenusPidListEntity',
      menuGetMenusPidList()
    )
  }
}

export const menuGetMenusPidListStore = new MenuGetMenusPidListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取该权限组已绑定以及未绑定的权限项【权限组管理】
 */
export interface actions {
  /**
   * 权限项ID
   */
  id: number

  /**
   * 权限名
   */
  actionName_zh_cn: string

  /**
   * 权限名
   */
  actionName_zh_tw: string

  /**
   * 权限名
   */
  actionName_en: string

  /**
   * 权限名
   */
  actionName_th: string

  /**
   * 权限名
   */
  actionName_vn: string

  /**
   * 是否选中
   */
  isSelected: boolean
  actionName: string
}
export interface MenuGetBindActionsEntityItem {
  /**
   * 是否选中
   */
  isSelected: boolean

  /**
   * 权限项列表
   */
  actions: actions[]

  /**
   * 控制器名称（用于权限组和权限项的分类）
   */
  controllerName: string

  /**
   * 控制器的别称（用中文描述）
   */
  anotherName_zh_cn: string
  anotherName_zh_tw: string
  anotherName_en: string
  anotherName_th: string
  anotherName_vn: string

  /**
   * 彩种名称
   */
  anotherName: string
}
export type MenuGetBindActionsEntity = MenuGetBindActionsEntityItem[]

/**
 * 获取该权限组已绑定以及未绑定的权限项【权限组管理】
 */
export interface MenuGetBindActionsParams {
  /**
   * 权限组id
   */
  actionGroupID: number
}

/**
 * 获取该权限组已绑定以及未绑定的权限项【权限组管理】
 */
export const menuGetBindActions = (params: MenuGetBindActionsParams) => {
  return client.get<IClientResp<MenuGetBindActionsEntity>>(
    '/Menu/GetBindActions',
    { params }
  )
}

/**
 * - **通过** `Inject('menuGetBindActionsStore')` 依赖注入到组件
 * - **获取该权限组已绑定以及未绑定的权限项【权限组管理】** MenuGetBindActionsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetBindActionsStore: MenuGetBindActionsStore
		}
		@Inject('menuGetBindActionsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetBindActionsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetBindActionsStore extends AsyncStore {
  @observable getBindActionsEntity?: MenuGetBindActionsEntity = undefined
  @action
  async getBindActions(params: MenuGetBindActionsParams) {
    return this.asyncAction<MenuGetBindActionsEntity>(
      'getBindActionsEntity',
      menuGetBindActions(params)
    )
  }
}

export const menuGetBindActionsStore = new MenuGetBindActionsStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取分站指定时间段的开始时间和结束时间[用于账务报表时间快捷选]
 */
export interface ReportsGetDateTimeEntityItem {
  /**
   * 开始时间
   */
  startDateTime: string

  /**
   * 结束时间
   */
  endDateTime: string
}
export type ReportsGetDateTimeEntity = ReportsGetDateTimeEntityItem[]

/**
 * 获取分站指定时间段的开始时间和结束时间[用于账务报表时间快捷选]
 */
export const reportsGetDateTime = () => {
  return client.get<IClientResp<ReportsGetDateTimeEntity>>(
    '/Reports/GetDateTime'
  )
}

/**
 * - **通过** `Inject('reportsGetDateTimeStore')` 依赖注入到组件
 * - **获取分站指定时间段的开始时间和结束时间[用于账务报表时间快捷选]** ReportsGetDateTimeStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			reportsGetDateTimeStore: ReportsGetDateTimeStore
		}
		@Inject('reportsGetDateTimeStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			reportsGetDateTimeStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ReportsGetDateTimeStore extends AsyncStore {
  @observable getDateTimeEntity?: ReportsGetDateTimeEntity = undefined
  @action
  async getDateTime() {
    return this.asyncAction<ReportsGetDateTimeEntity>(
      'getDateTimeEntity',
      reportsGetDateTime()
    )
  }
}

export const reportsGetDateTimeStore = new ReportsGetDateTimeStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取厂商列表
 */
export interface HomeGetVenderListEntityItem {
  /**
   * 厂商名称
   */
  venderName: string

  /**
   * 厂商昵称
   */
  nickName: string
}
export type HomeGetVenderListEntity = HomeGetVenderListEntityItem[]

/**
 * 获取厂商列表
 */
export const homeGetVenderList = () => {
  return client.get<IClientResp<HomeGetVenderListEntity>>('/Home/GetVenderList')
}

/**
 * - **通过** `Inject('homeGetVenderListStore')` 依赖注入到组件
 * - **获取厂商列表** HomeGetVenderListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			homeGetVenderListStore: HomeGetVenderListStore
		}
		@Inject('homeGetVenderListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			homeGetVenderListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class HomeGetVenderListStore extends AsyncStore {
  @observable getVenderListEntity?: HomeGetVenderListEntity = undefined
  @action
  async getVenderList() {
    return this.asyncAction<HomeGetVenderListEntity>(
      'getVenderListEntity',
      homeGetVenderList()
    )
  }
}

export const homeGetVenderListStore = new HomeGetVenderListStore()

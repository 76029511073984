/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 菜单列表【菜单管理】
 */
export interface data {
  /**
   * 菜单ID
   */
  id: number

  /**
   * 菜单层级(1--表示一级菜单)
   */
  menuLayer: number

  /**
   * 父级菜单ID
   */
  pid: number

  /**
   * 排序码
   */
  orderNo: number

  /**
   * 菜单对应的网址
   */
  menuUrl: string

  /**
   * 标签页名称（用于固定在某一个标签页显示）
   */
  tabName: string

  /**
   * 是否显示(0--不显示 1--显示)
   */
  display: boolean

  /**
   * 是否覆盖左边菜单栏(0--不覆盖 1--覆盖)
   */
  covered: boolean

  /**
   * 页面呈现方式(0--当前窗口  1--新窗口
   */
  pageDisplay: number

  /**
   * 备注
   */
  remark: string

  /**
   * 二级菜单小图标
   */
  icon: string

  /**
   * 菜单项名称(简体)
   */
  menuName_zh_cn: string

  /**
   * 菜单项名称(繁体)
   */
  menuName_zh_tw: string

  /**
   * 菜单项名称(英文)
   */
  menuName_en: string

  /**
   * 菜单项名称(越南文)
   */
  menuName_vn: string

  /**
   * 菜单项名称(泰文)
   */
  menuName_th: string

  /**
   * 子菜单数量
   */
  childCount: number
}
export interface MenuGetMenuListEntity {
  recondCount: number
  data: data[]
}

/**
 * 菜单列表【菜单管理】
 */
export interface MenuGetMenuListParams {
  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
  pid?: number
}

/**
 * 菜单列表【菜单管理】
 */
export const menuGetMenuList = (params: MenuGetMenuListParams) => {
  return client.get<IClientResp<MenuGetMenuListEntity>>('/Menu/GetMenuList', {
    params
  })
}

/**
 * - **通过** `Inject('menuGetMenuListStore')` 依赖注入到组件
 * - **菜单列表【菜单管理】** MenuGetMenuListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetMenuListStore: MenuGetMenuListStore
		}
		@Inject('menuGetMenuListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetMenuListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetMenuListStore extends AsyncStore {
  @observable getMenuListEntity?: MenuGetMenuListEntity = undefined
  @action
  async getMenuList(params: MenuGetMenuListParams) {
    return this.asyncAction<MenuGetMenuListEntity>(
      'getMenuListEntity',
      menuGetMenuList(params)
    )
  }
}

export const menuGetMenuListStore = new MenuGetMenuListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 返回基础的彩种列表
 */
export interface BasicInfoGetLottoListEntityItem {
  id: number

  /**
   * 排序码
   */
  orderNo: string

  /**
   * 彩种名称
   */
  name: string
}
export type BasicInfoGetLottoListEntity = BasicInfoGetLottoListEntityItem[]

/**
 * 返回基础的彩种列表
 */
export const basicInfoGetLottoList = () => {
  return client.post<IClientResp<BasicInfoGetLottoListEntity>>(
    '/BasicInfo/GetLottoList'
  )
}

/**
 * - **通过** `Inject('basicInfoGetLottoListStore')` 依赖注入到组件
 * - **返回基础的彩种列表** BasicInfoGetLottoListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			basicInfoGetLottoListStore: BasicInfoGetLottoListStore
		}
		@Inject('basicInfoGetLottoListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			basicInfoGetLottoListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class BasicInfoGetLottoListStore extends AsyncStore {
  @observable getLottoListEntity?: BasicInfoGetLottoListEntity = undefined
  @action
  async getLottoList() {
    return this.asyncAction<BasicInfoGetLottoListEntity>(
      'getLottoListEntity',
      basicInfoGetLottoList()
    )
  }
}

export const basicInfoGetLottoListStore = new BasicInfoGetLottoListStore()

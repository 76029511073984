/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 多语言删除【多语言管理】
 */
export interface ConfigLanguageDeleteEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 多语言删除【多语言管理】
 */
export interface ConfigLanguageDeleteParams {
  /**
   * 类名/ViewName...
   */
  className: string

  /**
   * 属性名称
   */
  propertyName: string
}

/**
 * 多语言删除【多语言管理】
 */
export const configLanguageDelete = (params: ConfigLanguageDeleteParams) => {
  return client.post<IClientResp<ConfigLanguageDeleteEntity>>(
    '/Config/LanguageDelete',
    params
  )
}

/**
 * - **通过** `Inject('configLanguageDeleteStore')` 依赖注入到组件
 * - **多语言删除【多语言管理】** ConfigLanguageDeleteStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			configLanguageDeleteStore: ConfigLanguageDeleteStore
		}
		@Inject('configLanguageDeleteStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			configLanguageDeleteStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ConfigLanguageDeleteStore extends AsyncStore {
  @observable languageDeleteEntity?: ConfigLanguageDeleteEntity = undefined
  @action
  async languageDelete(params: ConfigLanguageDeleteParams) {
    return this.asyncAction<ConfigLanguageDeleteEntity>(
      'languageDeleteEntity',
      configLanguageDelete(params)
    )
  }
}

export const configLanguageDeleteStore = new ConfigLanguageDeleteStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 系统用户绑定IP白名单，IP黑名单【系统用户管理】
 */
export interface SysCtrlUserBindIpEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 系统用户绑定IP白名单，IP黑名单【系统用户管理】
 */
/**
 * 返回值 code=0 执行成功 ，code = 1 IP不合法:具体IP， code = 2 执行失败 ，&gt;=10000 其他系统错误
 */
export interface SysCtrlUserBindIpParams {
  /**
   * 用户ID
   */
  id: number

  /**
   * 白名单列表（多个使用[,]分隔）
   */
  bindIPList: string

  /**
   * 黑名单列表（多个使用[,]分隔）
   */
  blackIPList: string
}

/**
 * 系统用户绑定IP白名单，IP黑名单【系统用户管理】
 */
export const sysCtrlUserBindIp = (params: SysCtrlUserBindIpParams) => {
  return client.post<IClientResp<SysCtrlUserBindIpEntity>>(
    '/Sys/CtrlUserBindIP',
    params
  )
}

/**
 * - **通过** `Inject('sysCtrlUserBindIpStore')` 依赖注入到组件
 * - **系统用户绑定IP白名单，IP黑名单【系统用户管理】** SysCtrlUserBindIpStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysCtrlUserBindIpStore: SysCtrlUserBindIpStore
		}
		@Inject('sysCtrlUserBindIpStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysCtrlUserBindIpStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysCtrlUserBindIpStore extends AsyncStore {
  @observable ctrlUserBindIpEntity?: SysCtrlUserBindIpEntity = undefined
  @action
  async ctrlUserBindIp(params: SysCtrlUserBindIpParams) {
    return this.asyncAction<SysCtrlUserBindIpEntity>(
      'ctrlUserBindIpEntity',
      sysCtrlUserBindIp(params)
    )
  }
}

export const sysCtrlUserBindIpStore = new SysCtrlUserBindIpStore()

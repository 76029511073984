/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取分站赔率变动列表 by felix
 */
export interface LottosettingGetChangeOddsEntityItem {
  /**
   * 玩法名称
   */
  playTypeName: string

  /**
   * 有效期
   */
  expiration: string
  id: number
  venderName: string
  siteNo: string

  /**
   * 彩种ID
   */
  lottoID: number

  /**
   * 期号
   */
  lottoNo: string

  /**
   * 玩法ID
   */
  lottoPlayTypeID: number

  /**
   * 指定号码
   */
  betContent: string

  /**
   * 赔率
   */
  changeValues: number

  /**
   * 添加时间
   */
  time: string

  /**
   * 操作者ID
   */
  operatorID: number

  /**
   * 是否自动降赚赔
   */
  isAuto: boolean
}
export type LottosettingGetChangeOddsEntity = LottosettingGetChangeOddsEntityItem[]

/**
 * 获取分站赔率变动列表 by felix
 */
export interface LottosettingGetChangeOddsParams {
  /**
   * 彩种ID
   */
  lottoId: number

  /**
   * 玩法ID
   */
  playId: number
}

/**
 * 获取分站赔率变动列表 by felix
 */
export const lottosettingGetChangeOdds = (
  params: LottosettingGetChangeOddsParams
) => {
  return client.get<IClientResp<LottosettingGetChangeOddsEntity>>(
    '/lottosetting/getChangeOdds',
    { params }
  )
}

/**
 * - **通过** `Inject('lottosettingGetChangeOddsStore')` 依赖注入到组件
 * - **获取分站赔率变动列表 by felix** LottosettingGetChangeOddsStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingGetChangeOddsStore: LottosettingGetChangeOddsStore
		}
		@Inject('lottosettingGetChangeOddsStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingGetChangeOddsStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingGetChangeOddsStore extends AsyncStore {
  @observable getChangeOddsEntity?: LottosettingGetChangeOddsEntity = undefined
  @action
  async getChangeOdds(params: LottosettingGetChangeOddsParams) {
    return this.asyncAction<LottosettingGetChangeOddsEntity>(
      'getChangeOddsEntity',
      lottosettingGetChangeOdds(params)
    )
  }
}

export const lottosettingGetChangeOddsStore = new LottosettingGetChangeOddsStore()

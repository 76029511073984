/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 删除菜单【菜单管理】
 */
export interface MenuDeleteMenusEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {}
}

/**
 * 删除菜单【菜单管理】
 */
/**
* 删除菜单<br />
            返回值 code=0 状态设置成功 ， code = 1 状态设置失败 ，&gt;=10000 其他系统错误
*/
export interface MenuDeleteMenusParams {
  /**
   * 菜单ID
   */
  id: number
}

/**
 * 删除菜单【菜单管理】
 */
export const menuDeleteMenus = (params: MenuDeleteMenusParams) => {
  return client.post<IClientResp<MenuDeleteMenusEntity>>(
    '/Menu/DeleteMenus',
    params
  )
}

/**
 * - **通过** `Inject('menuDeleteMenusStore')` 依赖注入到组件
 * - **删除菜单【菜单管理】** MenuDeleteMenusStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuDeleteMenusStore: MenuDeleteMenusStore
		}
		@Inject('menuDeleteMenusStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuDeleteMenusStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuDeleteMenusStore extends AsyncStore {
  @observable deleteMenusEntity?: MenuDeleteMenusEntity = undefined
  @action
  async deleteMenus(params: MenuDeleteMenusParams) {
    return this.asyncAction<MenuDeleteMenusEntity>(
      'deleteMenusEntity',
      menuDeleteMenus(params)
    )
  }
}

export const menuDeleteMenusStore = new MenuDeleteMenusStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 切换分站
 */
export interface HomeChangeSiteEntityItem {
  /**
   * 菜单ID
   */
  id: number

  /**
   * 菜单层级(1--表示一级菜单)
   */
  menuLayer: number

  /**
   * 菜单对应的网址
   */
  menuUrl: string

  /**
   * 标签页名称（用于固定在某一个标签页显示）
   */
  tabName: string

  /**
   * 是否显示(0--不显示 1--显示)
   */
  display: boolean

  /**
   * 是否覆盖左边菜单栏(0--不覆盖 1--覆盖)
   */
  covered: boolean

  /**
   * 页面呈现方式(0--当前窗口  1--新窗口
   */
  pageDisplay: number

  /**
   * 二级菜单小图标
   */
  icon: string
  menusName: string
  pid: number
}
export type HomeChangeSiteEntity = HomeChangeSiteEntityItem[]

/**
 * 切换分站
 */
export interface HomeChangeSiteParams {
  /**
   * 分站编码
   */
  siteNo: string
}

/**
 * 切换分站
 */
export const homeChangeSite = (params: HomeChangeSiteParams) => {
  return client.post<IClientResp<HomeChangeSiteEntity>>(
    '/Home/ChangeSite',
    params
  )
}

/**
 * - **通过** `Inject('homeChangeSiteStore')` 依赖注入到组件
 * - **切换分站** HomeChangeSiteStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			homeChangeSiteStore: HomeChangeSiteStore
		}
		@Inject('homeChangeSiteStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			homeChangeSiteStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class HomeChangeSiteStore extends AsyncStore {
  @observable changeSiteEntity?: HomeChangeSiteEntity = undefined
  @action
  async changeSite(params: HomeChangeSiteParams) {
    return this.asyncAction<HomeChangeSiteEntity>(
      'changeSiteEntity',
      homeChangeSite(params)
    )
  }
}

export const homeChangeSiteStore = new HomeChangeSiteStore()

/**
 * base api path
 */
export const baseURL = process.env.NODE_ENV === 'production' ? '/api' : ''
/**
 * captcha uri
 */
export const captchaUri = baseURL + '/IPassport/Captcha'

/** prefix */
export const prefix = '/app'

/**
 * 服务器地址 用于上传图片的地址拼接
 */
export const hostURI =
  process.env.REACT_APP_ASSETS_URL || 'http://apitgadm.rytest.com'

export const storage = {
  locale: 'LOCALE'
}

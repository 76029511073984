/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取所有的ClassName【多语言管理】
 */
export type ConfigGetLanguageClassEntity = string[]

/**
 * 获取所有的ClassName【多语言管理】
 */
export const configGetLanguageClass = () => {
  return client.get<IClientResp<ConfigGetLanguageClassEntity>>(
    '/Config/GetLanguageClass'
  )
}

/**
 * - **通过** `Inject('configGetLanguageClassStore')` 依赖注入到组件
 * - **获取所有的ClassName【多语言管理】** ConfigGetLanguageClassStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			configGetLanguageClassStore: ConfigGetLanguageClassStore
		}
		@Inject('configGetLanguageClassStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			configGetLanguageClassStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ConfigGetLanguageClassStore extends AsyncStore {
  @observable getLanguageClassEntity?: ConfigGetLanguageClassEntity = undefined
  @action
  async getLanguageClass() {
    return this.asyncAction<ConfigGetLanguageClassEntity>(
      'getLanguageClassEntity',
      configGetLanguageClass()
    )
  }
}

export const configGetLanguageClassStore = new ConfigGetLanguageClassStore()


/**
 * generate by scripts/menu.js
 */
export const breadcrumbNameMap = {
  "/app": {
    "title": "首页",
    "hasUrl": "/app"
  },
  "/app/statisticsreport": {
    "title": "注单统计",
    "hasUrl": ""
  },
  "/app/financereport": {
    "title": "财务报表",
    "hasUrl": ""
  },
  "/app/userinfo": {
    "title": "用户信息",
    "hasUrl": ""
  },
  "/app/openmanage": {
    "title": "开奖管理",
    "hasUrl": ""
  },
  "/app/gamesettings": {
    "title": "游戏设定",
    "hasUrl": ""
  },
  "/app/syssetting": {
    "title": "系统设定",
    "hasUrl": ""
  },
  "/app/logs": {
    "title": "日志",
    "hasUrl": ""
  },
  "/app/systemlogs": {
    "title": "系统日志",
    "hasUrl": ""
  },
  "/app/menu": {
    "title": "菜单配置",
    "hasUrl": ""
  },
  "/app/noticemanage": {
    "title": "公告配置",
    "hasUrl": ""
  },
  "/app/syssetting/user": {
    "title": "系统用户管理",
    "hasUrl": true
  },
  "/app/syssetting/usergroup": {
    "title": "系统用户组管理",
    "hasUrl": true
  },
  "/app/syssetting/sitemanagement": {
    "title": "分站管理",
    "hasUrl": true
  },
  "/app/syssetting/sysmaintain": {
    "title": "系统维护",
    "hasUrl": true
  },
  "/app/syssetting/vendermanagement": {
    "title": "厂商管理",
    "hasUrl": true
  },
  "/app/syssetting/languagemanagement": {
    "title": "多语言管理",
    "hasUrl": true
  },
  "/app/syssetting/operatecommand": {
    "title": "操作指令管理",
    "hasUrl": true
  },
  "/app/syssetting/operatecommandclass": {
    "title": "操作指令分类管理",
    "hasUrl": true
  },
  "/app/menu/config": {
    "title": "菜单配置",
    "hasUrl": true
  },
  "/app/menu/authoritygroup": {
    "title": "权限组管理",
    "hasUrl": true
  },
  "/app/menu/authorityitem": {
    "title": "权限项管理",
    "hasUrl": true
  },
  "/app/menu/controller": {
    "title": "控制器管理",
    "hasUrl": true
  },
  "/app/menu/usertemplate": {
    "title": "权限模板管理",
    "hasUrl": true
  },
  "/app/systemlogs/ctrlloginlogs": {
    "title": "管理员登录日志",
    "hasUrl": true
  },
  "/app/systemlogs/errorlogs": {
    "title": "错误日志",
    "hasUrl": true
  },
  "/app/systemlogs/apilogs": {
    "title": "API日志",
    "hasUrl": true
  },
  "/app/logs/operatelogs": {
    "title": "操作日志",
    "hasUrl": true
  },
  "/app/logs/memberloginlogs": {
    "title": "会员登录日志",
    "hasUrl": true
  },
  "/app/logs/siteloginlogs": {
    "title": "分站登录日志",
    "hasUrl": true
  },
  "/app/financereport/currentaccount": {
    "title": "当前账",
    "hasUrl": true
  },
  "/app/financereport/countdetail": {
    "title": "注单查询",
    "hasUrl": true
  },
  "/app/statisticsreport/realtime": {
    "title": "实时注单",
    "hasUrl": true
  },
  "/app/userinfo/userlist": {
    "title": "用户列表",
    "hasUrl": true
  },
  "/app/openmanage/openmanagedetail": {
    "title": "开奖管理",
    "hasUrl": true
  },
  "/app/openmanage/openresult": {
    "title": "开奖历史",
    "hasUrl": true
  },
  "/app/gamesettings/playconfig": {
    "title": "玩法配置",
    "hasUrl": true
  },
  "/app/gamesettings/lotteryconfig": {
    "title": "彩种启停",
    "hasUrl": true
  },
  "/app/gamesettings/lotteryonekey": {
    "title": "一键启停管理",
    "hasUrl": true
  },
  "/app/noticemanage/noticelist": {
    "title": "公告管理",
    "hasUrl": true
  }
}


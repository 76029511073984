/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 系统用户分页列表【系统用户管理】
 */
export interface data {
  /**
   * 管理员ID
   */
  id: number

  /**
   * 登录账号
   */
  ctrlUserName: string

  /**
   * 昵称
   */
  nickName: string

  /**
   * 状态(0-正常 1-停用)
   */
  state: string

  /**
   * 所属用户组(多个使用[,]分隔)
   */
  userGroupIDList: string

  /**
   * 备注
   */
  remark: string

  /**
   * 最后登录时间
   */
  lastLoginDateTime: string

  /**
   * 最后登录IP
   */
  lastLoginIP: string

  /**
   * 绑定MAC列表，多个MAC使用[,]分隔
   */
  bindMACList: string

  /**
   * 绑定IP列表，多个IP使用[,]分隔
   */
  bindIPList: string

  /**
   * 黑名单IP列表，多个IP使用[,]分隔
   */
  blackIPList: string

  /**
   * 用户类型 0-系统管理员，1-主播
   */
  userTypeID: 1 | 2 | 3
}
export interface SysGetCtrlUserListEntity {
  recondCount: number
  data: data[]
}

/**
 * 系统用户分页列表【系统用户管理】
 */
export interface SysGetCtrlUserListParams {
  /**
   * 用户状态[0-正常,1-停用]
   */
  State?: string

  /**
   * 用户帐号/昵称
   */
  UserName?: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 系统用户分页列表【系统用户管理】
 */
export const sysGetCtrlUserList = (params: SysGetCtrlUserListParams) => {
  return client.get<IClientResp<SysGetCtrlUserListEntity>>(
    '/Sys/GetCtrlUserList',
    { params }
  )
}

/**
 * - **通过** `Inject('sysGetCtrlUserListStore')` 依赖注入到组件
 * - **系统用户分页列表【系统用户管理】** SysGetCtrlUserListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			sysGetCtrlUserListStore: SysGetCtrlUserListStore
		}
		@Inject('sysGetCtrlUserListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			sysGetCtrlUserListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class SysGetCtrlUserListStore extends AsyncStore {
  @observable getCtrlUserListEntity?: SysGetCtrlUserListEntity = undefined
  @action
  async getCtrlUserList(params: SysGetCtrlUserListParams) {
    return this.asyncAction<SysGetCtrlUserListEntity>(
      'getCtrlUserListEntity',
      sysGetCtrlUserList(params)
    )
  }
}

export const sysGetCtrlUserListStore = new SysGetCtrlUserListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取首页数据
 */
export interface betData {
  /**
   * 日期
   */
  statDate: string

  /**
   * 名称
   */
  typeName: string

  /**
   * 盈亏(Abs)
   */
  moneyAbs: number

  /**
   * 盈亏
   */
  money: number
}
export interface winloseData {
  /**
   * 日期
   */
  statDate: string

  /**
   * 名称
   */
  typeName: string

  /**
   * 盈亏(Abs)
   */
  moneyAbs: number

  /**
   * 盈亏
   */
  money: number
}
export interface onlineData {
  /**
   * 日期
   */
  statDate: string

  /**
   * 名称
   */
  typeName: string

  /**
   * 人数
   */
  count: number
}
export interface HomeIndexDataListEntity {
  /**
   * 今日游戏数据
   */
  betData: betData[]

  /**
   * 近期投注记录
   */
  winloseData: winloseData[]

  /**
   * 在线会员数据
   */
  onlineData: onlineData[]
}

/**
 * 获取首页数据
 */
export const homeIndexDataList = () => {
  return client.post<IClientResp<HomeIndexDataListEntity>>(
    '/Home/IndexDataList'
  )
}

/**
 * - **通过** `Inject('homeIndexDataListStore')` 依赖注入到组件
 * - **获取首页数据** HomeIndexDataListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			homeIndexDataListStore: HomeIndexDataListStore
		}
		@Inject('homeIndexDataListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			homeIndexDataListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class HomeIndexDataListStore extends AsyncStore {
  @observable indexDataListEntity?: HomeIndexDataListEntity = undefined
  @action
  async indexDataList() {
    return this.asyncAction<HomeIndexDataListEntity>(
      'indexDataListEntity',
      homeIndexDataList()
    )
  }
}

export const homeIndexDataListStore = new HomeIndexDataListStore()

/******************************************************************
 *
 *  Note: this file was generated by `yarn services` command
 *  So don't modify it manually. If you want some modification
 *  please change the template via `scripts/store.tpl` directly
 *
 * ***************************************************************/

// tslint:disable:ordered-imports

import { apiSiteConfigStore } from 'services/api/site_config'
import { apiAddMenuActionConfigStore } from 'services/api/add_menu_action_config'
import { apiDeleteMenuActionConfigStore } from 'services/api/delete_menu_action_config'
import { basicInfoGetLottoListStore } from 'services/basic_info/get_lotto_list'
import { configGetLanguageListStore } from 'services/config/get_language_list'
import { configGetLanguageClassStore } from 'services/config/get_language_class'
import { configLanguageAddStore } from 'services/config/language_add'
import { configLanguageModifyStore } from 'services/config/language_modify'
import { configLanguageDeleteStore } from 'services/config/language_delete'
import { homeLiveAdminApiDemoStore } from 'services/home/live_admin_api_demo'
import { homeLoginStore } from 'services/home/login'
import { homeSsoLoginStore } from 'services/home/sso_login'
import { homeLogoutStore } from 'services/home/logout'
import { homeGetVenderListStore } from 'services/home/get_vender_list'
import { homeChangeVendersStore } from 'services/home/change_venders'
import { homeGetSiteListStore } from 'services/home/get_site_list'
import { homeChangeSiteStore } from 'services/home/change_site'
import { homeGetMenuStore } from 'services/home/get_menu'
import { homeIndexDataListStore } from 'services/home/index_data_list'
import { logGetDynamicLogListStore } from 'services/log/get_dynamic_log_list'
import { logGetOperateLogListStore } from 'services/log/get_operate_log_list'
import { logGetMemberLoginLogListStore } from 'services/log/get_member_login_log_list'
import { logGetSiteLoginLogListStore } from 'services/log/get_site_login_log_list'
import { logGetCtrlLoginLogListStore } from 'services/log/get_ctrl_login_log_list'
import { logGetErrorLogListStore } from 'services/log/get_error_log_list'
import { logGetErrorLogsClassNameStore } from 'services/log/get_error_logs_class_name'
import { logGetApiLogListStore } from 'services/log/get_api_log_list'
import { logGetApiLogsClassNameStore } from 'services/log/get_api_logs_class_name'
import { lottoMemberGetLottoMemberStore } from 'services/lotto_member/get_lotto_member'
import { lottosettingSystemlimitStore } from 'services/lottosetting/systemlimit'
import { lottosettingUpdatesystemlimitStore } from 'services/lottosetting/updatesystemlimit'
import { lottosettingSitelimitStore } from 'services/lottosetting/sitelimit'
import { lottosettingUpdateSiteLimitStore } from 'services/lottosetting/update_site_limit'
import { lottosettingGetChangeOddsStore } from 'services/lottosetting/get_change_odds'
import { lottosettingAddChangeOddsStore } from 'services/lottosetting/add_change_odds'
import { lottosettingUpdateChangeOddsStore } from 'services/lottosetting/update_change_odds'
import { lottosettingDeleteChangeOddsStore } from 'services/lottosetting/delete_change_odds'
import { lottosettingPlayTypeStore } from 'services/lottosetting/play_type'
import { lottosettingGetLowerOddsConfigStore } from 'services/lottosetting/get_lower_odds_config'
import { lottosettingUpdateLowerOddsStore } from 'services/lottosetting/update_lower_odds'
import { lottosettingGetTgLottosConfigStore } from 'services/lottosetting/get_tg_lottos_config'
import { lottosettingUpdateConfigStore } from 'services/lottosetting/update_config'
import { lottosettingUpdateOpenStateStore } from 'services/lottosetting/update_open_state'
import { lottosettingUpdatePanStateStore } from 'services/lottosetting/update_pan_state'
import { lottosettingUpdateAutoOpenPanStore } from 'services/lottosetting/update_auto_open_pan'
import { lottosettingGetTgLottosManagerStore } from 'services/lottosetting/get_tg_lottos_manager'
import { lottosettingGetPanCountStore } from 'services/lottosetting/get_pan_count'
import { lottosettingUpdatePanManagerStore } from 'services/lottosetting/update_pan_manager'
import { menuGetMenuListStore } from 'services/menu/get_menu_list'
import { menuGetMenusPidListStore } from 'services/menu/get_menus_pid_list'
import { menuMenuPidListStore } from 'services/menu/menu_pid_list'
import { menuAddMenusStore } from 'services/menu/add_menus'
import { menuDeleteMenusStore } from 'services/menu/delete_menus'
import { menuUpdateMenusStore } from 'services/menu/update_menus'
import { menuGetBindActionGroupsStore } from 'services/menu/get_bind_action_groups'
import { menuMenuBindActionGroupsStore } from 'services/menu/menu_bind_action_groups'
import { menuGetControllersAllStore } from 'services/menu/get_controllers_all'
import { menuGetActionGroupsListStore } from 'services/menu/get_action_groups_list'
import { menuUpdateActionGroupStore } from 'services/menu/update_action_group'
import { menuAddActionGroupsStore } from 'services/menu/add_action_groups'
import { menuDeleteActionGroupsStore } from 'services/menu/delete_action_groups'
import { menuGetBindActionsStore } from 'services/menu/get_bind_actions'
import { menuActionBindActionGroupStore } from 'services/menu/action_bind_action_group'
import { menuGetActionListStore } from 'services/menu/get_action_list'
import { menuAddActionStore } from 'services/menu/add_action'
import { menuDeleteActionStore } from 'services/menu/delete_action'
import { menuUpdateActionStore } from 'services/menu/update_action'
import { menuGetControllersListStore } from 'services/menu/get_controllers_list'
import { menuAddControllersStore } from 'services/menu/add_controllers'
import { menuDeleteControllersStore } from 'services/menu/delete_controllers'
import { menuModifyControllerStore } from 'services/menu/modify_controller'
import { menuGetTemplateListStore } from 'services/menu/get_template_list'
import { menuAddMenuActionConfigTemplatesStore } from 'services/menu/add_menu_action_config_templates'
import { menuModifyMenuActionConfigTemplatesStore } from 'services/menu/modify_menu_action_config_templates'
import { menuDeleteMenuActionConfigTemplatesStore } from 'services/menu/delete_menu_action_config_templates'
import { menuGetMenuAuthorityStore } from 'services/menu/get_menu_authority'
import { menuModifyMenuAuthorityStore } from 'services/menu/modify_menu_authority'
import { menuSelectBySubMenuIdActionListStore } from 'services/menu/select_by_sub_menu_id_action_list'
import { menuModifyActionAuthorityStore } from 'services/menu/modify_action_authority'
import { menuMenuActionConfigStore } from 'services/menu/menu_action_config'
import { menuRyActionsConfigStore } from 'services/menu/ry_actions_config'
import { menuModifyAuthorityStore } from 'services/menu/modify_authority'
import { menuModifyActionStore } from 'services/menu/modify_action'
import { noticeBackstageStore } from 'services/notice/backstage'
import { noticeListStore } from 'services/notice/list'
import { noticeAddStore } from 'services/notice/add'
import { noticeEditStore } from 'services/notice/edit'
import { noticeDeleteStore } from 'services/notice/delete'
import { noticeSitesStore } from 'services/notice/sites'
import { noticeStateStore } from 'services/notice/state'
import { noticeTopStore } from 'services/notice/top'
import { openManageAddNewOpenStore } from 'services/open_manage/add_new_open'
import { openManageGetHistoryStore } from 'services/open_manage/get_history'
import { openManageDoSettleStore } from 'services/open_manage/do_settle'
import { openManageDoReverseSettleStore } from 'services/open_manage/do_reverse_settle'
import { openManageDoWithoutNumSettleStore } from 'services/open_manage/do_without_num_settle'
import { openManageDoReSettlmentStore } from 'services/open_manage/do_re_settlment'
import { openManageUpdateResultStore } from 'services/open_manage/update_result'
import { openManageRemoveResultStore } from 'services/open_manage/remove_result'
import { openManageUpdateOpenResultTimeStore } from 'services/open_manage/update_open_result_time'
import { openManageUpdateAutoCountStore } from 'services/open_manage/update_auto_count'
import { reportsRealTimeStore } from 'services/reports/real_time'
import { reportsBetQueryStore } from 'services/reports/bet_query'
import { reportsGetDateTimeStore } from 'services/reports/get_date_time'
import { reportsGetLottoNoStore } from 'services/reports/get_lotto_no'
import { reportsGetAccountStore } from 'services/reports/get_account'
import { reportsGetDetailStore } from 'services/reports/get_detail'
import { sysGetCtrlUserListStore } from 'services/sys/get_ctrl_user_list'
import { sysGetCtrlUserGroupsStore } from 'services/sys/get_ctrl_user_groups'
import { sysAddCtrlUserStore } from 'services/sys/add_ctrl_user'
import { sysUpdateCtrlUserStore } from 'services/sys/update_ctrl_user'
import { sysDeleteCtrlUserStore } from 'services/sys/delete_ctrl_user'
import { sysCtrlUserBindIpStore } from 'services/sys/ctrl_user_bind_ip'
import { sysGetUserGroupListStore } from 'services/sys/get_user_group_list'
import { sysAddUserGroupStore } from 'services/sys/add_user_group'
import { sysEditUserGroupStore } from 'services/sys/edit_user_group'
import { sysDeleteUserGroupStore } from 'services/sys/delete_user_group'
import { sysModifyUserGroupStateStore } from 'services/sys/modify_user_group_state'
import { sysUserGroupMenuConfigStore } from 'services/sys/user_group_menu_config'
import { sysModifyUserGroupAuthorityStore } from 'services/sys/modify_user_group_authority'
import { sysUserGroupsActionsConfigStore } from 'services/sys/user_groups_actions_config'
import { sysModifyUserGroupActionStore } from 'services/sys/modify_user_group_action'
import { sysGetMaintenancesListStore } from 'services/sys/get_maintenances_list'
import { sysUpdateMaintenancesStore } from 'services/sys/update_maintenances'
import { sysGetOperateCommandListStore } from 'services/sys/get_operate_command_list'
import { sysGetClassNameStore } from 'services/sys/get_class_name'
import { sysGetOperateCommandAllStore } from 'services/sys/get_operate_command_all'
import { sysOperateCommandAddStore } from 'services/sys/operate_command_add'
import { sysOperateCommandModifyStore } from 'services/sys/operate_command_modify'
import { sysOperateCommandDeleteStore } from 'services/sys/operate_command_delete'
import { sysGetOperateCommandClassListStore } from 'services/sys/get_operate_command_class_list'
import { sysModifyOperateCommandClassStore } from 'services/sys/modify_operate_command_class'
import { sysAddOperateCommandClassStore } from 'services/sys/add_operate_command_class'
import { sysDeleteOperateCommandClassStore } from 'services/sys/delete_operate_command_class'
import { sysIsExistCommandStore } from 'services/sys/is_exist_command'
import { venderGetVendersListStore } from 'services/vender/get_venders_list'
import { venderGetSitesListStore } from 'services/vender/get_sites_list'
import { venderGetGameClassListStore } from 'services/vender/get_game_class_list'
import { inject } from 'mobx-react'

/**
 * all stores
 */
export const stores = {
  apiSiteConfigStore,
  apiAddMenuActionConfigStore,
  apiDeleteMenuActionConfigStore,
  basicInfoGetLottoListStore,
  configGetLanguageListStore,
  configGetLanguageClassStore,
  configLanguageAddStore,
  configLanguageModifyStore,
  configLanguageDeleteStore,
  homeLiveAdminApiDemoStore,
  homeLoginStore,
  homeSsoLoginStore,
  homeLogoutStore,
  homeGetVenderListStore,
  homeChangeVendersStore,
  homeGetSiteListStore,
  homeChangeSiteStore,
  homeGetMenuStore,
  homeIndexDataListStore,
  logGetDynamicLogListStore,
  logGetOperateLogListStore,
  logGetMemberLoginLogListStore,
  logGetSiteLoginLogListStore,
  logGetCtrlLoginLogListStore,
  logGetErrorLogListStore,
  logGetErrorLogsClassNameStore,
  logGetApiLogListStore,
  logGetApiLogsClassNameStore,
  lottoMemberGetLottoMemberStore,
  lottosettingSystemlimitStore,
  lottosettingUpdatesystemlimitStore,
  lottosettingSitelimitStore,
  lottosettingUpdateSiteLimitStore,
  lottosettingGetChangeOddsStore,
  lottosettingAddChangeOddsStore,
  lottosettingUpdateChangeOddsStore,
  lottosettingDeleteChangeOddsStore,
  lottosettingPlayTypeStore,
  lottosettingGetLowerOddsConfigStore,
  lottosettingUpdateLowerOddsStore,
  lottosettingGetTgLottosConfigStore,
  lottosettingUpdateConfigStore,
  lottosettingUpdateOpenStateStore,
  lottosettingUpdatePanStateStore,
  lottosettingUpdateAutoOpenPanStore,
  lottosettingGetTgLottosManagerStore,
  lottosettingGetPanCountStore,
  lottosettingUpdatePanManagerStore,
  menuGetMenuListStore,
  menuGetMenusPidListStore,
  menuMenuPidListStore,
  menuAddMenusStore,
  menuDeleteMenusStore,
  menuUpdateMenusStore,
  menuGetBindActionGroupsStore,
  menuMenuBindActionGroupsStore,
  menuGetControllersAllStore,
  menuGetActionGroupsListStore,
  menuUpdateActionGroupStore,
  menuAddActionGroupsStore,
  menuDeleteActionGroupsStore,
  menuGetBindActionsStore,
  menuActionBindActionGroupStore,
  menuGetActionListStore,
  menuAddActionStore,
  menuDeleteActionStore,
  menuUpdateActionStore,
  menuGetControllersListStore,
  menuAddControllersStore,
  menuDeleteControllersStore,
  menuModifyControllerStore,
  menuGetTemplateListStore,
  menuAddMenuActionConfigTemplatesStore,
  menuModifyMenuActionConfigTemplatesStore,
  menuDeleteMenuActionConfigTemplatesStore,
  menuGetMenuAuthorityStore,
  menuModifyMenuAuthorityStore,
  menuSelectBySubMenuIdActionListStore,
  menuModifyActionAuthorityStore,
  menuMenuActionConfigStore,
  menuRyActionsConfigStore,
  menuModifyAuthorityStore,
  menuModifyActionStore,
  noticeBackstageStore,
  noticeListStore,
  noticeAddStore,
  noticeEditStore,
  noticeDeleteStore,
  noticeSitesStore,
  noticeStateStore,
  noticeTopStore,
  openManageAddNewOpenStore,
  openManageGetHistoryStore,
  openManageDoSettleStore,
  openManageDoReverseSettleStore,
  openManageDoWithoutNumSettleStore,
  openManageDoReSettlmentStore,
  openManageUpdateResultStore,
  openManageRemoveResultStore,
  openManageUpdateOpenResultTimeStore,
  openManageUpdateAutoCountStore,
  reportsRealTimeStore,
  reportsBetQueryStore,
  reportsGetDateTimeStore,
  reportsGetLottoNoStore,
  reportsGetAccountStore,
  reportsGetDetailStore,
  sysGetCtrlUserListStore,
  sysGetCtrlUserGroupsStore,
  sysAddCtrlUserStore,
  sysUpdateCtrlUserStore,
  sysDeleteCtrlUserStore,
  sysCtrlUserBindIpStore,
  sysGetUserGroupListStore,
  sysAddUserGroupStore,
  sysEditUserGroupStore,
  sysDeleteUserGroupStore,
  sysModifyUserGroupStateStore,
  sysUserGroupMenuConfigStore,
  sysModifyUserGroupAuthorityStore,
  sysUserGroupsActionsConfigStore,
  sysModifyUserGroupActionStore,
  sysGetMaintenancesListStore,
  sysUpdateMaintenancesStore,
  sysGetOperateCommandListStore,
  sysGetClassNameStore,
  sysGetOperateCommandAllStore,
  sysOperateCommandAddStore,
  sysOperateCommandModifyStore,
  sysOperateCommandDeleteStore,
  sysGetOperateCommandClassListStore,
  sysModifyOperateCommandClassStore,
  sysAddOperateCommandClassStore,
  sysDeleteOperateCommandClassStore,
  sysIsExistCommandStore,
  venderGetVendersListStore,
  venderGetSitesListStore,
  venderGetGameClassListStore
}

type InjectStores = keyof typeof stores
type InjectFuncStores = (allStore: typeof stores) => any

/**
 * inject params
 */
export type InjectParams = InjectStores | InjectFuncStores

/**
 * inject store
 * @param params
 */
export const Inject = (params: InjectParams) => inject(params as any)

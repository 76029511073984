/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取彩种玩法列表 by felix
 */
export interface LottosettingPlayTypeEntityItem {
  /**
   * 玩法ID
   */
  id: number

  /**
   * 彩种编号
   */
  lottoID: number

  /**
   * 玩法简称
   */
  abbreviation: string

  /**
   * 彩票玩法组ID
   */
  lottoPlayTypeGroupID: number

  /**
   * 该玩法允许下注的长度
   */
  allowLength: number

  /**
   * 玩法启用状态
   */
  openState: boolean
  playTypeName_zh_cn: string
  playTypeName_zh_tw: string
  playTypeName_en: string
  playTypeName_th: string
  playTypeName_vn: string

  /**
   * 排序
   */
  orderNo: number

  /**
   * 玩法简单说明
   */
  remark: string

  /**
   * 彩种名称
   */
  playTypeName: string
}
export type LottosettingPlayTypeEntity = LottosettingPlayTypeEntityItem[]

/**
 * 获取彩种玩法列表 by felix
 */
export interface LottosettingPlayTypeParams {
  /**
   * 彩种ID
   */
  lottoId: number
}

/**
 * 获取彩种玩法列表 by felix
 */
export const lottosettingPlayType = (params: LottosettingPlayTypeParams) => {
  return client.get<IClientResp<LottosettingPlayTypeEntity>>(
    '/lottosetting/playType',
    { params }
  )
}

/**
 * - **通过** `Inject('lottosettingPlayTypeStore')` 依赖注入到组件
 * - **获取彩种玩法列表 by felix** LottosettingPlayTypeStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			lottosettingPlayTypeStore: LottosettingPlayTypeStore
		}
		@Inject('lottosettingPlayTypeStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			lottosettingPlayTypeStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LottosettingPlayTypeStore extends AsyncStore {
  @observable playTypeEntity?: LottosettingPlayTypeEntity = undefined
  @action
  async playType(params: LottosettingPlayTypeParams) {
    return this.asyncAction<LottosettingPlayTypeEntity>(
      'playTypeEntity',
      lottosettingPlayType(params)
    )
  }
}

export const lottosettingPlayTypeStore = new LottosettingPlayTypeStore()

import { Row, Spin } from 'antd'
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { HomeGetMenuEntity } from 'services/home/get_menu'
import { getUrlWithPrefix } from 'utils'
import { TabSwitch } from './TabSwitch'
const HOME = React.lazy(() => import('../Home'))
const SYSMAINTAIN = React.lazy(() => import('../SysSetting/SysMaintain'))
const VENDERMANAGEMENT = React.lazy(() =>
  import('../SysSetting/VenderManagement')
)
const USER = React.lazy(() => import('../SysSetting/User'))
const USERGROUP = React.lazy(() => import('../SysSetting/Usergroup'))
const AUTHORITY = React.lazy(() => import('../SysSetting/Usergroup/Authority'))
const LANGUAGEMANAGEMENT = React.lazy(() =>
  import('../SysSetting/LanguageManagement')
)
const OPERATECOMMAND = React.lazy(() => import('../SysSetting/OperateCommand'))
const OPERATECOMMANDCLASS = React.lazy(() =>
  import('../SysSetting/OperateCommandClass')
)
const SITEMANAGEMENT = React.lazy(() => import('../SysSetting/SiteManagement'))

const CONFIG = React.lazy(() => import('../Menu/Config'))
const AUTHORITYGROUP = React.lazy(() => import('../Menu/AuthorityGroup'))
const AUTHORITYITEM = React.lazy(() => import('../Menu/AuthorityItem'))
const CONTROLLER = React.lazy(() => import('../Menu/Controller'))
const USERTEMPLATE = React.lazy(() => import('../Menu/UserTemplate'))
const BINDMENU = React.lazy(() => import('../Menu/UserTemplate/BindMenu'))

// 日志
const MEMBERLOGINLOGS = React.lazy(() => import('../Logs/MemberLoginLogs'))
const SITELOGINLOGS = React.lazy(() => import('../Logs/SiteLoginLogs'))
const OPERATELOGS = React.lazy(() => import('../Logs/OperateLogs'))
const MEMBERDYNAMICLOGS = React.lazy(() => import('../Logs/MemberDynamicLogs'))

// 系统日志
const APILOGS = React.lazy(() => import('../SystemLogs/ApiLogs'))
const ERRORLOGS = React.lazy(() => import('../SystemLogs/ErrorLogs'))
const CTRLLOGINLOGS = React.lazy(() => import('../SystemLogs/CtrlLoginLogs'))

const OPENMANAGEDETAIL = React.lazy(() =>
  import('../OpenManage/OpenManageDetail')
)
const OPENRESULT = React.lazy(() => import('../OpenManage/OpenResult'))

// 用户信息
const USERLIST = React.lazy(() => import('../UserInfo/UserList'))
// 财务报表
const CURRENTACCOUNT = React.lazy(() =>
  import('../FinanceReport/CurrentAccount')
)
const CURRENCYNO = React.lazy(() =>
  import('../FinanceReport/CurrentAccount/CurrencyNo')
)
const LotteryMember = React.lazy(() =>
  import('../FinanceReport/CurrentAccount/LotteryMember')
)
const PlayType = React.lazy(() =>
  import('../FinanceReport/CurrentAccount/PlayType')
)
const AccountDate = React.lazy(() =>
  import('../FinanceReport/CurrentAccount/AccountDate')
)
const BetDetail = React.lazy(() =>
  import('../FinanceReport/CurrentAccount/BetDetail')
)
const COUNTDETAIL = React.lazy(() => import('../FinanceReport/CountDetail'))
// 注单统计
const REALTIME = React.lazy(() => import('../StatisticsReport/Realtime'))
const TODAY = React.lazy(() => import('../StatisticsReport/Today'))
// 游戏设定
const LOTTERYCONFIG = React.lazy(() => import('../GameSettings/LotteryConfig'))
const LOTTERYONEKEY = React.lazy(() => import('../GameSettings/LotteryOneKey'))
const PLAYCONFIG = React.lazy(() => import('../GameSettings/PlayConfig'))
// 公告管理
const NOTICEMANAGE = React.lazy(() => import('../NoticeManage/NoticeList/'))

function WaitingComponent(Component) {
  return props => (
    <Suspense
      fallback={
        <Row justify="center" align="middle">
          <Spin />
        </Row>
      }
    >
      <Component {...props} />
    </Suspense>
  )
}
export const RouteContent = (props: { menu?: HomeGetMenuEntity }) => {
  return (
    <TabSwitch>
      <Route
        exact
        path={getUrlWithPrefix()}
        component={WaitingComponent(HOME)}
      />
      <Route
        path={getUrlWithPrefix('/syssetting')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/user`}
              component={WaitingComponent(USER)}
            />
            <Route
              exact
              path={`${match.url}/usergroup`}
              component={WaitingComponent(USERGROUP)}
            />
            <Route
              path={`${match.url}/usergroup/authority/:id/:name`}
              component={WaitingComponent(AUTHORITY)}
            />
            <Route
              path={`${match.url}/sitemanagement`}
              component={WaitingComponent(SITEMANAGEMENT)}
            />
            <Route
              path={`${match.url}/sysmaintain`}
              component={WaitingComponent(SYSMAINTAIN)}
            />
            <Route
              path={`${match.url}/vendermanagement`}
              component={WaitingComponent(VENDERMANAGEMENT)}
            />
            <Route
              path={`${match.url}/languagemanagement`}
              component={WaitingComponent(LANGUAGEMANAGEMENT)}
            />
            <Route
              path={`${match.url}/operatecommand`}
              component={WaitingComponent(OPERATECOMMAND)}
            />
            <Route
              path={`${match.url}/operatecommandclass`}
              component={WaitingComponent(OPERATECOMMANDCLASS)}
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/menu')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/config/:pid?`}
              component={WaitingComponent(CONFIG)}
            />
            <Route
              path={`${match.url}/authoritygroup`}
              component={WaitingComponent(AUTHORITYGROUP)}
            />
            <Route
              path={`${match.url}/authorityitem`}
              component={WaitingComponent(AUTHORITYITEM)}
            />
            <Route
              path={`${match.url}/controller`}
              component={WaitingComponent(CONTROLLER)}
            />
            <Route
              exact
              path={`${match.url}/usertemplate`}
              component={WaitingComponent(USERTEMPLATE)}
            />
            <Route
              path={`${match.url}/usertemplate/bindMenu/:id`}
              component={WaitingComponent(BINDMENU)}
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/logs')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/memberloginlogs`}
              component={WaitingComponent(MEMBERLOGINLOGS)}
            />
            <Route
              path={`${match.url}/siteloginlogs`}
              component={WaitingComponent(SITELOGINLOGS)}
            />
            <Route
              path={`${match.url}/operateLogs`}
              component={WaitingComponent(OPERATELOGS)}
            />
            <Route
              path={`${match.url}/memberdynamiclogs`}
              component={WaitingComponent(MEMBERDYNAMICLOGS)}
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/systemlogs')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/apilogs`}
              component={WaitingComponent(APILOGS)}
            />
            <Route
              path={`${match.url}/errorlogs`}
              component={WaitingComponent(ERRORLOGS)}
            />
            <Route
              path={`${match.url}/ctrlloginlogs`}
              component={WaitingComponent(CTRLLOGINLOGS)}
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/openmanage')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/openmanagedetail`}
              component={WaitingComponent(OPENMANAGEDETAIL)}
            />
            <Route
              path={`${match.url}/openresult`}
              component={WaitingComponent(OPENRESULT)}
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/userinfo')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/userlist`}
              component={WaitingComponent(USERLIST)}
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/financereport')}
        render={({ match }) => (
          <Switch>
            <Route
              exact
              path={`${match.url}/currentaccount`}
              component={WaitingComponent(CURRENTACCOUNT)}
            />
            <Route
              path={`${match.url}/currentaccount/currencyno/:model?/:type?/:start?/:end?/:lid?`}
              component={WaitingComponent(CURRENCYNO)}
            />
            <Route
              path={`${match.url}/currentaccount/lotterymember/:model?/:type?/:start?/:end?/:lid?/:istest?`}
              component={WaitingComponent(LotteryMember)}
            />
            <Route
              path={`${match.url}/currentaccount/playtype/:model?/:type?/:start?/:end?/:lid?/:olid?`}
              component={WaitingComponent(PlayType)}
            />
            <Route
              path={`${match.url}/currentaccount/accountdate/:model?/:type?/:start?/:end?/:lid?/:nid?/:olid?/:istest?`}
              component={WaitingComponent(AccountDate)}
            />
            <Route
              path={`${match.url}/currentaccount/betdetail/:model?/:type?/:start?/:end?/:date?/:lid?/:nid?/:olid?/:istest?`}
              component={WaitingComponent(BetDetail)}
            />
            <Route
              path={`${match.url}/countdetail`}
              component={WaitingComponent(COUNTDETAIL)}
              exact
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/statisticsreport')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/realtime`}
              component={WaitingComponent(REALTIME)}
              exact
            />
            <Route
              path={`${match.url}/today`}
              component={WaitingComponent(TODAY)}
              exact
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/gamesettings')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/lotteryconfig`}
              component={WaitingComponent(LOTTERYCONFIG)}
              exact
            />
            <Route
              path={`${match.url}/lotteryonekey`}
              component={WaitingComponent(LOTTERYONEKEY)}
              exact
            />
            <Route
              path={`${match.url}/playconfig`}
              component={WaitingComponent(PLAYCONFIG)}
              exact
            />
          </Switch>
        )}
      />
      <Route
        path={getUrlWithPrefix('/noticemanage')}
        render={({ match }) => (
          <Switch>
            <Route
              path={`${match.url}/noticelist`}
              component={WaitingComponent(NOTICEMANAGE)}
              exact
            />
          </Switch>
        )}
      />
    </TabSwitch>
  )
}

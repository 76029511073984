import { Col, Row } from 'antd'
import React from 'react'

declare interface ContentProps extends React.HTMLProps<HTMLDivElement> {}
declare interface ContentState {}
class Content extends React.Component<ContentProps, ContentState> {
  render() {
    return (
      <Row gutter={[0, 32]}>
        <Col>{this.props.children}</Col>
      </Row>
    )
  }
}

export default Content

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 根据子菜单ID获取对应的菜单权限组权限项【RY编辑菜单权限】
 */
export interface actionList {
  /**
   * 节点编号
   */
  id: string

  /**
   * 父节点编号
   */
  pid: string

  /**
   * 节点名称
   */
  name_zh_cn: string
  name_zh_tw: string
  name_en: string
  name_vn: string
  name_th: string
  name: string

  /**
   * 是否勾选状态
   */
  check: boolean

  /**
   * 是否打开状态
   */
  open: boolean

  /**
   * 备注
   */
  remak: string

  /**
   * 是否不允许操作
   */
  disabled: boolean
}
export interface MenuRyActionsConfigEntityItem {
  id: number
  actionGroupName_zh_cn: string
  actionGroupName_zh_tw: string
  actionGroupName_en: string
  actionGroupName_th: string
  actionGroupName_vn: string
  actionGroupName: string
  isSelected: boolean
  actionList: actionList[]
}
export type MenuRyActionsConfigEntity = MenuRyActionsConfigEntityItem[]

/**
 * 根据子菜单ID获取对应的菜单权限组权限项【RY编辑菜单权限】
 */
export interface MenuRyActionsConfigParams {
  subMenuID: number
  menuActionConfigID: number
  venderName?: string
  siteNo?: string
}

/**
 * 根据子菜单ID获取对应的菜单权限组权限项【RY编辑菜单权限】
 */
export const menuRyActionsConfig = (params: MenuRyActionsConfigParams) => {
  return client.get<IClientResp<MenuRyActionsConfigEntity>>(
    '/Menu/RYActionsConfig',
    { params }
  )
}

/**
 * - **通过** `Inject('menuRyActionsConfigStore')` 依赖注入到组件
 * - **根据子菜单ID获取对应的菜单权限组权限项【RY编辑菜单权限】** MenuRyActionsConfigStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuRyActionsConfigStore: MenuRyActionsConfigStore
		}
		@Inject('menuRyActionsConfigStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuRyActionsConfigStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuRyActionsConfigStore extends AsyncStore {
  @observable ryActionsConfigEntity?: MenuRyActionsConfigEntity = undefined
  @action
  async ryActionsConfig(params: MenuRyActionsConfigParams) {
    return this.asyncAction<MenuRyActionsConfigEntity>(
      'ryActionsConfigEntity',
      menuRyActionsConfig(params)
    )
  }
}

export const menuRyActionsConfigStore = new MenuRyActionsConfigStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取多语言分页列表【多语言管理】
 */
export interface data {
  /**
   * 类名/ViewName...
   */
  className: string

  /**
   * 属性名称
   */
  propertyName: string

  /**
   * 最后更新时间
   */
  lastUpdateDateTime: string

  /**
   * 备注
   */
  remark: string

  /**
   * 内容(简体)
   */
  value_zh_cn: string

  /**
   * 内容(繁体)
   */
  value_zh_tw: string

  /**
   * 内容(英文)
   */
  value_en: string
}
export interface ConfigGetLanguageListEntity {
  recondCount: number
  data: data[]
}

/**
 * 获取多语言分页列表【多语言管理】
 */
export interface ConfigGetLanguageListParams {
  /**
   * className
   */
  className?: string

  /**
   * 查询参数
   */
  value?: string

  /**
   * 输入PropertyName或者LastUpdateDateTime
   */
  orderType: string

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 获取多语言分页列表【多语言管理】
 */
export const configGetLanguageList = (params: ConfigGetLanguageListParams) => {
  return client.get<IClientResp<ConfigGetLanguageListEntity>>(
    '/Config/GetLanguageList',
    { params }
  )
}

/**
 * - **通过** `Inject('configGetLanguageListStore')` 依赖注入到组件
 * - **获取多语言分页列表【多语言管理】** ConfigGetLanguageListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			configGetLanguageListStore: ConfigGetLanguageListStore
		}
		@Inject('configGetLanguageListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			configGetLanguageListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class ConfigGetLanguageListStore extends AsyncStore {
  @observable getLanguageListEntity?: ConfigGetLanguageListEntity = undefined
  @action
  async getLanguageList(params: ConfigGetLanguageListParams) {
    return this.asyncAction<ConfigGetLanguageListEntity>(
      'getLanguageListEntity',
      configGetLanguageList(params)
    )
  }
}

export const configGetLanguageListStore = new ConfigGetLanguageListStore()

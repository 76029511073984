/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取当前用户菜单
 */
export interface HomeGetMenuEntityItem {
  /**
   * 菜单ID
   */
  id: number

  /**
   * 菜单层级(1--表示一级菜单)
   */
  menuLayer: number

  /**
   * 菜单对应的网址
   */
  menuUrl: string

  /**
   * 标签页名称（用于固定在某一个标签页显示）
   */
  tabName: string

  /**
   * 是否显示(0--不显示 1--显示)
   */
  display: boolean

  /**
   * 是否覆盖左边菜单栏(0--不覆盖 1--覆盖)
   */
  covered: boolean

  /**
   * 页面呈现方式(0--当前窗口  1--新窗口
   */
  pageDisplay: number

  /**
   * 二级菜单小图标
   */
  icon: string
  menusName: string
  pid: number
}
export type HomeGetMenuEntity = HomeGetMenuEntityItem[]

/**
 * 获取当前用户菜单
 */
export const homeGetMenu = () => {
  return client.post<IClientResp<HomeGetMenuEntity>>('/Home/GetMenu')
}

/**
 * - **通过** `Inject('homeGetMenuStore')` 依赖注入到组件
 * - **获取当前用户菜单** HomeGetMenuStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			homeGetMenuStore: HomeGetMenuStore
		}
		@Inject('homeGetMenuStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			homeGetMenuStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class HomeGetMenuStore extends AsyncStore {
  @observable getMenuEntity?: HomeGetMenuEntity = undefined
  @action
  async getMenu() {
    return this.asyncAction<HomeGetMenuEntity>('getMenuEntity', homeGetMenu())
  }
}

export const homeGetMenuStore = new HomeGetMenuStore()

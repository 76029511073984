/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 权限模版列表【权限模版管理】
 */
export interface data {
  /**
   * ID
   */
  id: number

  /**
   * 模板类型(0--系统管理员 1--分站账号 2-厂商管理员)
   */
  templateType: string

  /**
   * 模板名称
   */
  templateName: string

  /**
   * 授权菜单ID列表(eg: 1,20)
   */
  menuIDList: string

  /**
   * 授权菜单ID列表(eg: 1,20)
   */
  actionIDList: string

  /**
   * 备注
   */
  remark: string
}
export interface MenuGetTemplateListEntity {
  recondCount: number
  data: data[]
}

/**
 * 权限模版列表【权限模版管理】
 */
export interface MenuGetTemplateListParams {
  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 权限模版列表【权限模版管理】
 */
export const menuGetTemplateList = (params: MenuGetTemplateListParams) => {
  return client.get<IClientResp<MenuGetTemplateListEntity>>(
    '/Menu/GetTemplateList',
    { params }
  )
}

/**
 * - **通过** `Inject('menuGetTemplateListStore')` 依赖注入到组件
 * - **权限模版列表【权限模版管理】** MenuGetTemplateListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetTemplateListStore: MenuGetTemplateListStore
		}
		@Inject('menuGetTemplateListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetTemplateListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetTemplateListStore extends AsyncStore {
  @observable getTemplateListEntity?: MenuGetTemplateListEntity = undefined
  @action
  async getTemplateList(params: MenuGetTemplateListParams) {
    return this.asyncAction<MenuGetTemplateListEntity>(
      'getTemplateListEntity',
      menuGetTemplateList(params)
    )
  }
}

export const menuGetTemplateListStore = new MenuGetTemplateListStore()

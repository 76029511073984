/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 会员登录日志分页数据【会员登录日志】
 */
export interface data {
  /**
   * ID序號
   */
  id: number

  /**
   * 厂商名称
   */
  venderName: string

  /**
   * 分站编码
   */
  siteNo: string

  /**
   * 彩票平台的会员ID
   */
  lottoMemberID: number

  /**
   * 登录会员账号
   */
  loginMemberName: string

  /**
   * 登录会员昵称
   */
  loginNickName: string

  /**
   * 登录时间
   */
  loginDateTime: string

  /**
   * 登录IP
   */
  loginIP: string
}
export interface LogGetMemberLoginLogListEntity {
  recondCount: number
  data: data[]
}

/**
 * 会员登录日志分页数据【会员登录日志】
 */
export interface LogGetMemberLoginLogListParams {
  /**
   * 开始时间(eg:[2020-03-01 12:00:00])
   */
  BeginTime?: string

  /**
   * 结束时间(eg:[2020-03-07 12:00:00])
   */
  EndTime?: string

  /**
   * 登录会员账号
   */
  LoginMemberName?: string

  /**
   * 彩票平台的会员ID
   */
  LottoMemberID?: number

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * 会员登录日志分页数据【会员登录日志】
 */
export const logGetMemberLoginLogList = (
  params: LogGetMemberLoginLogListParams
) => {
  return client.get<IClientResp<LogGetMemberLoginLogListEntity>>(
    '/Log/GetMemberLoginLogList',
    { params }
  )
}

/**
 * - **通过** `Inject('logGetMemberLoginLogListStore')` 依赖注入到组件
 * - **会员登录日志分页数据【会员登录日志】** LogGetMemberLoginLogListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetMemberLoginLogListStore: LogGetMemberLoginLogListStore
		}
		@Inject('logGetMemberLoginLogListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetMemberLoginLogListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetMemberLoginLogListStore extends AsyncStore {
  @observable
  getMemberLoginLogListEntity?: LogGetMemberLoginLogListEntity = undefined
  @action
  async getMemberLoginLogList(params: LogGetMemberLoginLogListParams) {
    return this.asyncAction<LogGetMemberLoginLogListEntity>(
      'getMemberLoginLogListEntity',
      logGetMemberLoginLogList(params)
    )
  }
}

export const logGetMemberLoginLogListStore = new LogGetMemberLoginLogListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 获取控制器列表【权限组管理/权限项管理】
 */
export interface MenuGetControllersAllEntityItem {
  /**
   * 控制器名称
   */
  controllerName: string

  /**
   * 控制器的别称
   */
  anotherName: string
}
export type MenuGetControllersAllEntity = MenuGetControllersAllEntityItem[]

/**
 * 获取控制器列表【权限组管理/权限项管理】
 */
export const menuGetControllersAll = () => {
  return client.get<IClientResp<MenuGetControllersAllEntity>>(
    '/Menu/GetControllersAll'
  )
}

/**
 * - **通过** `Inject('menuGetControllersAllStore')` 依赖注入到组件
 * - **获取控制器列表【权限组管理/权限项管理】** MenuGetControllersAllStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			menuGetControllersAllStore: MenuGetControllersAllStore
		}
		@Inject('menuGetControllersAllStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			menuGetControllersAllStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class MenuGetControllersAllStore extends AsyncStore {
  @observable getControllersAllEntity?: MenuGetControllersAllEntity = undefined
  @action
  async getControllersAll() {
    return this.asyncAction<MenuGetControllersAllEntity>(
      'getControllersAllEntity',
      menuGetControllersAll()
    )
  }
}

export const menuGetControllersAllStore = new MenuGetControllersAllStore()

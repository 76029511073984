/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * API日志分页数据【API日志】
 */
export interface data {
  /**
   * 日志编号
   */
  id: number

  /**
   * 被呼叫的APIUrl
   */
  apiUrl: string

  /**
   * 传入参数值
   */
  inMsg: string

  /**
   * 输出内容
   */
  outMsg: string

  /**
   * 日志分类
   */
  className: string

  /**
   * 呼叫开始时间
   */
  startDateTime: string

  /**
   * 呼叫结束时间
   */
  endDateTime: string

  /**
   * 总耗时（ms）
   */
  times: number

  /**
   * IsDebug
   */
  isDebug: boolean
}
export interface LogGetApiLogListEntity {
  recondCount: number
  data: data[]
}

/**
 * API日志分页数据【API日志】
 */
export interface LogGetApiLogListParams {
  /**
   * 开始时间(eg:[2017-06-12 12:00:00])
   */
  BeginTime?: string

  /**
   * 结束时间(eg:[2018-06-12 12:00:00])
   */
  EndTime?: string

  /**
   * 查询数据的类型，0:API调用日志；1:API被调用日志
   */
  SelectType?: number

  /**
   * 被呼叫的APIUrl
   */
  APIUrl?: string

  /**
   * 日志分类
   */
  ClassName?: string

  /**
   * 总耗时（ms）
   */
  Times?: number

  /**
   * 每页显示记录数(eg:[10])
   */
  PageSize: number

  /**
   * 页码(eg:[1])
   */
  PageIndex: number
}

/**
 * API日志分页数据【API日志】
 */
export const logGetApiLogList = (params: LogGetApiLogListParams) => {
  return client.get<IClientResp<LogGetApiLogListEntity>>('/Log/GetAPILogList', {
    params
  })
}

/**
 * - **通过** `Inject('logGetApiLogListStore')` 依赖注入到组件
 * - **API日志分页数据【API日志】** LogGetApiLogListStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			logGetApiLogListStore: LogGetApiLogListStore
		}
		@Inject('logGetApiLogListStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			logGetApiLogListStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class LogGetApiLogListStore extends AsyncStore {
  @observable getApiLogListEntity?: LogGetApiLogListEntity = undefined
  @action
  async getApiLogList(params: LogGetApiLogListParams) {
    return this.asyncAction<LogGetApiLogListEntity>(
      'getApiLogListEntity',
      logGetApiLogList(params)
    )
  }
}

export const logGetApiLogListStore = new LogGetApiLogListStore()

/**********************************************************
 *
 *  Auto generated by `yarn services`
 *
 *********************************************************/

import { action, observable } from 'mobx'
import { AsyncStore } from 'utils/AsyncStore'
import client, { IClientResp } from 'utils/client'

/**
 * 添加公告
 */
export interface NoticeAddEntity {
  /**
* 错误码[0-表示操作成功，大于0表示操作失败]
10000-系统发生错误,10001-IP未授权,10002-系统维护中,10003-用户登录失效,10004-参数解密失败,10005-参数错误,10006-无效的厂商编码<br />
*/
  code: number

  /**
   * 错误信息
   */
  msg: string

  /**
   * 响应数据
   */
  body: {
    /**
     * 返回对象
     */
    data: boolean
  }
}

/**
 * 添加公告
 */
export interface NoticeAddParams {
  /**
   * 厂商名称(ALL表示所有厂商所有分站都可查看这个公告)
   */
  venderName: string

  /**
   * 分站编码（NULL表示系统管理员发送的公告）
   */
  siteNo: string

  /**
   * 分站编码列表：ALL表示全部分站，多个分站编码使用[,]分隔(前后也都要有一个[,])，eg:,00-08,00-29,
   */
  siteNoList: string

  /**
   * 查看公告的用户类型列表(0--后台(分站账号查看)  1--前台(会员查看)),多个使用[,]分隔,eg: 1,2
   */
  userTypeList: string

  /**
   * 公告类型列表【1--跑马灯 2--弹出公告】,可以同时为多种类型采用[,]分隔
   */
  typeList: string

  /**
   * 状态(0--禁用  1--启用)
   */
  state: boolean

  /**
   * 是否置顶 （1--置顶）
   */
  isTop: boolean

  /**
   * 公告启用时间
   */
  startDateTime: string

  /**
   * 公告失效时间
   */
  endDateTime: string

  /**
   * 公告内容(简体)
   */
  content_zh_cn: string

  /**
   * 公告内容(繁体)
   */
  content_zh_tw: string

  /**
   * 公告内容(英文)
   */
  content_en: string
  content_vn: string
  content_th: string
}

/**
 * 添加公告
 */
export const noticeAdd = (params: NoticeAddParams) => {
  return client.post<IClientResp<NoticeAddEntity>>('/notice/add', params)
}

/**
 * - **通过** `Inject('noticeAddStore')` 依赖注入到组件
 * - **添加公告** NoticeAddStore**
 * - ```tsx
		...
		import { Inject } from '@app/store';
		import { observer } from 'mobx-react/native';
		export interface ExamlpleProps {
			noticeAddStore: NoticeAddStore
		}
		@Inject('noticeAddStore')
		@observer
		class Examlple extends React.Component<ExamlpleProps> {
		render() {
			const {
			noticeAddStore: { loading },
			} = this.props
			// loading
			...
		}

	```
 */
export class NoticeAddStore extends AsyncStore {
  @observable addEntity?: NoticeAddEntity = undefined
  @action
  async add(params: NoticeAddParams) {
    return this.asyncAction<NoticeAddEntity>('addEntity', noticeAdd(params))
  }
}

export const noticeAddStore = new NoticeAddStore()
